import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useQueryClient } from '@tanstack/react-query';
import { useLicenseController_updateClientLicense } from 'services/hooks';

import { Button } from '@/components/atoms/Buttons';

const EditLicense = ({
    onClose,
    selectedLicense,
}: {
    onClose: () => void;
    selectedLicense: {
        numLicenses: number;
        id: number;
        licenses: {
            name: string;
        };
    };
}) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const updateClientLicense = useLicenseController_updateClientLicense();

    const [licenseNumber, setLicenseNumber] = useState(selectedLicense?.numLicenses);

    return (
        <Modal isOpen={true} toggle={onClose}>
            <ModalHeader toggle={onClose}>{t('Update Client License')}</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>{t('License Name')}</Label>
                    <Input
                        type="text"
                        id="licenseName"
                        disabled={true}
                        value={selectedLicense?.licenses?.name ?? ''}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>{t('Number of Licenses')}</Label>
                    <Input
                        type="text"
                        id="licenseNumber"
                        placeholder=""
                        value={licenseNumber}
                        onChange={(e) => {
                            !Number.isNaN(Number(e.target.value)) &&
                                setLicenseNumber(Number(e.target.value));
                        }}
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button
                    variant="secondary"
                    onClick={(event) => {
                        if (selectedLicense) {
                            updateClientLicense
                                .mutateAsync({
                                    id: selectedLicense.id,
                                    requestBody: {
                                        numLicenses: licenseNumber,
                                    },
                                })
                                .finally(() => {
                                    queryClient.invalidateQueries();
                                    onClose();
                                });
                        }
                        event.preventDefault();
                    }}>
                    {t('Update')}
                </Button>{' '}
                <Button variant="secondary" onClick={onClose}>
                    {t('Cancel')}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default EditLicense;
