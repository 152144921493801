import { useLocation } from 'react-router-dom';
import { t } from 'i18next';

import LabelBadgeBlock from '@/components/atoms/LabelBadgeBlock';
import { Loader } from '@/components/atoms/Loader';
import UmerBox from '@/components/atoms/UmerBox';
import AverageHeader from '@/components/molecules/AverageHeader';
import { PageTitleRow } from '@/components/molecules/PageTitleRow';
import SomethingHappened from '@/components/SomethingHappened';
import { Location } from '@/routes/Client/Overview/TabularOverview2/Location/Location';
import { RequestError } from '@/services/config';
import { OverviewLocation } from '@/services/types';

import styles from './LocationDashboard.module.scss';

interface LocationProps {
    clientId: number;
    location: OverviewLocation;
    hasAccessToMarkHistorical: boolean;
    markHistorical: (cageId: number, historical: boolean) => Promise<unknown>;
    isOptoscaleAdmin?: boolean;
    renderLazy: boolean;
    containerLoading: boolean;
    containerError: Error | RequestError | null;
}

const LocationDashboardPageContent = ({
    clientId,
    location,
    markHistorical,
    hasAccessToMarkHistorical,
    isOptoscaleAdmin = false,
    renderLazy = false,
    containerLoading,
    containerError,
}: LocationProps) => {
    const { pathname } = useLocation();

    const createCompareUrl = (cageIds: number[]) => {
        const cagesToCompare = location.cages.filter((c) => cageIds.includes(c.id));
        const queryString = cagesToCompare
            .map((item) => `${encodeURIComponent(item.name)}~${encodeURIComponent(item.id)}`)
            .join(',');
        return cagesToCompare.length > 1
            ? `/c/${clientId}/compare?data=${queryString}&daypartTag=day`
            : '#';
    };

    if (containerLoading || location == null) {
        return <Loader />;
    }

    if (containerError) {
        return <SomethingHappened />;
    }

    return (
        <>
            <PageTitleRow title={location ? location.name : ''} subTitle={t('Dashboard')} />
            <UmerBox key={location.id} id={location.name.replace(/\s+/g, '')}>
                <>
                    <h2
                        className={`${styles.header}`}
                        data-species={location.fishTypes.name.toLowerCase()}>
                        <span data-type="name">{t('Cage')}</span>
                        <div>
                            <LabelBadgeBlock
                                label={t('species')}
                                term={t(location.fishTypes?.name)}
                                fishTypeName={location.fishTypes?.name.toLowerCase()}
                            />
                        </div>
                    </h2>
                    <AverageHeader location={location} />
                    <hr />
                    <Location
                        key={location.id}
                        location={location}
                        createCompareUrl={createCompareUrl}
                        hasAccessToMarkHistorical={hasAccessToMarkHistorical}
                        markHistorical={markHistorical}
                        hideCompare={true}
                        hideMarkHistorical={false}
                        clientId={clientId}
                    />
                </>
            </UmerBox>
        </>
    );
};

export default LocationDashboardPageContent;
