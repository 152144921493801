import React, { createElement } from 'react';
import { NavLink } from 'react-router-dom';

import IconButton from '@/components/atoms/Buttons/IconButton';
import HamburgerIcon from '@/components/atoms/icons/HamburgerIcon';

import styles from './SlideInMenu.module.scss';

type ItemReturnType = {
    value: string;
    event: React.MouseEvent<HTMLButtonElement>;
};

export interface SlideInMenuProps {
    children?: React.ReactNode;
    isOpen?: boolean;
    ItemOnClickHandler?: ({ value }: ItemReturnType) => void;
}

const SlideInMenu = ({
    children,
    isOpen = false,
    ItemOnClickHandler = () => {},
}: SlideInMenuProps) => {
    /*
    const handleItemOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        const childClicked = event.target as HTMLElement;
        const { value } = childClicked.dataset;

        ItemOnClickHandler({ value: value || 'no value set on child', event: event });
    };
    */

    return (
        <div data-isopen={isOpen} className={styles.slideinmenu}>
            <section>
                {React.Children.map(children, (child, index) => (
                    <div key={index}>{child}</div>
                ))}
            </section>
        </div>
    );
};

interface SlideInMenuItemProps {
    text: string;
    name: string;
    path: string;
    icon?: React.ReactNode | undefined | ReturnType<typeof createElement>;
}
const SlideInMenuItem = ({ name, text, icon = undefined, path }: SlideInMenuItemProps) => {
    return (
        <NavLink
            to={path}
            exact={true}
            className={(isActive: boolean) => {
                return isActive ? styles.active : 'test';
            }}>
            <div data-name={name}>
                {icon ? <span>{icon}</span> : null}
                <span data-value={name}>{text}</span>
            </div>
        </NavLink>
    );
};

interface SlideInMenuTriggerProps {
    triggerHandler?: () => void;
    label?: string;
    collapsed?: boolean;
}
const SlideInMenuTrigger = ({
    triggerHandler = () => {},
    label = 'Open / close menu',
    collapsed = false,
}: SlideInMenuTriggerProps) => {
    return (
        <IconButton onClick={triggerHandler} buttonText={label} onlyIcon={collapsed}>
            <HamburgerIcon height={20} />
        </IconButton>
    );
};

export default SlideInMenu;
export { SlideInMenu, SlideInMenuItem, SlideInMenuTrigger };
