import React, { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import OptoTooltip from '@/components/atoms/OptoTooltip';

import styles from './SwitchButtons.module.scss';

type SwitchConfig = {
    label: string;
    value: string;
    selected?: boolean;
    tooltipKey?: string;
};

export interface SwitchButtonsProps {
    switches?: SwitchConfig[];
    onClick?: (ev: MouseEvent<HTMLButtonElement>) => void;
    size: 'sm' | 'md' | 'lg';
}

const SwitchButtons: React.FC<SwitchButtonsProps & React.ButtonHTMLAttributes<HTMLButtonElement>> =
    ({ switches = [], onClick, size }: SwitchButtonsProps) => {
        const { t } = useTranslation();
        const [activeState, setActiveState] = useState<string>();

        return (
            <div className={classNames(styles.switchbuttons, styles[size])}>
                {switches.map((switchConfig, index) => {
                    activeState === undefined &&
                        switchConfig.selected &&
                        setActiveState(switchConfig.value);

                    return (
                        <OptoTooltip
                            content={t(switchConfig.tooltipKey || '')}
                            nub="down-center"
                            maxWidth={150}
                            textAlign="left"
                            key={index}>
                            <button
                                type="button"
                                data-pos={index === 0 ? 'left' : 'right'}
                                aria-checked={activeState === switchConfig.value}
                                name={switchConfig.value}
                                value={switchConfig.value}
                                className={
                                    activeState === switchConfig.value
                                        ? classNames(
                                              `${styles.active}`,
                                              `${styles.switchbuttonsButtons}`
                                          )
                                        : `${styles.switchbuttonsButtons}`
                                }
                                onClick={(e) => {
                                    setActiveState(switchConfig.value);
                                    onClick?.(e);
                                }}>
                                {t(switchConfig.label)}
                            </button>
                        </OptoTooltip>
                    );
                })}
            </div>
        );
    };

export default SwitchButtons;
export { SwitchButtons };
