import type { ImagesIndicatorResult } from '@/services/types';
import { FullSizeImage } from '@/services/types';

type Indicators = Omit<ImagesIndicatorResult, 'maturity'>;

export const getImagesByIndicatorKey = (images: FullSizeImage[], cageId: number) => {
    return images.reduce(
        (acc, image) => {
            for (const [indicatorKey] of Object.entries(
                (image?.indicators as Indicators) || ({} as Indicators)
            )) {
                if (!acc[indicatorKey]) {
                    acc[indicatorKey] = [];
                }

                acc[indicatorKey].push(image);
            }
            return acc;
        },
        {} as Record<string, FullSizeImage[]>
    );
};
