import React from 'react';
import { t } from 'i18next';

import styles from './AmountRatioWithText.module.scss';

export interface AmountRatioWithTextProps {
    leftSideAmount?: number;
    rightSideAmount?: number;
    text?: string;
}

const AmountRatioWithText = ({
    leftSideAmount = 0,
    rightSideAmount = 0,
    text = '',
}: AmountRatioWithTextProps) => {
    return (
        <div className={styles.activeinactivebioscopes}>
            {`${leftSideAmount}/${rightSideAmount} ${t(text)}`}
        </div>
    );
};

export default AmountRatioWithText;
export { AmountRatioWithText };
