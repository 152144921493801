import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Loader from '@/components/atoms/Loader';
import { BioscopeMovingModalContainer } from '@/components/organisms/MoveBioscopeModal/MoveBioscopeModal';
import { useMe } from '@/contexts/meContext';
import {
    useBioscopeController_ActiveBioscopesForClient,
    useMoveBioscopeController_DeleteLastMove,
} from '@/services/hooks';

import { MoveBioscope } from './MoveBioscope';

interface MoveBioscopeContainerProps {
    clientId: number;
}
export const MoveBioscopeContainer = ({ clientId }: MoveBioscopeContainerProps) => {
    const { t } = useTranslation();
    const [moveBioscope, setMoveBioscope] = useState<number>(null);
    const { canMoveBioscopes } = useMe();

    const bioscopes = useBioscopeController_ActiveBioscopesForClient(clientId);
    useEffect(() => {
        bioscopes.refetch();
    }, [moveBioscope]);

    const deleteLastBioscopeMove = useMoveBioscopeController_DeleteLastMove({
        onSettled: () => bioscopes.refetch(),
    });

    const bioscopeToMove = bioscopes.data?.data?.find((b) => b.id === moveBioscope);

    const onDeleteBioscope = (bioscopeId: number) =>
        deleteLastBioscopeMove.mutateAsync({ bioscopeId });
    const data = bioscopes.data?.data ?? [];

    const mappedBioscopeData = data.map((bioscope) => ({
        id: bioscope.id,
        lastCage: bioscope.lastLocation?.cage?.name,
        lastLocation: bioscope.lastLocation?.cage?.location?.name,
        lastMeasurement: bioscope.lastMeasurement?.timestamp,
        updatedAt: bioscope.lastLocation?.updatedAt,
    }));

    return (
        <div>
            <Loader error={bioscopes.error} loading={bioscopes.isLoading}>
                <MoveBioscope
                    canMoveBioscope={canMoveBioscopes(clientId)}
                    bioscopes={mappedBioscopeData}
                    onDeleteBioscope={onDeleteBioscope}
                    onMoveBioscope={(bioscopeId) =>
                        setMoveBioscope(parseInt(bioscopeId.toString()))
                    }
                />
            </Loader>
            {moveBioscope && (
                <>
                    <BioscopeMovingModalContainer
                        isOpen={moveBioscope !== null}
                        onClose={() => setMoveBioscope(null)}
                        bioscopeId={bioscopeToMove.id}
                    />
                </>
            )}
        </div>
    );
};
