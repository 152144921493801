import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { Chart } from '@/components/organisms/DeprecatedGraphs/Chart';
import LiceChartHeader from '@/components/organisms/DeprecatedGraphs/Chart/headers/ChartHeader/LiceChartHeader';
import FullScreenCard from 'components/Layout/Cards/FullScreenCard/FullScreenCard';

import options from './options';
import plugins from './plugins';

const MIN_FISH_COUNT_FOR_CERTAIN_LICE = 100;

const DEFAULT_COLORS = ['#2f837b', '#5ec5bb', '#1e6e96', '#cb4773', '#eac007', '#7ba44c'];

export default ({ data, colors, GRAPH_LABELS }) => {
    colors = colors || DEFAULT_COLORS;
    const { t } = useTranslation();
    function getAdultLiceTemperatureData(GRAPH_LABELS, list) {
        const datasets = GRAPH_LABELS.map((gl, index) => {
            const dataset = {
                label: t(gl.label),
                backgroundColor: colors[index % colors.length],
                borderColor: colors[index % colors.length],
                pointBackgroundColor: [],
                pointBorderColor: [],
                pointRadius: '3',
                lineTension: 0,
                yAxisID: gl.axis,
                fill: gl.dataLabel === 'temperature' ? true : false,
                data: [],
            };
            list.forEach((row, i) => {
                const isEnoughFishCounted = row?.total > MIN_FISH_COUNT_FOR_CERTAIN_LICE;

                dataset.data.push({
                    x: row?.day,
                    y: row?.[gl.dataLabel],
                });
                if (!isEnoughFishCounted) {
                    dataset.pointBackgroundColor.push('#A9A9A9');
                    dataset.pointBorderColor.push('#A9A9A9');
                } else {
                    dataset.pointBackgroundColor.push(data.backgroundColor);
                    dataset.pointBorderColor.push(data.borderColor);
                }
            });
            return dataset;
        });
        return { datasets };
    }
    return (
        <>
            <Row>
                <Col xs={12}>
                    <FullScreenCard>
                        <Chart
                            type="line"
                            data={getAdultLiceTemperatureData(GRAPH_LABELS, data)}
                            header={(chart) => <LiceChartHeader chart={chart} />}
                            options={options()}
                            plugins={plugins}
                        />
                    </FullScreenCard>
                </Col>
            </Row>
        </>
    );
};
