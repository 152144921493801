import React, { createContext, useContext, useMemo } from 'react';
import {
    useCageController_Context,
    useCageController_FishTypes,
    useLocationController_CageTypes,
} from 'services/hooks';

import { useMe } from '@/contexts/meContext';
import { hasLicense } from '@/helpers/licenses';
import { CageType, FishType } from '@/services/types';

export interface CageContextType {
    cageId?: number;
    cage?: {
        id: number;
        name: string;
        historical: string | null;
        cageType: {
            id: number;
            name: string;
        };
    };
    location?: {
        id: number;
        name: string;
        fishtypeid: number;
        fishTypes?: FishType;
        client: {
            id: number;
            name: string;
        };
    };
    client?: {
        id: number;
        name: string;
        featureFlags: string[];
    };
    timezone?: string;
    dayparts?: {
        id: number;
        tag: string;
    }[];
    licenses?: {
        code: string;
        from: Date;
        to: Date;
    }[];

    weightUnits?: {
        id: number;
        tag: string;
    }[];
    firstDateMeasurement?: string;
    loading: boolean;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    error?: any;
    hasColorWelfare?: boolean;
    hasCageLicense: (...code: string[]) => boolean;
    isTrout: boolean;
    cageTypesList?: CageType[];
}

const CageContext = createContext<CageContextType>({
    loading: true,
    hasCageLicense: () => false,
    isTrout: false,
    cageTypesList: [] as CageType[],
});

function useCage() {
    return useContext<CageContextType>(CageContext);
}

function CageProvider({ cageId, children }) {
    // we want to use [) for query, but UI has [],
    // so we need to add 1 extra day
    const { data, isLoading: isLoadingContext, error } = useCageController_Context(cageId);
    const {
        data: cageTypesList,
        error: cageTypeListError,
        isLoading: isLoadingCageTypeList,
    } = useLocationController_CageTypes();

    const {
        data: fishTypesList,
        error: fishTypeListError,
        isLoading: isLoadingFishTypeList,
    } = useCageController_FishTypes();

    const locationSpecies = data?.data.location.fishtypeid;
    const { isOptoscaleAdmin } = useMe();
    const value: CageContextType = useMemo(() => {
        if (isLoadingContext || isLoadingCageTypeList || isLoadingFishTypeList) {
            return {
                loading: true,
                hasCageLicense: () => false,
                isTrout: false,
                cageTypesList: [] as CageType[],
                cageId: cageId,
            };
        }
        if (error || cageTypeListError || fishTypeListError) {
            return {
                loading: false,
                error,
                hasCageLicense: () => false,
                isTrout: false,
                cageTypesList: [] as CageType[],
                cageId: cageId,
            };
        }
        const d = data.data;

        return {
            loading: false,
            error: null,
            cageTypesList: cageTypesList.data,
            cage: {
                id: d.id,
                name: d.name,
                historical: d.historical,
                cageType: {
                    id: d.cageType?.id ?? cageTypesList.data[0].id,
                    name: d.cageType?.name ?? cageTypesList.data[0].name,
                },
            },
            licenses: d.licenses.map((license) => ({
                code: license.code,
                from: new Date(license.from),
                to: new Date(license.to),
            })),
            dayparts: d.dayparts,
            location: d.location,
            timezone: d.location.timezone,
            weightUnits: d.weightUnits,
            firstDateMeasurement: d.measurementStats.firstMeasurement.timestamp,
            client: d.location.client,
            cageId: cageId,
            hasColorWelfare: d.hasColorWelfare,
            hasCageLicense: (...patterns: string[]) => {
                return isOptoscaleAdmin || hasLicense(d.licenses, patterns);
            },
            //TODO: Find a better way to determine if a location is a trout location
            //This is hardcoded at the moment to only match Kelvesteinen
            isTrout:
                fishTypesList?.data.find((fishType: FishType) => {
                    return fishType.id === locationSpecies && fishType.name === 'Trout';
                }) !== undefined,
        };
    }, [
        data,
        error,
        cageTypeListError,
        fishTypeListError,
        isLoadingContext,
        isLoadingCageTypeList,
        isLoadingFishTypeList,
        cageId,
        isOptoscaleAdmin,
        locationSpecies,
    ]);
    return <CageContext.Provider value={value}>{children}</CageContext.Provider>;
}

export default useCage;
export { CageProvider, useCage };
