import React, { useRef } from 'react';

import { OptoTooltip } from '@/components/atoms/OptoTooltip';
import settings from '@/config/settings';

import styles from './GenericInfoBox.module.scss';

export interface GenericInfoBoxProps {
    title?: string;
    info?: string;
    unit?: string;
    icon?: any;
    circleColor?: CircleColorType;
    grayBackground?: boolean;
    tooltipText?: string;
    shouldHaveTooltip?: boolean;
}

export interface ColorToClassMapper {
    color?: CircleColorType;
    customColorClassName?: string;
    hexColor?: string;
    extraCustomClassName?: string;
}

export enum CircleColorType {
    green = 0,
    blue = 1,
    purple = 2,
    orange = 3,
}

export const colorToClassMapperObj: ColorToClassMapper[] = [
    {
        color: CircleColorType.green,
        customColorClassName: styles.backgroundgreen,
        hexColor: settings.primaryColor,
    },
    {
        color: CircleColorType.purple,
        customColorClassName: styles.backgroundpurple,
        hexColor: settings.purpleColor,
    },
    {
        color: CircleColorType.orange,
        customColorClassName: styles.backgroundorange,
        hexColor: settings.orangeColor,
        extraCustomClassName: styles.rotateicon,
    },
    {
        color: CircleColorType.blue,
        customColorClassName: styles.backgroundblue,
        hexColor: settings.blueColor,
    },
];

const GenericInfoBox = ({
    title = '',
    info,
    unit = '',
    icon,
    circleColor,
    grayBackground,
    tooltipText = '',
    shouldHaveTooltip = false,
}: GenericInfoBoxProps) => {
    const color = useRef<ColorToClassMapper | undefined>({});

    color.current = colorToClassMapperObj.find((item) => {
        return item.color === circleColor;
    });

    return (
        <div
            className={`${styles.genericinfobox} ${
                grayBackground ? styles.boxbackgroundcolorgray : styles.boxbackgroundcolorwhite
            } ${styles.infoboxnotooltipcontainer}`}>
            {shouldHaveTooltip && (
                <OptoTooltip content={tooltipText} nub={'up-center'}>
                    <div className={`${styles.genericinfobox} ${styles.infoboxtooltipcontainer}`} />
                </OptoTooltip>
            )}
            <div className={styles.mainelementcontainer}>
                <div
                    className={`${styles.iconcontainer} ${color.current?.customColorClassName} ${
                        color.current?.extraCustomClassName !== undefined
                            ? color.current.extraCustomClassName
                            : ''
                    }`}>
                    {icon
                        ? icon({
                              color: color.current?.hexColor,
                              height: 20,
                              width: 20,
                              size: 20,
                          })
                        : ''}
                </div>
                <div className={styles.mainsubcontainer}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.infocontainer}>
                        <div className={styles.info}>{info}</div>
                        <div className={styles.unit}>{unit}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GenericInfoBox;
export { GenericInfoBox };
