import { createContext, ReactNode, useContext, useMemo } from 'react';
import {
    DailyLaksvelData,
    DailyLaksvelResult,
    FullSizeImage,
    LaksvelTrend,
    Last500fishResult,
} from 'services/types';

import { placeHolder } from '@/services/config';
import {
    useLaksvelController_GetLaksvelLast500Fish,
    useLaksvelController_laksvelDaily,
    useWelfareController_allImages,
} from '@/services/hooks';
import { getImagesByIndicatorKey } from '@/utils/mapImagesByIndicatorKey';

import { useMe } from './meContext';

interface LaksvelContextType {
    laksvelData: DailyLaksvelResult[];
    laksvelTrendData: LaksvelTrend;
    averageLaksvelData: Last500fishResult;
    cageId: number;
    timezone?: string;
    dayparts:
        | {
              id: number;
              tag: string;
          }[]
        | undefined;
    client?:
        | {
              id: number;
              name: string;
          }
        | undefined;
    loading: boolean;
    isOptoscaleAdmin: boolean;
    laksvelImages: Record<string, FullSizeImage[]> | Record<string, never>;
}

const LaksvelContext = createContext<LaksvelContextType | null>(null);

const LaksvelProvider = ({
    cageId,
    dayparts,
    timezone = 'UTC',
    children,
}: {
    cageId: number | undefined;
    dayparts: { tag: string; id: number }[] | undefined;
    timezone: string;
    children: ReactNode;
}) => {
    const { isOptoscaleAdmin } = useMe();

    // Fetch data from the server
    if (!cageId) {
        return null;
    }

    // Fetch data for daily graphs
    const { data: laksvelMetaData, isLoading } = useLaksvelController_laksvelDaily(
        cageId,
        {},
        {
            placeholderData: placeHolder({ dailyData: [] } as DailyLaksvelData),
            keepPreviousData: true,
        }
    );

    // Fetch data for last 500 fish graphs
    const { data: laksvel500MetaData, isLoading: is500Loading } =
        useLaksvelController_GetLaksvelLast500Fish(
            cageId,
            {
                placeholderData: placeHolder({} as Last500fishResult),
                keepPreviousData: true,
            },
            {}
        );

    const {
        data: lakesvelImageData,
        isLoading: imagesdataLoading,
        isFetching: refetchingImages,
    } = useWelfareController_allImages(
        cageId,
        { type: 'color-welfare-image' },
        {
            placeholderData: placeHolder([]),
            keepPreviousData: true,
        }
    );

    const laksvelRestContext = useMemo<LaksvelContextType>(() => {
        // Daily laksvel
        const laksvelData = laksvelMetaData?.data?.dailyData ?? ([] as DailyLaksvelResult[]);

        // Laksvel trend data
        const laksvelTrendData =
            laksvelMetaData?.data?.trend ?? (undefined as unknown as LaksvelTrend);

        // Images
        const imagesdata: Record<string, FullSizeImage[]> = isOptoscaleAdmin
            ? lakesvelImageData?.data
                ? getImagesByIndicatorKey(lakesvelImageData?.data, cageId)
                : {}
            : {};

        // Last 500ish fish data for laksvel
        const averageLaksvelData = laksvel500MetaData?.data ?? ({} as Last500fishResult);

        const dataStillLoading = isLoading || imagesdataLoading || is500Loading;

        if (!laksvelData || !imagesdata || !averageLaksvelData) {
            const defaultObject = {
                loading: dataStillLoading,
                laksvelData: [] as DailyLaksvelResult[],
                averageLaksvelData: {} as Last500fishResult,
                laksvelTrendData: undefined as unknown as LaksvelTrend,
                laksvelImages: {} as Record<string, FullSizeImage[]> | Record<string, never>,
                cageId: cageId,
                dayparts: dayparts,
                timezone: timezone,

                isOptoscaleAdmin,
            };

            return defaultObject;
        }

        const populatedObject = {
            loading: dataStillLoading,
            laksvelData: laksvelData,
            laksvelTrendData: laksvelTrendData,
            averageLaksvelData: averageLaksvelData,
            laksvelImages: imagesdata,
            cageId,
            dayparts,
            timezone,
            isOptoscaleAdmin,
        };

        return populatedObject;
    }, [
        cageId,
        dayparts,
        timezone,
        isLoading,
        imagesdataLoading,
        is500Loading,
        laksvelMetaData,
        lakesvelImageData,
        laksvel500MetaData,
        isOptoscaleAdmin,
    ]);

    return <LaksvelContext.Provider value={laksvelRestContext}>{children}</LaksvelContext.Provider>;
};

const useLaksvel = (): LaksvelContextType => {
    return useContext(LaksvelContext) as LaksvelContextType;
};

export default useLaksvel;
export { LaksvelProvider, useLaksvel, LaksvelContext };
