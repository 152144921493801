import React from 'react';
import { Spinner } from 'reactstrap';

import { IconButton } from '@/components/atoms/Buttons/IconButton';
import { DownloadIcon } from '@/components/atoms/icons';

import { useDownloadCSVXLSX } from '../hooks/download-CSV-XLSX';
export const DownloadCsvXlsx = ({
    label,
    data,
    variant = 'secondary',
    nameToInclude = undefined,
    COLUMNS = [],
    buttonSize = 'medium',
}) => {
    const { loadingdownloadCSV, loadingXLSX, downloadCSV, downloadXLSX } = useDownloadCSVXLSX(
        data,
        nameToInclude,
        COLUMNS
    );
    if (label === 'CSV') {
        return (
            <IconButton
                size={buttonSize}
                variant={variant}
                buttonText={label}
                onClick={() => downloadCSV({ label, data })}>
                {loadingdownloadCSV ? <Spinner size="sm" /> : <DownloadIcon size={20} />}
            </IconButton>
        );
    }
    return (
        <IconButton
            size={buttonSize}
            variant={variant}
            onClick={() => downloadXLSX({ label, data })}
            buttonText={label}>
            {loadingXLSX ? <Spinner size="sm" /> : <DownloadIcon size={20} />}
        </IconButton>
    );
};
