import React from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import {
    useClientController_GetClient,
    useClientController_Overview,
    useUserController_Logout,
} from 'services/hooks';

import OptoBreadCrumb from '@/components/molecules/OptoBreadCrumb/OptoBreadCrumb';
import { ClientInfo } from '@/services/types';

import { useHeaderContext } from '../../../../contexts/headerContext';
import { useMe } from '../../../../contexts/meContext';
import { Header } from '../Header/Header';

export interface HeaderContainerProps {
    children?: React.ReactNode;
    baseUrl?: string;
    languageBar?: boolean;
    showHomeButton?: boolean;
}

const HeaderContainer = ({
    children,
    baseUrl,
    languageBar,
    showHomeButton = true,
}: HeaderContainerProps) => {
    const { set, expanded } = useHeaderContext();
    const location = useLocation();
    const history = useHistory();

    const headerMenuItemHandler = (to): void => {
        history.push(to);
    };

    const { mutateAsync: onLogout } = useUserController_Logout();

    const me = useMe();

    const clientMatch = matchPath<{ clientId: string }>(location.pathname, {
        path: '/c/:clientId',
        exact: false,
        strict: true,
    });

    const clientId = clientMatch ? parseInt(clientMatch.params.clientId) : null;

    const locationMatch = matchPath<{ clientId: string; locationId: string }>(location.pathname, {
        path: '/c/:clientId/location/:locationId',
        exact: false,
        strict: true,
    });
    const locationId = locationMatch ? parseInt(locationMatch.params.locationId) : null;

    const cageMatch = matchPath<{ clientId: string; locationId: string; cageId: string }>(
        location.pathname,
        {
            path: '/c/:clientId/location/:locationId/pen/:cageId',
            exact: false,
            strict: true,
        }
    );
    const cageId = cageMatch ? parseInt(cageMatch.params.cageId) : null;

    let lastPart = '';
    if (cageId) {
        lastPart = location.pathname.replace(cageMatch.url, '');
    } else if (locationId) {
        lastPart = location.pathname.replace(locationMatch.url, '');
    } else if (clientId) {
        lastPart = location.pathname.replace(clientMatch.url, '');
    }

    let navBarBrandLogoLink = '/';
    if (clientId && !me.isOptoscaleAdmin) {
        navBarBrandLogoLink = `/c/${clientId}`;
    }

    const internalPage = location.pathname.startsWith('/internal');

    if (internalPage) {
        lastPart = location.pathname.replace('/internal', '');
    }

    const {
        data: clientData,
        isLoading: clientIsLoading,
        error: clientError,
    } = useClientController_GetClient(clientId, {
        enabled: clientId !== null,
    });

    const {
        isLoading,
        data: clientOverviewData,
        error,
        refetch,
    } = useClientController_Overview(clientId, {
        staleTime: 5 * 60 * 1000,
        cacheTime: 5 * 60 * 1000,
        enabled: clientId !== null,
    });

    const clientInfo: ClientInfo = clientData?.data ?? ({} as ClientInfo);

    return (
        <Header
            expanded={expanded}
            setExpandedHandler={set}
            location={location}
            menuItemHandler={headerMenuItemHandler}
            user={me.state.user}
            client={clientInfo}
            clients={me.state.clients}
            languageBar={languageBar}
            onLogout={onLogout}
            navBarBrandLogoLink={navBarBrandLogoLink}>
            <OptoBreadCrumb
                client={clientInfo}
                clients={me?.state?.clients ? me.state.clients : []}
                locationId={locationId}
                cageId={cageId}
                locations={
                    clientOverviewData?.data?.locations ? clientOverviewData.data.locations : []
                }
                isLoading={isLoading}
                lastUrlPart={lastPart}
                showHomeButton={showHomeButton}
            />
            {children}
        </Header>
    );
};

export default HeaderContainer;
export { HeaderContainer };
