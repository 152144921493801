import React from 'react';
import { useTranslation } from 'react-i18next';
import { AllWeeklyReportResult } from 'services/types';

import { PageTitleRow } from '@/components/molecules/PageTitleRow';
import ToastInfoTemplate from '@/components/molecules/ToastTemplate/ToastTemplate';
import { Page, PageContent } from 'components/Layout';
import NoData from 'components/NoData';

import { LocationContainer } from './Locations/LocationContainer/LocationContainer';

import styles from './LiceReport.module.scss';

interface OverviewProps {
    clientId: number;
    clientName: string;
    reports: AllWeeklyReportResult[];
}

const LiceReport = ({ reports, clientId, clientName }: OverviewProps) => {
    const { t } = useTranslation();
    if (reports.length === 0) {
        return (
            <Page title={t('Lice report')}>
                <PageContent>
                    <NoData customText={'No locations'} />
                </PageContent>
            </Page>
        );
    }

    if (reports.length === 1 && reports[0].locations.length === 0) {
        return (
            <Page title={t('Lice report')}>
                <PageContent>
                    <NoData customText={'No Report'} />
                </PageContent>
            </Page>
        );
    }

    ToastInfoTemplate({ content: t('Lice report missing pen explain'), page: 'lice-report' });

    // Due to the required quality and number of measurements, the lice report for a certain pen might be excluded on this tabular overview. A new way of generate a report  is under development and will be available soon.

    return (
        <div className={styles.liceReportPage}>
            <PageTitleRow title={t('Lice report')} />

            {reports.map((report, index) => (
                <LocationContainer
                    key={report.isoWeek.toString()}
                    weekNumber={`${t('Week')} ${report.isoWeek}, ${report.isoYear}${
                        report.interimReport ? ` - ${t('Interim Report')}` : ''
                    }`}
                    locations={report.locations}
                    openDefault={index === 0}
                    clientName={clientName}
                    clientId={clientId}
                />
            ))}
        </div>
    );
};

export default LiceReport;
