import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { t } from 'i18next';

import BlockTitle from '@/components/atoms/BlockTitle';
import { DownloadCsvXlsx } from '@/components/DownloadCsvXlsx';
import { PageTitleRow } from '@/components/molecules/PageTitleRow';
import SlideInMenu, {
    SlideInMenuItem,
    SlideInMenuTrigger,
} from '@/components/molecules/SlideInMenu';
import useCage from '@/contexts/cage-context';

import styles from './Laksvel.module.scss';

interface LaksevelPageContentProps {
    laksvelmenu: {
        key: number;
        label?: string;
        path?: string;
        icon?: undefined;
        page?: React.ComponentType;
        params?: {
            i18n?: string; // Maybe this is a good idea
        };
    }[];
    clientId: number | undefined;
    cageId: number | undefined;
    children?: React.ReactNode;
    routerProps: RouteComponentProps;
    isOptoscaleAdmin?: boolean;
    title: string;
}

const LaksvelPageContent = ({
    laksvelmenu,
    cageId,
    clientId,
    children,
    title,
}: LaksevelPageContentProps) => {
    const [menuOpen, setMenuOpen] = useState(true);
    const { location } = useCage();
    const handleItemClick = ({ value, event }) => {
        //const { pathname } = history.location;
    };

    return (
        <div className={styles.laksvelPageContent}>
            <PageTitleRow title={t('Laksvel')}>
                <div>
                    <DownloadCsvXlsx label="CSV" data={[]} COLUMNS={[]} />
                    <DownloadCsvXlsx label="XLSX" data={[]} COLUMNS={[]} />
                </div>
            </PageTitleRow>

            <div className={styles.laksvelPageContentGrid} data-menuopen={menuOpen}>
                <BlockTitle heading={title} />
                <SlideInMenuTrigger
                    triggerHandler={() => setMenuOpen(!menuOpen)}
                    label={t('Attributes')}
                    collapsed={!menuOpen}
                />

                <section>{children}</section>
                <aside>
                    <SlideInMenu isOpen={menuOpen} ItemOnClickHandler={handleItemClick}>
                        {laksvelmenu.map((item) => (
                            <SlideInMenuItem
                                key={item.key}
                                name={item?.path || ''}
                                text={
                                    t(item?.params?.i18n, {
                                        postProcess: 'capitalizeFirstLetter',
                                    }) || ''
                                }
                                path={`/c/${clientId}/location/${location?.id}/pen/${cageId}/laksvel${item?.path}`}
                            />
                        ))}
                    </SlideInMenu>
                </aside>
            </div>
        </div>
    );
};

export default LaksvelPageContent;
export { LaksvelPageContent };
