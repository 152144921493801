import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './HelpPageVideos.module.scss';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import bioscope1 from 'svg/bioscope1-0.jpg';
import bioscope2 from 'svg/bioscope-2-0.jpg';

interface HelpPageVideosProps {
    linkListBioscope1: {
        title: string;
        link: string;
    }[];
    linkListBioscope2: {
        title: string;
        link: string;
    }[];
    heading: any;
    description: string;
}

const HelpPageVideos = ({
    linkListBioscope1,
    linkListBioscope2,
    heading,
    description,
}: HelpPageVideosProps) => {
    const { t } = useTranslation();
    const [isModalOpen, setModalOpen] = useState(false);
    const [videoSrc, setVideoSrc] = useState<string | null>(null);

    const openModal = (e: React.MouseEvent, videoUrl: string) => {
        e.preventDefault();
        setVideoSrc(videoUrl);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setVideoSrc(null);
    };

    return (
        <div data-type="helpVideoSection" className="row">
            <div className="col-md-12">
                <h2 className={styles.helpHeading}>{heading}</h2>
                <p>{description}</p>

                <div className="row">
                    <div className="col-md-6">
                        <h3>{t('Bioscope 1.0')}</h3>
                        <div className={styles.helpVideoContent}>
                            <img src={bioscope1} alt="" />
                            <ul>
                                {linkListBioscope1.map((list, index) => {
                                    return (
                                        <li key={index}>
                                            <a href="#" onClick={(e) => openModal(e, list.link)}>
                                                {list.title}
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h3>{t('Bioscope 2.0')}</h3>
                        <div className={styles.helpVideoContent}>
                            <img src={bioscope2} alt="" />
                            <ul>
                                {linkListBioscope2.map((list, index) => {
                                    return (
                                        <li key={index}>
                                            <a href="#" onClick={(e) => openModal(e, list.link)}>
                                                {list.title}
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className={styles.helpModal} isOpen={isModalOpen} toggle={closeModal} size="lg">
                <ModalHeader toggle={closeModal}>{t('Installation video')}</ModalHeader>
                <ModalBody>
                    {videoSrc ? (
                        <video width="100%" controls autoPlay>
                            <source src={videoSrc} type="video/mp4" />
                            <track default kind="captions" srcLang="en" />
                            {t('Your browser does not support the video tag.')}
                        </video>
                    ) : null}
                </ModalBody>
            </Modal>
        </div>
    );
};
export default HelpPageVideos;
