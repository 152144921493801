import { useEffect, useState } from 'react';

import { Loader } from '@/components/atoms/Loader';
import SomethingHappened from '@/components/SomethingHappened';
import { useMe } from '@/contexts/meContext';
import LocationComparePageContent from '@/routes/Client/Location/pages/LocationCompare/LocationComparePageContent';
import LocationFilterCompare from '@/routes/Client/Location/pages/LocationCompare/LocationFilterCompare';
import { useClientController_Overview } from '@/services/hooks';
import { OverviewCage, OverviewLocation } from '@/services/types';

import LocationCompareGraphContainer from './LocationCompareGraph';

import styles from './LocationCompare.module.scss';

const getUrlParameters = (search: string) => {
    const params = new URLSearchParams(search);
    const dataParam = params.get('data');

    if (!dataParam) {
        return [];
    }
    const dataArray = dataParam.split(',');
    const result = dataArray.reduce((result, item) => {
        const [key, value] = item.split('~');
        result[value] = key;
        return result;
    }, {});
    return result;
};

const LocationCompareContainer = ({
    clientId,
    locationId,
}: {
    clientId: number;
    locationId: number;
}) => {
    const { isOptoscaleAdmin } = useMe();

    const {
        isLoading: isLoadingClientLocations,
        data: clientLocationsData,
        error: clientLocationsDataError,
        refetch: clientLocationRefetch,
    } = useClientController_Overview(clientId, {
        staleTime: 5 * 60 * 1000,
        cacheTime: 5 * 60 * 1000,
    });

    const [currentLocation, setCurrentLocation] = useState<OverviewLocation>(null);
    const [filteredCages, setFilteredCages] = useState<OverviewCage[]>([]);
    const [currentCages, setCurrentCages] = useState<OverviewCage[]>([]);

    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [daypartTag, setDaypartTag] = useState('day');

    const onDateChange = (from: string, to: string, daypartTag: string) => {
        if (from) {
            setFrom(from);
        } else {
            setFrom(null);
        }

        if (to) {
            setTo(to);
        } else {
            setTo(null);
        }

        if (daypartTag) {
            setDaypartTag(daypartTag);
        } else {
            setDaypartTag('day');
        }
    };

    useEffect(() => {
        const location = clientLocationsData?.data?.locations?.find(
            (x) => x.id === locationId
        ) as OverviewLocation;
        if (location) {
            setCurrentLocation(location);
            setFilteredCages(location.cages.filter((x) => x.weight && !x.historical));
            setCurrentCages(location.cages.filter((x) => x.weight && !x.historical));
        } else {
            setCurrentLocation(null);
            setCurrentCages([]);
        }
    }, [clientLocationsData]);

    const handleCagesChanged = (cages: OverviewCage[]) => {
        setCurrentCages(cages);
    };

    return (
        <div className={styles.pageContent}>
            {(isLoadingClientLocations || !currentLocation) && <Loader />}
            {clientLocationsDataError && <SomethingHappened />}
            {currentLocation && (
                <div>
                    <LocationComparePageContent
                        fullFilteredCagesList={filteredCages}
                        currentCages={currentCages}
                        onCagesChanged={handleCagesChanged}
                        location={currentLocation}
                    />
                    <div className={styles.compareGraphContainer}>
                        <LocationCompareGraphContainer
                            clientId={clientId}
                            locationId={locationId}
                            cages={currentCages}
                            toDate={to}
                            fromDate={from}
                            dayartTagParam={daypartTag}
                            containerIsLoading={isLoadingClientLocations}
                            containerError={clientLocationsDataError}
                        />

                        <LocationFilterCompare onDateChange={onDateChange} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default LocationCompareContainer;
export { LocationCompareContainer };
