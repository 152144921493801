import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MeasurmentOverviewResult } from 'services/types';

import UmerBox from '@/components/atoms/UmerBox';
import Options, {
    addXYunitis,
    autoTimeScale,
    chartTitle,
    minMax,
    weightAndLengthUnitConverter,
} from '@/components/organisms/OptoGraphs/optionsModifiers';
import {
    defaultLineChartOptions,
    OverViewToFishSpeedChartMapper,
} from '@/components/organisms/OptoGraphs/OptoMixedChart';
import OptoMixedChart, {
    ChartActionButtons,
} from 'components/organisms/OptoGraphs/OptoMixedChart/OptoMixedChart';

interface FishSpeedPageContentProps {
    loading: boolean;
    weightUnitTag: 'kg' | 'g' | 'mm' | 'cm' | 'm' | 'oz' | 'lb' | '%';
    daypartTag: string;
    data: MeasurmentOverviewResult;
    disabeledDayTags: string[];
    timeUnitTag: string;
    actionButtons?: ChartActionButtons;
}

const FishSpeedPageContent = ({ loading = false, data }: FishSpeedPageContentProps) => {
    const { t, i18n } = useTranslation();

    function toLocale(language: string) {
        return language === 'nb' ? 'nb-NO' : language === 'es' ? 'es-ES' : 'en-US';
    }

    const [locale, setLocale] = useState('nb-NO');

    useEffect(() => {
        setLocale(toLocale(i18n.language));
    }, [i18n.language]);

    return (
        <>
            {locale && (
                <UmerBox>
                    <OptoMixedChart
                        chartName={t('Swim Speed')}
                        chartData={OverViewToFishSpeedChartMapper({
                            overview: data,
                            inactiveSetLegend: ['all', 'short_day', 'night'],
                        })}
                        actionButtons={{
                            copyAsPng: true,
                            resetZoom: true,
                            downloadAsPng: true,
                            fullScreen: true,
                            timeScaleSwitch: false,
                        }}
                        chartOptions={Options(defaultLineChartOptions).modify(
                            autoTimeScale({}),
                            chartTitle({ title: t('Swim Speed') }),
                            weightAndLengthUnitConverter({
                                locale: locale,
                                type: 'number',
                                fromUnit: 'bl/s',
                                toUnit: 'bl/s',
                            }),
                            addXYunitis({ y: t('swimspeed'), x: 'days' }),
                            minMax({
                                min: 0,
                                max: 2.5,
                            })
                        )}
                        loading={loading}
                    />
                </UmerBox>
            )}
        </>
    );
};

export default FishSpeedPageContent;
export { FishSpeedPageContent };
