import React, { MouseEvent } from 'react';
import cn from 'classnames';

import type { IconButtonProps } from '@/components/atoms/Buttons/IconButton';
import { IconButton } from '@/components/atoms/Buttons/IconButton';
import { AnchorButton } from 'components/atoms/Buttons/AnchorButton';

import styles from './button.module.scss';

const useClasses = (filledBackground, color, fullWidth, disabled, className) =>
    cn(className, styles.button, styles[`${filledBackground ? 'filled' : 'normal'}_${color}`], {
        [styles.fullWidth]: fullWidth,
        [styles.disabled]: disabled,
    });

interface ButtonProps extends IconButtonProps {
    onClick?: (ev: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
    confirmationText?: string;
    filledBackground?: boolean;
    fullWidth?: boolean;
    disabled?: boolean;
    className?: string;
    type?: 'button' | 'submit' | 'reset';
    variant?: 'primary' | 'secondary' | 'danger' | 'table';
    size?: 'small' | 'medium' | 'large';
    to?: string;
    defaultButton?: boolean;
    children: React.ReactNode | React.ReactNode[];
}

const Button: React.FC<ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
    children,
    confirmationText = null,
    type = 'button',
    size = 'medium',
    disabled = false,
    variant = 'primary',
    onlyIcon = false,
    buttonText = '',
    to = undefined,
    onClick = () => {},
    ...buttonProps
}) => {
    const confirmationOnclick = confirmationText
        ? (ev: MouseEvent<HTMLButtonElement>) => {
              ev.stopPropagation();
              const confirm = window?.confirm(confirmationText);

              if (confirm) {
                  typeof onClick === 'function' && onClick(ev);
              }
          }
        : (ev: MouseEvent<HTMLButtonElement>) => {
              ev.stopPropagation();
              typeof onClick === 'function' && onClick(ev);
          };

    return to ? (
        <AnchorButton size={size} disabled={disabled} to={to} variant={variant}>
            {children}
        </AnchorButton>
    ) : (
        <IconButton
            variant={variant}
            disabled={disabled}
            data-navigation={to}
            size={size}
            onlyIcon={onlyIcon}
            type={type}
            buttonText={buttonText}
            {...buttonProps}
            onClick={confirmationOnclick}>
            {children}
        </IconButton>
    );
};

export default Button;
export { Button };
