import React from 'react';
import { Link } from 'react-router-dom';
import { useMe } from 'contexts/meContext';
import { t } from 'i18next';
import formattedTs from 'utils/formattedTs';
import { v4 as uuidv4 } from 'uuid';

import CalendarIcon from '@/components/atoms/icons/CalendarIcon';
import Notice from '@/components/atoms/Notice/Notice';
import { OptoTooltip } from '@/components/atoms/OptoTooltip';
import settings, { hexToRGBA } from '@/config/settings';

import styles from './BioscopeHistoricalList.module.scss';
interface BioscopeHistoricalListProps {
    bioscopes: { id: number; active: boolean; history: { from: Date; to?: Date }[] }[];
}

function SingleHistoricalEntry({
    index,
    ...item
}: BioscopeHistoricalListProps['bioscopes'][0] & { index: number }) {
    const id = `bioscopeHistorical${item.id}`;
    const { isOptoscaleAdmin } = useMe();
    return (
        <span key={id} className={styles.bioscopeHistoricalList}>
            <span id={id} className={item?.active ? '' : 'text-muted'}>
                {index > 0 && item.id !== undefined ? ', ' : null}
                {isOptoscaleAdmin && item.id ? (
                    <Link
                        className={item?.active ? '' : 'text-muted'}
                        to={`/internal/bioscopes/${item.id}`}>
                        {item.id}
                    </Link>
                ) : (
                    item.id ?? ''
                )}
                {item.history.map((historyItem) => {
                    const id = uuidv4();
                    const id2 = uuidv4();
                    const dateTo = historyItem
                        ? formattedTs(historyItem.to, undefined, {
                              format: 'DD-MM-YYYY',
                          })
                        : t('now');

                    const dateFrom = formattedTs(historyItem.from, undefined, {
                        format: 'DD-MM-YYYY - ',
                    });
                    const dateArray = [dateFrom];

                    if (dateTo !== null) {
                        dateArray.push(dateTo);
                    }

                    const tipElement = (
                        <Notice
                            key={id2}
                            heading="Active since"
                            message={dateArray}
                            variant={'default'}
                            icon={false}
                        />
                    );

                    return (
                        <>
                            <OptoTooltip key={id} nub="down-center" content={tipElement}>
                                <CalendarIcon color={hexToRGBA(settings.secondaryColor, 0.5)} />
                            </OptoTooltip>
                        </>
                    );
                })}
            </span>
        </span>
    );
}

function BioscopeHistoricalList({ bioscopes }: BioscopeHistoricalListProps) {
    return (
        <>
            {bioscopes?.map((item, index) => {
                const id = uuidv4();
                return (
                    <SingleHistoricalEntry
                        key={id}
                        id={item.id}
                        active={item.active}
                        history={item.history}
                        index={index}
                    />
                );
            })}
        </>
    );
}

export default BioscopeHistoricalList;
