import { Page, PageContent } from '@/components/Layout';
import { GenericSidebar, SidebarType } from '@/components/molecules/GenericSidebar';
import UserPage from '@/components/UserManagmentPage';
import regionSidebarOptions from '@/routes/Client/Overview';

const RegionUserManagementContainer = ({ clientId }: { clientId: number }) => {
    const layerUrlPrefix = `/c/${clientId}`;

    return (
        <Page title="Users">
            <GenericSidebar
                sideBarOptions={regionSidebarOptions}
                sidebarType={SidebarType.regionLevel}
                layerUrlPrefix={layerUrlPrefix}
            />
            <PageContent>
                <UserPage clientId={clientId} />
            </PageContent>
        </Page>
    );
};

export default RegionUserManagementContainer;
export { RegionUserManagementContainer };
