import React from 'react';

export enum SidebarType {
    regionLevel = 0,
    locationLevel = 1,
    cageLevel = 2,
    internalLevel = 3,
}

export interface GenericPage {
    id?: number;
    parentId?: number;
    label?: string;
    page?: React.ComponentType;
    roles?: string[];
    exact?: boolean;
    licenses?: string[];
    key: number;
    params?: {
        [key: string]: string;
    };
    path: string;
    sidebarToShowFor?: SidebarType;
    image?: any;
}

export type Pages = GenericPage[];

export interface GenericSideMenuBackOption {
    url: string;
    title: string;
}

export { default, GenericSidebar } from './GenericSidebar';
