import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownToggle, Table } from 'reactstrap';
import cn from 'classnames';
import moment from 'moment';
import type {
    CreateNewContractInput,
    CreateNewContractLicenseInput,
    LicenseInfo,
} from 'services/types';
import type { Contract } from 'services/types';

import { Button } from 'components/atoms/Buttons';
import { SimpleToolTip } from 'components/atoms/SimpleToolTip';
import { BioscopeContractListOfBioscopes } from 'components/molecules/BioscopeContractListOfBioscopes/BioscopeContractListOfBioscopes';
import { ModalAddLicense, type NewLicenseProps } from 'components/molecules/ModalAddLicense';
import { ModalUpdateLicense } from 'components/molecules/ModalUpdateLicense';

import ModalUpdateBioscopeContract from '../ModalUpdateBioscopeContract/ModalUpdateBioscopeContract';

import styles from './BioscopeContract.module.scss';

export interface MoveCallbackParameters {
    bioscopeId: number;
    cageId: number;
    clientId: number;
    from: string;
    contractId: number;
}

export interface BioscopeContractProps {
    contract: Contract;
    licenseList: LicenseInfo[];
    onCreateLicense: NewLicenseProps['onSubmitted'];
    onUpdateContract: (id: number, license: CreateNewContractInput) => Promise<unknown>;
    onUpdateLicense: (licenseId, license: CreateNewContractLicenseInput) => Promise<unknown>;
    onDeleteLicense: (id: number) => void;
    openMoveModal: (bioscopeId: number) => void;
}

export const BioscopeContract = ({
    contract,
    licenseList,
    onCreateLicense,
    onUpdateLicense,
    onDeleteLicense,
    onUpdateContract,
    openMoveModal,
}: BioscopeContractProps) => {
    const { t } = useTranslation();

    const [dropdownOpen, setDropdownOpen] = useState(false);

    return (
        <div className={styles.BioscopeContract}>
            <Dropdown
                toggle={() => {
                    setDropdownOpen(!dropdownOpen);
                }}>
                <DropdownToggle
                    tag="h3"
                    className={
                        dropdownOpen ? 'headingForCollapse openedCollapse' : 'headingForCollapse'
                    }>
                    {t(contract.description)}
                    <i
                        className={cn({
                            fas: true,
                            'fa-caret-up': dropdownOpen,
                            'fa-caret-down': !dropdownOpen,
                        })}
                    />
                </DropdownToggle>
                <div
                    className={cn({
                        overflowHidden: true,
                        height0: !dropdownOpen,
                        heightAuto: dropdownOpen,
                    })}>
                    <div className={styles.addLicense}>
                        <ModalUpdateBioscopeContract
                            contractData={contract}
                            onSubmitted={onUpdateContract}
                        />
                        <ModalAddLicense licenses={licenseList} onSubmitted={onCreateLicense} />
                    </div>
                    <Table>
                        <thead>
                            <tr>
                                <th>{t('Name')}</th>
                                <th>{t('From')}</th>
                                <th>{t('To')}</th>
                                <th>{t('Actions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {contract.licenses.map((license, i) => {
                                const currentDate = moment().format('YYYY-MM-DD');
                                const dayTo = moment(license.dayTo).format('YYYY-MM-DD');
                                const dayfrom = moment(license.dayFrom).format('YYYY-MM-DD');
                                return (
                                    <tr key={i}>
                                        <td className={cn(styles.td_flex)}>
                                            {license.license.name}
                                            {currentDate > dayTo ? (
                                                <SimpleToolTip
                                                    helpText={license.license.name
                                                        .replace(/\s+/g, '-')
                                                        .toLowerCase()}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                        </td>
                                        <td>{dayfrom}</td>
                                        <td>{dayTo}</td>
                                        <td>
                                            <ModalUpdateLicense
                                                licenseData={{
                                                    dayFrom: license.dayFrom,
                                                    dayTo: license.dayTo,
                                                    licenseId: license.license.id,
                                                }}
                                                licenses={licenseList}
                                                onSubmitted={(input) =>
                                                    onUpdateLicense(license.id, input)
                                                }
                                            />
                                            <Button
                                                variant={'danger'}
                                                onClick={() => onDeleteLicense(license.id)}
                                                confirmationText={t(
                                                    'Are you sure you want to delete Bioscope License?'
                                                )}>
                                                {t('Delete')}
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    {
                        <BioscopeContractListOfBioscopes
                            bioscopes={contract.bioscopeLocations}
                            openMoveModal={openMoveModal}
                        />
                    }
                </div>
            </Dropdown>
        </div>
    );
};
