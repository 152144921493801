import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import IconButton from '@/components/atoms/Buttons/IconButton';
import { FeedBackIcon } from '@/components/atoms/icons/FeedBackIcon';
import { OptoTooltip } from '@/components/atoms/OptoTooltip';
import { SmallDropDown } from '@/components/molecules/SmallDropdown';
import { ImageFeedbackDto } from '@/services/types';

import DropDownObjects from './ShareFalseDetectionDropDownObjects';

import styles from './ShareFalseDetectionDropdown.module.scss';

export interface ShareFalseDetectionDropdownProps {
    children?: React.ReactNode;
    isOptoscaleAdmin?: boolean;
    onCommitFalseDetection: (feedbackDto: ImageFeedbackDto) => void;
    showLiceOptions: boolean;
    showHealthOptions: boolean;
}

const ShareFalseDetectionDropdown = ({
    isOptoscaleAdmin = false,
    onCommitFalseDetection = () => {},
    showLiceOptions = false,
    showHealthOptions = false,
}: ShareFalseDetectionDropdownProps) => {
    const { t } = useTranslation();
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const [selectedDropdownTags, setSelectedDropdownTags] = useState('');
    const [buttonShouldBeActive, setButtonShouldBeActive] = useState(false);

    const allDropdownTags = DropDownObjects;

    const dropdownTags: any[] = [];

    if (showLiceOptions) {
        allDropdownTags
            .filter((x) => x.type === 'lice' && !x.onlyOptoScaleAdmin)
            .map((obj) => {
                dropdownTags.push(obj);
            });
    }
    if (showHealthOptions) {
        allDropdownTags
            .filter((x) => x.type === 'health' && !x.onlyOptoScaleAdmin)
            .map((obj) => {
                dropdownTags.push(obj);
            });
    }

    if (isOptoscaleAdmin) {
        allDropdownTags
            .filter((x) => x.onlyOptoScaleAdmin)
            .map((obj) => {
                dropdownTags.push(obj);
            });
    }

    const toggleDropdown = (e) => {
        e.stopPropagation();
        setDropdownOpen(!dropdownOpen);
    };

    const ref = useRef<HTMLDivElement>(null);

    const handleClick = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            onCancel();
        }
    };

    const commitFalseDetection = () => {
        if (onCommitFalseDetection) {
            const feedbackDto: ImageFeedbackDto = {
                imageMetadataDto: undefined,
                tag: selectedDropdownTags,
            };

            onCommitFalseDetection(feedbackDto);
        }

        onCancel();
    };

    const onCancel = () => {
        setDropdownOpen(false);
        setSelectedDropdownTags('');
    };

    useEffect(() => {
        setButtonShouldBeActive(selectedDropdownTags.length > 0);
    }, [selectedDropdownTags]);

    useEffect(() => {
        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    return (
        dropdownTags.length > 0 && (
            <div className={styles.sharefalsedetectiondropdown}>
                <OptoTooltip
                    content={t('Send a report about an error in the picture')}
                    nub={'up-right'}>
                    <IconButton
                        variant={'secondary'}
                        size={'small'}
                        onlyIcon={true}
                        onClick={toggleDropdown}
                        aria-label={'Open false detection dropdown'}
                        buttonText={t('Wrong detection')}>
                        <FeedBackIcon color="#fff" />
                    </IconButton>
                </OptoTooltip>
                <div
                    className={classnames(styles.dropdownMenu, dropdownOpen ? styles.open : '')}
                    ref={ref}>
                    <div className={classnames(styles.title)}>
                        {t('Submit a report for the picture')}
                    </div>
                    <div>
                        <SmallDropDown
                            items={dropdownTags}
                            value={selectedDropdownTags}
                            scrollable={false}
                            onChange={(value) => setSelectedDropdownTags(value)}
                            title={t('Select option')}
                            className={classnames(styles.reportingDropdownMenu)}
                        />

                        <div className={classnames(styles.buttonContainer)}>
                            <IconButton
                                buttonText={t('Send report')}
                                disabled={!buttonShouldBeActive}
                                variant="primary"
                                size="medium"
                                onClick={() => {
                                    commitFalseDetection();
                                }}
                            />

                            <IconButton
                                buttonText={t('Cancel')}
                                disabled={!buttonShouldBeActive}
                                variant="primary"
                                size="medium"
                                onClick={() => {
                                    onCancel();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default ShareFalseDetectionDropdown;
export { ShareFalseDetectionDropdown };
