import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
    MoveBioscopeIconSVG,
    SearchIconSVG,
    UserIconSVG,
} from '@/components/atoms/icons/GenericIcon/GenericIcons';
import { Pages, SidebarType } from '@/components/molecules/GenericSidebar';
import { SidebarPageOptionObject } from '@/components/molecules/GenericSidebar/SidebarPageOptionObjects';
import InternalBioscopeDetailContainer from '@/routes/Internal/routes/Bioscopes/InternalBioscopeDetails';
import InternalUserManagementContainer from '@/routes/Internal/routes/InternalUsers';

import { HeaderContainer } from '../../components/organisms/Header/HeaderContainer';

import Bioscopes from './routes/Bioscopes';
import Client from './routes/Client';
import Clients from './routes/Clients';

export const pages: Pages = [
    {
        path: '/bioscopes',
        label: 'Bioscopes',
        sidebarToShowFor: SidebarType.internalLevel,
        exact: true,
        image: MoveBioscopeIconSVG,
    },
    {
        exact: false,
        path: '/clients',
        label: 'Clients',
        sidebarToShowFor: SidebarType.internalLevel,
        image: SearchIconSVG,
    },
    {
        path: '/users',
        label: 'Users',
        sidebarToShowFor: SidebarType.internalLevel,
        exact: true,
        image: UserIconSVG,
    },
].map((page, index) => ({ key: index, ...page }));

export const internalSidebarOptions: SidebarPageOptionObject[] = pages.map((item) => {
    return {
        page: item,
        url: '',
        active: false,
    };
});

const Internal = (props) => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const changeLanguage = (language) => i18n.changeLanguage(language);
        if (i18n.language !== 'en') {
            changeLanguage('en');
        }
    }, [i18n, i18n.language]);

    return (
        <>
            <HeaderContainer
                baseUrl={props.match.path}
                languageBar={false}
                showHomeButton={false}></HeaderContainer>

            <Switch>
                <Route
                    exact
                    path={`${props.match.path}/users`}
                    component={InternalUserManagementContainer}
                />

                <Route
                    exact
                    path={'/internal/bioscopes/:bioscopeId'}
                    render={({ match }) => (
                        <>
                            <InternalBioscopeDetailContainer
                                bioscopeId={parseInt(match?.params?.bioscopeId)}
                            />
                        </>
                    )}
                />
                <Route exact path={`${props.match.path}/bioscopes`} component={Bioscopes} />
                <Route exact path={`${props.match.path}/clients`} component={Clients} />
                <Route exact path={`${props.match.path}/clients/:clientId`} component={Client} />

                <Redirect to={`${props.match.path}/bioscopes`} />
            </Switch>
        </>
    );
};

export default Internal;
export { Internal };
