import LiceIcon from '../../../../../public/assets/InternalIcons/LiceIcon.svg';
import ArrowWithLineDown from '../../../../../public/assets/MingCute-main/svg/arrow/align_arrow_down_fill.svg';
import ArrowWithLineLeft from '../../../../../public/assets/MingCute-main/svg/arrow/align_arrow_left_fill.svg';
import ArrowWithLineRight from '../../../../../public/assets/MingCute-main/svg/arrow/align_arrow_right_fill.svg';
import ArrowWithLineUp from '../../../../../public/assets/MingCute-main/svg/arrow/align_arrow_up_fill.svg';
import MoveBioscopeIcon from '../../../../../public/assets/MingCute-main/svg/arrow/transfer_4_fill.svg';
import CompareIcon from '../../../../../public/assets/MingCute-main/svg/arrow/transfer_fill.svg';
import HomeIcon from '../../../../../public/assets/MingCute-main/svg/building/home_3_fill.svg';
import DashboardIcon from '../../../../../public/assets/MingCute-main/svg/design/layout_grid_fill.svg';
import CopyIcon from '../../../../../public/assets/MingCute-main/svg/file/copy_fill.svg';
import DownloadFile from '../../../../../public/assets/MingCute-main/svg/file/download_2_line.svg';
import SearchIcon from '../../../../../public/assets/MingCute-main/svg/file/search_2_fill.svg';
import ResetZoom from '../../../../../public/assets/MingCute-main/svg/file/zoom_out_line.svg';
import ExitFullScreen from '../../../../../public/assets/MingCute-main/svg/media/fullscreen_exit_line.svg';
import FullScreen from '../../../../../public/assets/MingCute-main/svg/media/fullscreen_line.svg';
import ElipsisIcon from '../../../../../public/assets/MingCute-main/svg/system/more_2_fill.svg';
import HelpIcon from '../../../../../public/assets/MingCute-main/svg/system/question_fill.svg';
import UserIcon from '../../../../../public/assets/MingCute-main/svg/user/group_3_fill.svg';

export const ArrowWithLineDownIconSVG = ArrowWithLineDown;
export const ArrowWithLineUpIconSVG = ArrowWithLineUp;
export const ArrowWithLineLeftIconSVG = ArrowWithLineLeft;
export const ArrowWithLineRightIconSVG = ArrowWithLineRight;
export const HomeIconSVG = HomeIcon;
export const UserIconSVG = UserIcon;
export const HelpIconSVG = HelpIcon;
export const MoveBioscopeIconSVG = MoveBioscopeIcon;
export const LiceIconSVG = LiceIcon;
export const DashboardIconSVG = DashboardIcon;
export const CompareIconSVG = CompareIcon;
export const SearchIconSVG = SearchIcon;
export const ElipsisIconSVG = ElipsisIcon;
export const CopyIconSVG = CopyIcon;
export const ResetZoomIconSVG = ResetZoom;
export const DownloadFileIconSVG = DownloadFile;
export const FullScreenIconSVG = FullScreen;
export const FullScreenExitconSVG = ExitFullScreen;
