import { t } from 'i18next';
import { useClientController_AllLiceWeeklyReport } from 'services/hooks';

import { Page, PageContent } from '@/components/Layout';
import { GenericSidebar, SidebarType } from '@/components/molecules/GenericSidebar';
import regionSidebarOptions from '@/routes/Client/Overview';
import { Loader } from 'components/atoms/Loader';
import SomethingHappened from 'components/SomethingHappened';

import LiceReport from './LiceReport';
interface LiceReportProps {
    clientId: number;
    clientName: string;
}

const LiceReportContainer = ({ clientId, clientName }: LiceReportProps) => {
    const { isLoading, data, error } = useClientController_AllLiceWeeklyReport(clientId);
    const layerUrlPrefix = `/c/${clientId}`;
    return (
        <Page title={t('Lice report')}>
            <GenericSidebar
                sideBarOptions={regionSidebarOptions}
                sidebarType={SidebarType.regionLevel}
                layerUrlPrefix={layerUrlPrefix}
            />
            <PageContent>
                {(isLoading || !data?.data) && <Loader />}
                {error && <SomethingHappened />}

                {!isLoading && !error && data?.data && (
                    <LiceReport clientId={clientId} clientName={clientName} reports={data.data} />
                )}
            </PageContent>
        </Page>
    );
};

export default LiceReportContainer;
export type { LiceReportProps };
export { LiceReportContainer };
