import { Page, PageContent } from '@/components/Layout';
import { GenericSidebar, SidebarType } from '@/components/molecules/GenericSidebar';
import HelpPageContainer from '@/routes/Client/components/Help/HelpPageContainer';
import regionSidebarOptions from '@/routes/Client/Overview';

const RegionHelpContainer = ({ clientId }: { clientId: number }) => {
    const layerUrlPrefix = `/c/${clientId}`;

    return (
        <Page title="Help">
            <GenericSidebar
                sideBarOptions={regionSidebarOptions}
                sidebarType={SidebarType.regionLevel}
                layerUrlPrefix={layerUrlPrefix}
            />
            <PageContent>
                <HelpPageContainer />
            </PageContent>
        </Page>
    );
};

export default RegionHelpContainer;
export { RegionHelpContainer };
