import React from 'react';

import useDocumentTitle from '@/hooks/useDocumentTitle';

import styles from './pageTitle.module.scss';

export interface PageTitleProps {
    children?: React.ReactNode;
    title: string;
    showH1?: boolean; // This is temporarly added to show/hide h1 tag. We should display proper h1 tags on all pages.
}

const PageTitle = ({ children = undefined, title, showH1 = false }: PageTitleProps) => {
    return (
        <header className={styles.pageTitle} data-type="header">
            {children !== undefined && <div className={styles.pageTitleChildren}>{children}</div>}
            <h1 data-show-h1={showH1}>{title}</h1>
        </header>
    );
};

interface PageTitleForAnalyticsrops {
    title: string;
    category: string;
    routeProps?: any;
    params?: {};
}

const PageTitleForAnalytics = ({
    title,
    category,
    routeProps = {},
    params = {},
}: PageTitleForAnalyticsrops) => {
    let titleString = title;

    if (category === 'cage') {
        titleString = `Cage - ${title}`;

        /*
        if (title === 'Health') {
            const pageName = routeProps.location.pathname.split('/').pop();
            console.log(pageName);
            titleString = `Cage - Health - ${pageName}`;
        }
            */
    }

    if (category === 'location') {
        titleString = `Location - ${title}`;
    }

    useDocumentTitle(titleString);

    return <></>;
};

export default PageTitle;
export { PageTitle, PageTitleForAnalytics };
