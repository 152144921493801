import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { toast, ToastOptions, ToastPosition } from 'react-toastify';
import { mergeDeep } from 'immutable';

import { AdultFemale } from '@/components/atoms/icons/AdultFemale';
import { LiceIcon } from '@/components/atoms/icons/LiceIcon';
import { useMe } from '@/contexts/meContext';

/**
 * DOCS: https://fkhadra.github.io/react-toastify/introduction
 */

const LICENSES = {
    FISH_HEALTH_DATA: 'FISH_HEALTH.DATA',
    FISH_HEALTH_MIN: 'FISH_HEALTH.MIN',
    LICE_COUNT_MIN: 'LICE_COUNT.MIN',
    LICE_COUNT_DATA: 'LICE_COUNT.DATA',
    LICE_COUNT_MAX: 'LICE_COUNT.MAX',
    FISH_HEALTH_INSPECTION: 'FISH_HEALTH.INSPECTION',
    BIOMASS_FULL_: 'BIOMASS.FULL',
    FISH_SPEED: 'FISH_SPEED',
};

const ROLES = {
    OPTOSCALE_ADMIN: 'OptoScaleAdmin',
    CLIENT_ADMIN: 'ClientAdmin',
    LOCATION_ADMIN: 'LocationAdmin',
};

const ICONS = {
    lice: AdultFemale,
    health: AdultFemale,
    fishspeed: AdultFemale,
    optoscale: AdultFemale,
};

export interface ToastTemplateBackendFeedbackProps {
    httpStatusCode?: number;
    toastId?: string;
    content: string | React.ReactNode;

    position?:
        | 'top-left'
        | 'top-center'
        | 'top-right'
        | 'bottom-left'
        | 'bottom-center'
        | 'bottom-right';
}

const ToastTemplateBackendFeedback = ({
    httpStatusCode = 200,
    toastId = 'default',
    content,
    position = 'top-right',
}: ToastTemplateBackendFeedbackProps) => {
    const { isOptoscaleAdmin } = useMe();

    if (httpStatusCode === 200 || httpStatusCode === 201) {
        toast.warn(content, {
            toastId: toastId,
            containerId: 'optoscale-toast',
            position: position,
            autoClose: 8000,
            closeOnClick: true,
            hideProgressBar: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    } else {
        toast.error('Failed to submit feedback', {
            toastId: toastId,
            containerId: 'optoscale-toast',
            position: position,
            autoClose: 8000,
            closeOnClick: true,
            hideProgressBar: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    return <></>;
};

interface ToastInfoTemplateProps {
    content: string | React.ReactNode;
    position?:
        | 'top-left'
        | 'top-center'
        | 'top-right'
        | 'bottom-left'
        | 'bottom-center'
        | 'bottom-right';
    type?: 'info' | 'success' | 'warning' | 'error';
    module?: 'lice' | 'health' | 'fishspeed' | 'general';
    page?: string;
    license?: string;
    role?: string;
    options?: ToastOptions<unknown> | undefined;
}

const ToastInfoTemplate = ({
    content,
    type = 'info',
    license = 'all',
    role = 'all',
    page = 'all',
    module = undefined,
    options = {},
}: ToastInfoTemplateProps) => {
    const location = useLocation();

    const defaultOptions = {
        toastId: 'opto-info',
        containerId: 'optoscale-toast',
        position: 'top-right' as ToastPosition,
        autoClose: false as number | false,
        closeOnClick: true,
        hideProgressBar: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: type,
        icon: () => {
            return module === 'lice' ? <LiceIcon /> : <></>;
        },
    };

    if (module !== undefined) {
        // defaultOptions.icon = ICONS[module];
    }

    if (options !== undefined) {
        mergeDeep(defaultOptions, options as { [key: string]: unknown });
    }

    useEffect(() => {
        /*
        const pathParts = location.pathname.split('/');
        const match = pathParts.find((part: string) => part === page);

        if (match === undefined) {
            toast.dismiss({ id: defaultOptions.toastId, containerId: defaultOptions.containerId });
        }
        */

        return () => {
            toast.dismiss({ id: defaultOptions.toastId, containerId: defaultOptions.containerId });
        };
    }, [location, page, defaultOptions.toastId, defaultOptions.containerId]);

    toast(content, defaultOptions);

    return <></>;
};

export default ToastInfoTemplate;
export { ToastTemplateBackendFeedback, ToastInfoTemplate };
