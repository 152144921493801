import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Last3DaysResult } from 'services/types';
import { v4 as uuidv4 } from 'uuid';

import BlockTitle from '@/components/atoms/BlockTitle';
import { AdultFemale } from '@/components/atoms/icons/AdultFemale';
import { Caligus } from '@/components/atoms/icons/Caligus';
import LockIcon from '@/components/atoms/icons/LockIcon';
import { MobileLice } from '@/components/atoms/icons/MobileLice';
import OptoTooltip from '@/components/atoms/OptoTooltip';
import UmerBox from '@/components/atoms/UmerBox';
import caligusLocked from '@/svg/caligusLocked.jpg';
import mobileLocked from '@/svg/mobileLocked.jpg';

import styles from './LiceStatus.module.scss';

const LABELS = {
    female: 'Adult Female Lice',
    // fixedStage: 'Fixed stage',
    caligusElongatus: 'Caligus elongatus',
    movingStage: 'Mobile Lice',
    noLice: 'No Lice',
};

const LiceIndicatorBlock = ({ nub, label, value200, showMaximumLiceInformation }) => {
    const { t } = useTranslation();
    const LiceStageIndicators = ({ id, iconName, label, labelInEng, value200, helpText }) => {
        const heltTextAsHtml = [];
        for (const para of helpText.split('. ')) {
            const id = uuidv4();
            heltTextAsHtml.push(<p key={id}>{para}.</p>);
        }
        const TootipBlock = <div className={styles.tooltipBlock}>{heltTextAsHtml}</div>;

        return (
            <div className={styles.liceStatus}>
                <OptoTooltip nub={nub} content={TootipBlock} customClass={styles.customIconTooltip}>
                    {iconName === 'adultFemale' ? (
                        <AdultFemale height={105} />
                    ) : iconName === 'mobile' ? (
                        <MobileLice height={105} />
                    ) : (
                        <Caligus height={105} />
                    )}
                </OptoTooltip>
                <div data-type="details">
                    <div data-type="label">{label}</div>
                    {labelInEng === 'Mobile Lice' || labelInEng === 'Caligus elongatus' ? (
                        <div className={styles.countValue}>
                            <div data-type="liceNumber">
                                {value200 === '—' ? (
                                    <OptoTooltip
                                        customClass={styles.customTooltip}
                                        nub="up-center"
                                        content={t('Not enough measurements')}>
                                        — <i className="fa fa-question fa-xs" />
                                    </OptoTooltip>
                                ) : labelInEng === 'Mobile Lice' && Number(value200) > 10 ? (
                                    <OptoTooltip
                                        customClass={styles.customTooltip}
                                        nub="down-center"
                                        content={t('moreThan10Mobile')}>
                                        {'>10'}
                                    </OptoTooltip>
                                ) : labelInEng === 'Caligus elongatus' && Number(value200) > 10 ? (
                                    <OptoTooltip
                                        customClass={styles.customTooltip}
                                        nub="down-center"
                                        content={t('moreThan10Caligus')}>
                                        {'>10'}
                                    </OptoTooltip>
                                ) : (
                                    value200
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className={styles.countValue}>
                            <div data-type="liceNumber">
                                {value200 === '—' ? (
                                    <OptoTooltip
                                        customClass={styles.customTooltip}
                                        nub="up-center"
                                        content={t('Not enough measurements')}>
                                        — <i className="fa fa-question fa-xs" />
                                    </OptoTooltip>
                                ) : Number(value200) > 10 ? (
                                    <OptoTooltip
                                        customClass={styles.customTooltip}
                                        nub="down-center"
                                        content={t('moreThan10AdultFemale')}>
                                        {'>10'}
                                    </OptoTooltip>
                                ) : (
                                    value200
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const LiceDataMinimal = ({ display, toolTipText }) => {
        return (
            <div className={styles.lockedStatus}>
                <OptoTooltip nub="up-center" content={toolTipText}>
                    <LockIcon width={50} color="#a7a4a4" />
                </OptoTooltip>
            </div>
        );
    };
    const renderElement = () => {
        if (LABELS[label] === 'Adult Female Lice') {
            return (
                <LiceStageIndicators
                    id={Object.keys(LABELS)[0]}
                    iconName="adultFemale"
                    label={t(LABELS[label])}
                    labelInEng={LABELS[label]}
                    value200={value200}
                    helpText={t('liceNumberHelpText.adult')}
                />
            );
        }
        if (LABELS[label] === 'Mobile Lice') {
            if (showMaximumLiceInformation) {
                return (
                    <LiceStageIndicators
                        id={Object.keys(LABELS)[1]}
                        iconName="mobile"
                        label={t(LABELS[label])}
                        labelInEng={LABELS[label]}
                        value200={value200}
                        helpText={t('liceNumberHelpText.mobile')}
                    />
                );
            }
            return (
                <LiceDataMinimal
                    display={mobileLocked}
                    toolTipText={'Get full license to view Mobile'}
                />
            );
        }
        if (LABELS[label] === 'Caligus elongatus') {
            if (showMaximumLiceInformation) {
                return (
                    <LiceStageIndicators
                        id={Object.keys(LABELS)[2]}
                        iconName="caligus"
                        label={t(LABELS[label])}
                        labelInEng={LABELS[label]}
                        value200={value200}
                        helpText={t('liceNumberHelpText.caligus')}
                    />
                );
            }
            return (
                <LiceDataMinimal
                    display={caligusLocked}
                    toolTipText={'Get full license to view Caligus'}
                />
            );
        }
        return null;
    };

    return renderElement();
};

function Boxes({ data, showMaximumLiceInformation }) {
    const calculateNub = (index: number) => {
        if (index === 0) return 'left-center';
        if (index === 1) return 'up-center';
        return 'right-center';
    };

    return data.map((col, index) => {
        const id = uuidv4();
        return (
            <React.Fragment key={id}>
                <div>
                    <LiceIndicatorBlock
                        nub={calculateNub(index)}
                        label={col.label}
                        value200={col.value200}
                        showMaximumLiceInformation={showMaximumLiceInformation}
                    />
                </div>
                {index !== 2 && (
                    <div data-type="divider">
                        <div />
                    </div>
                )}
            </React.Fragment>
        );
    });
}

const getLiceColor = (mpf) => {
    // if no mean per fish lice information, return background color
    if (mpf === undefined || mpf === null || Number.isNaN(mpf)) return 'white';

    if (mpf >= 0.5) {
        return 'red';
    }
    if (mpf >= 0.2) {
        return 'yellow';
    }

    return 'green';
};

interface LiceStatusProps {
    liceInMin200Fish: Last3DaysResult & {
        count?: number;
        countNoLice?: number;
        dayPart?: string;
        daypartId?: number;
        total?: number;
    };
    showMaximumLiceInformation: boolean;
}
const LiceStatus = ({ liceInMin200Fish, showMaximumLiceInformation }: LiceStatusProps) => {
    const { t } = useTranslation();

    const data = ['female', 'movingStage', 'caligusElongatus'].map((cur) => {
        const value = liceInMin200Fish?.[`${cur}Avg`];
        const hasValue = !(value === null || value === undefined);
        return {
            label: cur,
            value200: !hasValue ? '—' : Number(value).toFixed(2),
            hasValue,
            color: getLiceColor(value),
        };
    });

    const title = `${t('Lice mean count per fish')}`;

    return (
        <UmerBox>
            <BlockTitle heading={title} />
            <div className={styles.liceIndicatorPanel}>
                <Boxes data={data} showMaximumLiceInformation={showMaximumLiceInformation} />
            </div>
            <footer className={styles.footer}>
                {liceInMin200Fish?.day
                    ? `${t('Last measurement day')}: ${moment(liceInMin200Fish?.day).format(
                          'YYYY-MM-DD'
                      )} `
                    : null}
            </footer>
        </UmerBox>
    );
};

export default LiceStatus;
export { LiceStatus };
