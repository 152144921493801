import React from 'react';

import styles from './DownloadIcon.module.scss';

export interface DownloadIconProps {
    color?: string;
    size?: number;
}

const DownloadIcon = ({ color, size = 32 }: DownloadIconProps) => {
    const colorStyle = color ? color : 'currentColor';

    return (
        <div className={styles.downloadIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 32 32">
                <g fill="none">
                    <path d="M24 0v24H0V0zM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01z" />
                    <path
                        fill={colorStyle}
                        d="M20 15a1 1 0 0 1 1 1v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4a1 1 0 1 1 2 0v4h14v-4a1 1 0 0 1 1-1M12 2a1 1 0 0 1 1 1v10.243l2.536-2.536a1 1 0 1 1 1.414 1.414l-4.066 4.066a1.25 1.25 0 0 1-1.768 0L7.05 12.121a1 1 0 1 1 1.414-1.414L11 13.243V3a1 1 0 0 1 1-1"
                    />
                </g>
            </svg>
        </div>
    );
};

export default DownloadIcon;
export { DownloadIcon };
