import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as features from 'config/features';
import { useMe } from 'contexts/meContext';
import { MeasurmentOverviewResult } from 'services/types';

import Options, {
    autoTimeScale,
    chartTitle,
    formatAxsis,
    TypeNames,
    weightAndLengthUnitConverter,
} from '@/components/organisms/OptoGraphs/optionsModifiers';
import { defaultLineChartOptions } from '@/components/organisms/OptoGraphs/OptoMixedChart';
import { OverViewToLivingWeightChartMapper } from '@/components/organisms/OptoGraphs/OptoMixedChart/OptoMixedChartMappers';
import { Box } from 'components/Layout';
import { OptoMixedChart } from 'components/organisms/OptoGraphs';
import { dayTagValues } from 'components/organisms/OptoGraphs/commonOptoChartHelpers';

import useGraphData from '../graph-data-context';

function WeightBox() {
    const { t, i18n } = useTranslation();
    const { hasUserFeature } = useMe();

    function toLocale(language: string) {
        return language === 'nb' ? 'nb-NO' : language === 'es' ? 'es-ES' : 'en-US';
    }

    const [locale, setLocale] = useState('nb-NO');

    useEffect(() => {
        setLocale(toLocale(i18n.language));
    }, [i18n.language]);

    const wantNewWeightGraph = hasUserFeature(
        features.NEW_CAGE_OVERVIEW_WEIGHT_GRAPH,
        // admins should not see new overview by default
        { noSuper: true }
    );

    const { data, weightUnitTag, daypartTag } = useGraphData();
    const disabeledDayTags = dayTagValues.filter((dayTag) => dayTag !== daypartTag);

    return (
        <Box data-tut="weightGraph" customStyleOuter={{ padding: 0 }}>
            <OptoMixedChart
                chartName={t('Living weight')}
                chartData={OverViewToLivingWeightChartMapper({
                    overview: data as MeasurmentOverviewResult,
                    dayTags: [daypartTag],
                    inactiveSetLegend: [...disabeledDayTags],
                })}
                actionButtons={{
                    copyAsPng: true,
                    resetZoom: true,
                    downloadAsPng: true,
                    fullScreen: true,
                    timeScaleSwitch: false,
                }}
                chartOptions={Options(defaultLineChartOptions).modify(
                    autoTimeScale({}),
                    chartTitle({ title: t('Living weight') }),
                    weightAndLengthUnitConverter({
                        locale: locale,
                        type: TypeNames.weight,
                        fromUnit: weightUnitTag,
                        toUnit: weightUnitTag,
                    }),
                    formatAxsis({
                        axsisID: 'y',
                        tickType: TypeNames.weight,
                        descimals: 0,
                    })
                )}
                loading={false}
            />
        </Box>
    );
}

export default WeightBox;
