import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Collapse, Nav, Navbar, NavbarToggler } from 'reactstrap';
import cn from 'classnames';

import type { ClientInfo, MeUser } from '@/services/types';
import { ChangeLanguage } from 'components/molecules/ChangeLanguage';
import { ClientNav } from 'components/molecules/ClientNav/ClientNav';

import styles from './header.module.scss';

export interface ExtendedClientInfo extends ClientInfo {
    role?: string;
    authLicenses?: string[];
}

export interface HeaderProps {
    children?: React.ReactNode;
    languageBar?: boolean;
    location?: {
        pathname: string;
    };
    expanded?: boolean;
    setExpandedHandler?: (expanded: boolean) => void;
    menuItemHandler?: (to: string) => void;
    user: MeUser;
    client: ExtendedClientInfo;
    clients: {
        id: number;
        name: string;
        role: string;
        authLicenses: string[];
    }[];
    onLogout: () => Promise<unknown>;
    navBarBrandLogoLink?: string;
}

export const Header = ({
    children,
    languageBar = true,
    expanded,
    setExpandedHandler,
    user,
    client,
    clients,
    onLogout,
    navBarBrandLogoLink,
}: HeaderProps) => {
    const clientName = client?.name ?? '';
    const match = useRouteMatch();
    return (
        <>
            <Navbar expand="lg" className={styles.header} dark>
                <NavbarToggler
                    className={styles.navbarToggler}
                    onClick={() => setExpandedHandler(!expanded)}
                    color="dark"
                />
                <Collapse
                    isOpen={expanded}
                    navbar
                    className={expanded ? styles.headerCollapsed : ''}>
                    {children}
                    <Nav
                        className={cn(styles.languageBarAndClientNav, !expanded ? 'ml-auto' : '')}
                        navbar>
                        {!!languageBar && <ChangeLanguage />}
                        <ClientNav
                            match={match}
                            onLogout={onLogout}
                            user={user}
                            clients={clients}
                            clientName={clientName}
                        />
                    </Nav>
                </Collapse>
            </Navbar>
        </>
    );
};
