import React from 'react';
import { useTranslation } from 'react-i18next';

import { Chart } from '@/components/organisms/DeprecatedGraphs/Chart';

import getOptions from './options';
import plugins from './plugins';
export default ({
    height = undefined,
    data,
    header,
    sidebar = undefined,
    showYLabel = undefined,
    maintainAspectRatio = undefined,
}) => {
    const { t } = useTranslation();

    const allDates = data.map((datum) => datum.x);
    const lastCount = allDates[allDates.length - 1];
    const todayDate = new Date().toISOString().slice(0, 10);
    const isLastCountIsToday = lastCount === todayDate;
    const backgroundColor = data.map((item, index) => {
        return item.isUncertainLiceCount || (index === data.length - 1 && isLastCountIsToday)
            ? 'rgba(91,183,132,0.4)'
            : '#5bb784';
    });
    const datasets = [
        {
            label: t('Adult Female Lice'),
            backgroundColor,
            borderColor: '#5bb784',
            fill: false,
            hidden: false,
            showLine: true,
            data: data,
        },
    ];

    return (
        <Chart
            style={
                height && {
                    height,
                }
            }
            type="bar"
            data={{
                datasets: datasets,
            }}
            options={getOptions(showYLabel, maintainAspectRatio)}
            plugins={plugins}
            header={header}
            sidebar={sidebar}
        />
    );
};
