import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphSettings } from 'config/settings';

import GrowthIcon from '@/components/atoms/icons/GrowthIcon';
import LaksvelIcon from '@/components/atoms/icons/LaksvelIcon';
import LiceIcon from '@/components/atoms/icons/LiceIcon';
import { GenericInfoBox } from '@/components/molecules/GenericInfoBox';
import { CircleColorType } from '@/components/molecules/GenericInfoBox/GenericInfoBox';
import { OverviewLocation } from '@/services/types';
import { FishHealthIcon } from 'components/atoms/icons/FishHealthIcon';

import styles from './AverageHeader.module.scss';

export interface AverageHeaderProps {
    children?: React.ReactNode;
    location: OverviewLocation;
}

interface FishTypeColors<T> {
    [key: string]: T;
}

const GrowthValue = (growth: number | undefined) => {
    const { t } = useTranslation();
    return growth ? `${Math.floor(growth)} g/${t('daypartTag.day')}` : '---';
};

const WoundsValue = (wound: number | undefined) => {
    if (wound) {
        return `${(100 * Math.round(wound * 200)) / 200}%`;
    }
    return '---';
};

const LiceValue = (lice: number | undefined) => {
    if (lice) {
        return `${lice?.toFixed(2)}`;
    }
    return '---';
};

const LaksvelValue = (avgLaksvel: number | undefined) => {
    if (avgLaksvel) {
        return `${avgLaksvel?.toFixed(1)}%`;
    }
    return '---';
};

const AverageHeader = ({ location }: AverageHeaderProps) => {
    const { t } = useTranslation();
    const hasAccessToFishHealth = location.cages.some((cage) => cage.welfare);
    const hasAccessToLice = location.cages.some((cage) => cage.lice);
    const hasAccessToLaksvel = location.cages.some((cage) => cage.laksvel);
    const { fishTypes } = location;
    const color = (graphSettings.fishTypeName as FishTypeColors<string>)[
        fishTypes.name.toLowerCase()
    ];

    return (
        <div className={`${styles.averageheader}`} style={{ color: color }}>
            <GenericInfoBox
                info={GrowthValue(location.averages.growth)}
                title={t('Growth')}
                icon={GrowthIcon}
                circleColor={CircleColorType.purple}
                grayBackground={true}
                tooltipText={'Growth test tooltip'}
                shouldHaveTooltip={false}
            />

            {hasAccessToLice ? (
                <GenericInfoBox
                    info={LiceValue(location.averages.femaleLice)}
                    title={t('Lice')}
                    icon={LiceIcon}
                    circleColor={CircleColorType.orange}
                    grayBackground={true}
                    tooltipText={'Lice test tooltip'}
                    shouldHaveTooltip={false}
                />
            ) : (
                ''
            )}

            {hasAccessToLaksvel ? (
                <GenericInfoBox
                    info={LaksvelValue(location.averages?.laksvel)}
                    title={t('Laksvel')}
                    icon={LaksvelIcon}
                    circleColor={CircleColorType.blue}
                    grayBackground={true}
                    tooltipText={'Laksvel test tooltip'}
                    shouldHaveTooltip={false}
                />
            ) : (
                ''
            )}

            {hasAccessToFishHealth && location.fishTypes.shortName !== 'trout' ? (
                <GenericInfoBox
                    info={WoundsValue(location?.averages?.wounds)}
                    title={t('Wound')}
                    icon={FishHealthIcon}
                    circleColor={CircleColorType.green}
                    grayBackground={true}
                    tooltipText={'Wound test tooltip'}
                    shouldHaveTooltip={false}
                />
            ) : (
                ''
            )}
        </div>
    );
};

export default AverageHeader;
export { AverageHeader };
