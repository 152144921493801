import React from 'react';

import styles from './Notice.module.scss'; // Import SCSS module

interface NoticePropsWithClose {
    heading: string;
    message: string | string[];
    showCloseButton?: boolean;
    onClose?: () => void;
    type?: 'status' | 'notice';
    icon?: boolean;
    variant: 'default' | 'warning' | 'warningNoIcon' | 'allgood'; // default is green/black, warning is orange
    topic?: string;
}

interface NoticePropsWithoutCloose {
    heading: string;
    message: string | string[];
    showCloseButton?: false;
    onClose?: never;
    type?: 'status' | 'notice';
    icon?: boolean;
    variant: 'default' | 'warning' | 'warningNoIcon' | 'allgood'; // default is green/black, warning is orange
    topic?: string;
}

type NoticeProps = NoticePropsWithClose | NoticePropsWithoutCloose;

const NoticeVariantOptions: Record<NoticeProps['variant'], { style: string; icon: string | null }> =
    {
        default: { style: styles.default, icon: 'fa-bell' },
        warning: { style: styles.warning, icon: 'fa-exclamation-triangle' },
        warningNoIcon: { style: styles.warning, icon: null },
        allgood: { style: styles.allgood, icon: 'fa-check-circle' },
    };

const Notice = ({
    heading,
    message,
    showCloseButton,
    onClose,
    variant,
    type = 'notice',
    icon = true,
    topic = 'default',
}: NoticeProps) => {
    const option = NoticeVariantOptions[variant];
    const formattedMessages = Array.isArray(message)
        ? message
              .filter((text) => {
                  return text !== '' || text.length > 1;
              })
              .map((text) => {
                  return `<span>${text}</span>`;
              })
              .join('')
        : message;
    return (
        <div
            className={`alert alert-warning ${styles.notice}  ${option.style}`}
            data-type={type}
            data-hasicon={icon}>
            {showCloseButton && (
                <button className={`${styles.closeButton}`} onClick={onClose} type="button">
                    <i className="fa fa-times-circle" />
                </button>
            )}
            <div className={styles.contentWrapper}>
                <div className={styles.headerContainer}>
                    {icon && option.icon != null && (
                        <i className={`fa ${option.icon} ${styles.icon}`} />
                    )}
                </div>
                <div className={styles.messageContainer}>
                    <h2 className={`alert-heading ${styles.heading}`}>{heading}</h2>
                    {/* biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation> */}
                    <div dangerouslySetInnerHTML={{ __html: formattedMessages }} />
                </div>
            </div>
        </div>
    );
};

export default Notice;
export { Notice };
