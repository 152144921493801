import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import Panzoom, { type PanzoomObject } from '@panzoom/panzoom';
import { localizedDateFormat } from 'helpers/date';

import PoiInfoLayer from 'components/atoms/PoiInfoLayer';

import Magnifyer from './Magnifyer';

import styles from './ImageViewer.module.scss';

export interface ImageViewerProps {
    isColor?: boolean;
    id: string;
    path: string;
    text?: string;
    lazyLoad?: boolean;
    alt?: string;
    timeStamp: string;
    dimensions: {
        width: number;
        height: number;
    };
    bboxes?: {
        tag: string;
        bbox: number[];
        size_x_mm?: number;
        size_y_mm?: number;
        score: number;
    }[];
    poiPositionHandler?: (poiData, bounbdingRects) => void;
    showPoiMeta?: boolean;
    useMagnifyer?: boolean;
    instanceKey?: string;
    isOptoScaleAdmin?: boolean;
}

/**
 * ImageViewer component
 * @param {ImageViewerProps} props
 * @returns {JSX.Element}
 *
 * @description lazy load and display one single image
 */

const ImageViewerRenderFunction = (
    {
        isColor = true,
        id,
        path,
        text = 'Image recorded',
        lazyLoad = true,
        alt = 'bioscope image fish',
        timeStamp,
        dimensions,
        bboxes,
        poiPositionHandler = () => {},
        showPoiMeta = false,
        useMagnifyer = false,
        instanceKey = 'default',
        isOptoScaleAdmin = false,
    }: ImageViewerProps,
    ref
) => {
    if (!path || !dimensions || !dimensions.width || !dimensions.height) {
        return null;
    }

    const [panZoomReferense, setPanZoomReferense] = useState(null);
    const imageRef = useRef<HTMLImageElement>(null);
    const imageViewerRef = useRef<HTMLDivElement>(null);
    const [_, setUpdate] = useState<number>(0);
    const forceUpdate = () => setUpdate((val) => val + 1);

    const loadlazy = lazyLoad ? 'lazy' : 'eager';
    const placeHolderImage = '/assets/glyphicons/glyphicons-basic-790-fish.svg';

    useImperativeHandle(
        ref,
        () => {
            return {
                handleZoom: (e) => {
                    e.preventDefault();
                    if (panZoomReferense) {
                        if (!e.shiftKey) return;
                        panZoomReferense.zoomWithWheel(e);
                    }
                    return;
                },
                resetZoom: () => {
                    if (panZoomReferense) {
                        panZoomReferense.reset();
                    }
                    document.dispatchEvent(
                        new CustomEvent('fishscale', {
                            bubbles: true,
                            cancelable: true,
                            composed: true,
                            detail: {
                                scale: 1,
                            },
                        })
                    );
                    return;
                },
                handleZoomToPoi: (e) => {
                    e.preventDefault();
                    if (!panZoomReferense) {
                        return;
                    }
                    panZoomReferense.zoomToPoint(3, { clientX: e.clientX, clientY: e.clientY });

                    document.dispatchEvent(
                        new CustomEvent('fishscale', {
                            bubbles: true,
                            cancelable: true,
                            composed: true,
                            detail: {
                                scale: 3,
                            },
                        })
                    );
                    return;
                },
                getCurrentZoom: () => {
                    if (panZoomReferense) {
                        return {
                            scale: panZoomReferense.getScale(),
                            pan: panZoomReferense.getPan(),
                        };
                    }
                    return;
                },
            };
        },
        [panZoomReferense]
    );

    const captionText = timeStamp
        ? `${text} ${localizedDateFormat({ dateString: timeStamp })}`
        : `${text} N/A`;

    /* Executes when the figure element is ready in the DOM */
    const IMAGE_SIZE_MULTIPLIER = 1;
    const figureRefHandler = useCallback(
        (node: HTMLImageElement) => {
            if (node !== null) {
                const aspect = dimensions.width / dimensions.height;

                node.style.setProperty('--image-aspect-ratio', `${aspect}/1`);
                node.style.setProperty('--aspect-factor', `${aspect}`);

                node.style.setProperty(
                    '--salmon-height',
                    `${dimensions.height * IMAGE_SIZE_MULTIPLIER}px`
                );
                node.style.setProperty(
                    '--salmon-width',
                    `${dimensions.width * IMAGE_SIZE_MULTIPLIER}px`
                );

                const panzoom: PanzoomObject = Panzoom(node, {
                    maxScale: 5,
                    minScale: 1,
                    startScale: 1,
                    startX: 0,
                    startY: 0,
                    animate: true,
                    easing: 'ease-in-out',
                    step: 0.2,
                    silent: false,
                    panOnlyWhenZoomed: true,
                    contain: 'outside',
                });

                setPanZoomReferense(panzoom);

                const panZoomChangeHandler = (e) => {
                    if (e.detail.scale < 1.1) {
                        setTimeout(() => {
                            panzoom.pan(0, 0);
                        });
                    }
                };

                // Center image when zoomed out
                node.addEventListener('panzoomchange', panZoomChangeHandler);
            }
        },
        [dimensions.height, dimensions.width, path]
    );

    return (
        <div className={styles.imageViewer} ref={imageViewerRef}>
            <figure ref={figureRefHandler}>
                <Magnifyer
                    useMagnifyer={useMagnifyer}
                    path={path}
                    coordinate={{
                        x: 200,
                        y: 200,
                    }}
                    dimensions={dimensions}
                />

                <PoiInfoLayer
                    isColor={isColor}
                    showPoiMeta={showPoiMeta}
                    dimensions={dimensions}
                    bboxes={bboxes}
                    poiPositionHandler={poiPositionHandler}
                    show={imageRef.current?.complete}
                    instanceKey={instanceKey}
                    isOptoScaleAdmin={isOptoScaleAdmin}
                />

                <img
                    data-path={path}
                    src={path}
                    data-placeholder={placeHolderImage}
                    alt={alt}
                    loading={loadlazy}
                    onLoad={() => {
                        forceUpdate();
                    }}
                    ref={imageRef}
                />
                <figcaption>{captionText}</figcaption>
            </figure>
        </div>
    );
};

const ImageViewer = forwardRef(ImageViewerRenderFunction);

export default ImageViewer;
export { ImageViewer };
