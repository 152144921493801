import React from 'react';
import { Route, Switch } from 'react-router-dom';

import BioscopeList2 from './BioscopeList2';
import InternalBioscopeDetailContainer from './InternalBioscopeDetails';
const Index = (props) => {
    return (
        <Switch>
            <Route exact path={props.match.path} component={BioscopeList2} />
            <Route
                exact
                path={`${props.match.path}/:bioscopeId`}
                component={InternalBioscopeDetailContainer}
            />
        </Switch>
    );
};

Index.propTypes = {};

export default Index;
