import React from 'react';

import styles from './HamburgerIcon.module.scss';

export interface HamburgerIconProps {
    children?: React.ReactNode;
    height?: number;
    color?: string;
}

const HamburgerIcon = ({ height = 40, color = '#60bc84' }: HamburgerIconProps) => {
    return (
        <div className={styles.hamburgericon}>
            <svg
                id="hamburger_icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                width={height}
                height={height}>
                <path
                    fill={color}
                    id="menu"
                    d="M17,4V5a1,1,0,0,1-1,1H4A1,1,0,0,1,3,5V4A1,1,0,0,1,4,3H16A1,1,0,0,1,17,4ZM16,8H4A1,1,0,0,0,3,9v1a1,1,0,0,0,1,1H16a1,1,0,0,0,1-1V9A1,1,0,0,0,16,8Zm0,5H4a1,1,0,0,0-1,1v1a1,1,0,0,0,1,1H16a1,1,0,0,0,1-1V14A1,1,0,0,0,16,13Z"
                />
            </svg>
        </div>
    );
};

export default HamburgerIcon;
export { HamburgerIcon };
