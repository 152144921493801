import React from 'react';

import { GenericSidebar, SidebarType } from '@/components/molecules/GenericSidebar';
import { BioscopeDetailContainer } from '@/containers/BioscopeDetailContainer';
import MeContext from '@/contexts/meContext';
import { internalSidebarOptions } from '@/routes/Internal';
import { Page, PageContent } from 'components/Layout';

interface BioscopeDetailProps {
    bioscopeId: number;
}

const InternalBioscopeDetailContainer = ({ bioscopeId }: BioscopeDetailProps) => {
    const { isOptoscaleAdmin } = React.useContext(MeContext);

    return (
        <Page title="Bioscope">
            <GenericSidebar
                sideBarOptions={internalSidebarOptions}
                sidebarType={SidebarType.internalLevel}
                layerUrlPrefix={'/internal'}
            />
            <PageContent>
                <BioscopeDetailContainer bioscopeId={bioscopeId} />
            </PageContent>
        </Page>
    );
};

export default InternalBioscopeDetailContainer;
export { InternalBioscopeDetailContainer };
