/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { RiCheckboxBlankCircleLine, RiCheckboxCircleLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import {
    Button,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
} from 'reactstrap';
import { useQueryClient } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import {
    useAcknowledgedAlertsController_acknowledgeAlert,
    useAcknowledgedAlertsController_getAcknowledgements,
    useAcknowledgedAlertsController_unacknowledgeAlert,
    useAlertsController_allAlerts,
    useBioscopeController_ActiveBioscopes,
    useBioscopeController_CreateBioscope,
    useClientController_GetClients,
} from 'services/hooks';
import { AcknowledgedAlertsController_getAcknowledgements } from 'services/services';
import type {
    ActiveBioscope,
    Alert as AlertEntry,
    AlertAcknowledgement,
    BioscopeConfiguration,
} from 'services/types';

import { OptoTooltip } from '@/components/atoms/OptoTooltip';
import { GenericSidebar, SidebarType } from '@/components/molecules/GenericSidebar';
import { OptoTable } from '@/components/organisms/Table/Table';
import { graphSettings as ColorSettings } from '@/config/settings';
import { internalSidebarOptions } from '@/routes/Internal';
import { SearchIcon } from 'components/atoms/icons';
import { Loader } from 'components/atoms/Loader';
import { SimpleToolTip } from 'components/atoms/SimpleToolTip';
import { Box, Page, PageContent, PageTitle } from 'components/Layout';

import {
    bioscopeAlertCodes,
    isUserActionRequired,
} from '../../Dashboard/components/Alert/useAlert';

import AddBioscope from './AddBioscope';

import styles from './BioscopeListContainer.module.scss';

function parseBioscopeVersion(v) {
    if (!v) {
        return null;
    }

    return v.replace(/.*: v?/g, '');
}

// Compare a version section fragment, e.g. 1.2.3-beta is split into the
// fragments 1.2.3 and beta
function cmpFragment(lhs: string, rhs: string) {
    const isDigit = (char) => /^\d$/.test(char);
    const isAlpha = (char) => /^[A-Z]$/i.test(char);
    // Rank a single character with a value for ordering
    const order = (character) => {
        if (isDigit(character)) return 0;
        if (isAlpha(character)) return character.charCodeAt(0);
        if (character === '~') return -1;
        if (character !== '\0') return character.charCodeAt(0) + 256;
        return 0;
    };
    let lhs_index = 0;
    let rhs_index = 0;
    while (lhs_index !== lhs.length && rhs_index !== rhs.length) {
        let first_diff = 0;
        // First compare leading letters, if any
        while (
            lhs_index !== lhs.length &&
            rhs_index !== rhs.length &&
            (!isDigit(lhs[lhs_index]) || !isDigit(rhs[rhs_index]))
        ) {
            const lhs_value = order(lhs[lhs_index]);
            const rhs_value = order(rhs[rhs_index]);
            if (lhs_value !== rhs_value) return lhs_value - rhs_value;
            lhs_index++;
            rhs_index++;
        }
        // Ignore leading 0s
        while (lhs_index < lhs.length && lhs[lhs_index] === '0') lhs_index++;
        while (rhs_index < rhs.length && rhs[rhs_index] === '0') rhs_index++;
        if (rhs_index >= rhs.length || lhs_index >= lhs.length) break;
        // Iterate through numbers
        while (
            isDigit(lhs[lhs_index]) &&
            isDigit(rhs[rhs_index]) &&
            lhs_index < lhs.length &&
            rhs_index < rhs.length
        ) {
            if (first_diff === 0) {
                first_diff = lhs.charCodeAt(lhs_index) - rhs.charCodeAt(rhs_index);
            }
            lhs_index++;
            rhs_index++;
        }
        // If we still have digits to consume here, that means lhs is a longer
        // number than rhs, and therefore bigger
        if (lhs_index < lhs.length && isDigit(lhs[lhs_index])) return 1;
        if (rhs_index < rhs.length && isDigit(rhs[rhs_index])) return -1;
        // Both numbers are equally long, return first diff if any
        if (first_diff !== 0) return first_diff;
    }
    // We've consumed at least one of the fragments, both leading letters and
    // the number itself

    // Both versions at the end with no difference found, must be equal
    if (lhs_index >= lhs.length && rhs_index >= rhs.length) return 0;

    // Lhs is shorter
    if (lhs_index >= lhs.length) {
        // Versions are the same, but rhs ends with ~, so is considered smaller
        if (rhs[rhs_index] === '~') return 1;
        return -1;
    }
    if (rhs_index >= rhs.length) {
        if (lhs[lhs_index] === '~') return -1;
        return 1;
    }
    // This should never happen
    return Number.NaN;
}

// Compare versions the same way Debian does for packages
function cmpVersion(lhs: string, rhs: string) {
    const NOT_FOUND = -1;
    const lhs_epoch_index = lhs.indexOf(':');
    const rhs_epoch_index = rhs.indexOf(':');
    // Found at least one epoch
    if (lhs_epoch_index !== NOT_FOUND || rhs_epoch_index !== NOT_FOUND) {
        // Leading 0s are ignored, and a 0 epoch is treated as no epoch
        const lhs_epoch = lhs.slice(lhs_epoch_index).replace(/^0*/g, '');
        const rhs_epoch = rhs.slice(rhs_epoch_index).replace(/^0*/g, '');
        const res = cmpFragment(lhs_epoch, rhs_epoch);
        if (res !== 0) return res;
    }
    // Same epoch, strip it
    lhs = lhs_epoch_index !== NOT_FOUND ? lhs.substr(0, lhs_epoch_index) : lhs;
    rhs = rhs_epoch_index !== NOT_FOUND ? rhs.substr(0, rhs_epoch_index) : rhs;

    // Find the last -, we want to compare these fragments separately
    const lhs_revision_index = lhs.lastIndexOf('-');
    const rhs_revision_index = rhs.lastIndexOf('-');
    const lhs_main = lhs_revision_index !== NOT_FOUND ? lhs.substring(0, lhs_revision_index) : lhs;
    const rhs_main = rhs_revision_index !== NOT_FOUND ? rhs.substring(0, rhs_revision_index) : rhs;
    let res = cmpFragment(lhs_main, rhs_main);
    if (res !== 0) return res;

    // Main version is the same, so compare the revision
    // No revision is treated like revision 0
    const lhs_revision = lhs_revision_index !== NOT_FOUND ? lhs.substring(lhs_revision_index) : '0';
    const rhs_revision = rhs_revision_index !== NOT_FOUND ? rhs.substring(rhs_revision_index) : '0';
    res = cmpFragment(lhs_revision, rhs_revision);
    return res;
}

interface BioscopeTableProps {
    bioscopes: ActiveBioscope[];
    alerts: AlertEntry[];
    acknowledgements: AlertAcknowledgement[];
    filter: string;
}

function BioscopeTable({ bioscopes, alerts, acknowledgements, filter }: BioscopeTableProps) {
    const { t } = useTranslation();

    const alertMap = Object.fromEntries(
        bioscopeAlertCodes.map((alertCode) => [alertCode, t(`alert.${alertCode}`) as string])
    );

    const isAcknowledged = (alert, locationId: number) => {
        return acknowledgements.some(
            (ack) =>
                ack.alertCode === alert.alertCode &&
                ack.locationId === locationId &&
                ack.acknowledged &&
                (ack.alertId === null || ack.alertId === alert.id)
        );
    };

    const data = bioscopes.map((bioscope) => {
        return {
            ...(bioscope as ActiveBioscope),
            alerts: alerts
                .filter((alert) => alert.bioscopeId === bioscope.id)
                .map((alert) => ({
                    ...alert,
                    acknowledged: isAcknowledged(alert, bioscope.lastLocation?.id),
                })),
        };
    });

    const queryClient = useQueryClient();
    const acknowledgeAlert = useAcknowledgedAlertsController_acknowledgeAlert({
        onSettled: () =>
            queryClient.invalidateQueries([AcknowledgedAlertsController_getAcknowledgements.key]),
    });

    const unacknowledgeAlert = useAcknowledgedAlertsController_unacknowledgeAlert({
        onSettled: () =>
            queryClient.invalidateQueries([AcknowledgedAlertsController_getAcknowledgements.key]),
    });

    interface AlertProps {
        alert: {
            id: number;
            alertCode: string;
            acknowledged: boolean;
        };
        location: {
            id: number;
        };
    }
    const AlertDescription = ({ alert, location }: AlertProps) => {
        const code = alert.alertCode;
        const locationId = location?.id;
        const [showAlertMenu, setShowAlertMenu] = useState(false);
        const timePeriods = [
            {
                label: 'for 1 day',
                value: (date) => {
                    date.setDate(date.getDate() + 1);
                    return date;
                },
            },
            {
                label: 'for 1 week',
                value: (date) => {
                    date.setDate(date.getDate() + 7);
                    return date;
                },
            },
            {
                label: 'for 1 month',
                value: (date) => {
                    date.setMonth(date.getMonth() + 1);
                    return date;
                },
            },
            { label: 'until new pen', value: (date) => null },
        ];
        const silenceActions = timePeriods.map((period) => ({
            label: `Silence ${period.label}`,
            onClick: () =>
                acknowledgeAlert.mutate({
                    requestBody: {
                        alertCode: code,
                        locationId: locationId,
                        until: period.value(new Date())?.toISOString(),
                        alertId: alert.id,
                    },
                }),
        }));
        silenceActions.push({
            label: 'Silence until healed',
            onClick: () =>
                acknowledgeAlert.mutate({
                    requestBody: {
                        until: undefined,
                        alertCode: code,
                        locationId: locationId,
                        alertId: alert.id,
                    },
                }),
        });
        const unsilenceActions = [
            {
                label: 'Unsilence',
                onClick: () =>
                    unacknowledgeAlert.mutate({
                        requestBody: {
                            alertCode: code,
                            locationId: locationId,
                        },
                    }),
            },
        ];
        const toggle = () => {
            setShowAlertMenu(!showAlertMenu);
        };
        const message = alertMap[code];
        return (
            <Dropdown isOpen={showAlertMenu} toggle={toggle}>
                <DropdownToggle
                    className={
                        alert.acknowledged
                            ? 'text-muted d-inline-block mb-1'
                            : isUserActionRequired(code)
                              ? 'textDanger d-inline-block mb-1'
                              : 'textWarning d-inline-block mb-1'
                    }
                    tag="span">
                    {message} {showAlertMenu ? <FaAngleUp /> : <FaAngleDown />}
                </DropdownToggle>
                <DropdownMenu>
                    {(alert.acknowledged ? unsilenceActions : silenceActions).map((item) => (
                        <DropdownItem key={item.label} onClick={item.onClick}>
                            {item.label}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        );
    };

    interface AlertsContainerProps {
        alerts: AlertProps['alert'][];
        location?: {
            id: number;
        };
    }
    function AlertsContainer({ alerts, location }: AlertsContainerProps) {
        if (alerts.length === 0) {
            return <span className="textSuccess">{t('All good')}</span>;
        }
        return (
            <>
                {alerts
                    .sort(
                        (a, b) =>
                            Number(a.acknowledged) - Number(b.acknowledged) ||
                            bioscopeAlertCodes.indexOf(a?.alertCode) -
                                bioscopeAlertCodes.indexOf(b?.alertCode)
                    )
                    .map((alert) => (
                        <AlertDescription
                            key={alert?.alertCode}
                            alert={alert}
                            location={location}
                        />
                    ))}
            </>
        );
    }

    const BioscopeConfigurationFinclip = ({
        configuration,
    }: {
        configuration?: BioscopeConfiguration;
    }) => {
        const isFinclipActive = configuration?.config?.classifyAdiposeFin === true;

        const iconProps = isFinclipActive
            ? {
                  Icon: RiCheckboxCircleLine,
                  color: ColorSettings.alertColors.success,
                  message: 'Fin clip is active',
              }
            : {
                  Icon: RiCheckboxBlankCircleLine,
                  color: ColorSettings.alertColors.neutral,
                  message: 'Fin clip is not active',
              };

        return (
            <OptoTooltip nub="up-center" content={iconProps.message}>
                <iconProps.Icon size="1.7em" color={iconProps.color} />
            </OptoTooltip>
        );
    };

    const columnHelper = createColumnHelper<(typeof data)[0]>();

    const columns = [
        columnHelper.accessor((row) => row.id, {
            header: 'Id',
            cell: (info) => (
                <a href={`/internal/bioscopes/${info.getValue()}`}>{info.getValue()}</a>
            ),
        }),
        columnHelper.accessor((row) => row.connection?.cabinet, {
            header: 'Cabinet',
        }),
        columnHelper.accessor((row) => row.version?.version, {
            id: 'version',
            header: 'Version',
            cell: (info) => parseBioscopeVersion(info.getValue()),
            sortingFn: (a, b) =>
                cmpVersion(a.original.version?.version ?? '', b.original.version?.version ?? ''),
            sortUndefined: -1,
        }),
        columnHelper.accessor((row) => row.client?.name, {
            enableGlobalFilter: true,
            header: 'Client',
            cell: (info) => (
                <Link to={`/internal/clients/${info.row.original.clientId}`}>
                    {info.getValue()}
                </Link>
            ),
        }),
        columnHelper.accessor((row) => row.lastLocation?.cage?.location?.name, {
            header: 'Location',
            cell: (info) => {
                return (
                    <Link
                        to={`/c/${info.row.original.clientId}/location/${info.row.original.lastLocation?.cage?.location?.id}`}>
                        {' '}
                        {info.getValue()}
                    </Link>
                );
            },
            sortingFn: (a, b) =>
                a.original.lastLocation?.cage?.location?.name
                    .toLocaleLowerCase()
                    .localeCompare(
                        b.original?.lastLocation?.cage?.location?.name.toLocaleLowerCase()
                    ),
        }),
        columnHelper.accessor(
            (row) => t(row.lastLocation?.cage?.location?.fishTypes.name as string),

            {
                header: 'Fish type',
                cell: (row) => {
                    return row.getValue();
                },
            }
        ),
        columnHelper.accessor(
            (row) => t(row.lastLocation?.cage?.cageType as string),

            {
                header: 'Cage type',
                cell: (row) => {
                    return row.getValue();
                },
            }
        ),
        columnHelper.accessor((row) => row.lastLocation?.cage?.name, {
            header: 'Pen',
            cell: (info) => (
                <Link
                    to={`/c/${info.row.original.clientId}/location/${info.row.original.lastLocation.cage?.location?.id}/pen/${info.row.original.lastLocation?.cageId}`}>
                    {info.getValue()}
                </Link>
            ),
        }),
        columnHelper.accessor((row) => row.lastLocation?.updatedAt, {
            header: 'Moved',
            cell: (cell) =>
                cell.row.original?.lastLocation?.updatedAt
                    ? moment(cell.row.original.lastLocation?.updatedAt).format(
                          'DD/MM/YYYY HH:mm:ss'
                      )
                    : null,
            sortingFn: (a, b) =>
                new Date(a.original.lastLocation?.updatedAt).getTime() -
                new Date(b.original.lastLocation?.updatedAt).getTime(),
        }),
        columnHelper.accessor((row) => row.lastMeasurement?.timestamp, {
            id: 'lastMeasurement',
            header: 'Last measurement',

            enableGlobalFilter: false,
            cell: (cell) =>
                cell.row.original?.lastMeasurement?.timestamp
                    ? moment(cell.row.original.lastMeasurement.timestamp).format(
                          'DD/MM/YYYY HH:mm:ss'
                      )
                    : null,
            size: 2,
            sortingFn: (a, b) =>
                new Date(a.original.lastMeasurement?.timestamp).getTime() -
                new Date(b.original.lastMeasurement?.timestamp).getTime(),
        }),
        columnHelper.accessor(
            (row) => ({
                data: row.alerts,
                acknowledgements,
                toString: () => row.alerts.map((alert) => alertMap[alert.alertCode]).join(', '),
            }),
            {
                header: 'Alerts',
                cell: (info) => (
                    <AlertsContainer
                        alerts={info.row.original.alerts}
                        location={info.row.original.lastLocation}
                    />
                ),

                sortingFn: ({ original: a }, { original: b }) => {
                    const filteredA = a.alerts.filter(
                        (alert) => !isAcknowledged(alert, a.lastLocation?.id)
                    );
                    const filteredB = b.alerts.filter(
                        (alert) => !isAcknowledged(alert, b.lastLocation?.id)
                    );

                    const minAlert = (cand: AlertEntry[]) =>
                        Math.min(
                            ...cand.map((alert) => bioscopeAlertCodes.indexOf(alert.alertCode))
                        );

                    return (
                        minAlert(filteredB) - minAlert(filteredA) ||
                        filteredA.length - filteredB.length ||
                        0
                    );
                },
            }
        ),
        columnHelper.accessor((row) => Boolean(row.configuration?.config?.classifyAdiposeFin), {
            header: 'Fin clip',
            cell: (info) => (
                <BioscopeConfigurationFinclip configuration={info.row.original?.configuration} />
            ),
        }),
    ];

    return (
        <OptoTable
            columns={columns}
            data={data}
            filter={filter}
            initialSort={[{ id: 'lastMeasurement', desc: true }]}
        />
    );
}

interface FilterProps {
    filter: string;
    setFilter: (filter: string) => void;
}
function Filter<T extends FilterProps>({ filter, setFilter, ...other }: T) {
    const { t } = useTranslation();
    const [value, setValue] = React.useState(filter);

    React.useEffect(() => {
        const handler = setTimeout(() => {
            setFilter(value);
        }, 200);

        return () => {
            clearTimeout(handler);
        };
    }, [setFilter, value]);

    return (
        <span {...other}>
            <InputGroup>
                <Input
                    value={value || ''}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder={t('Search text')}
                />
                <InputGroupAddon addonType="append">
                    <InputGroupText style={{ padding: '0 0.75rem' }}>
                        <SearchIcon size={22} />
                    </InputGroupText>
                </InputGroupAddon>
            </InputGroup>
        </span>
    );
}

const BioscopeListContainer = () => {
    const { t } = useTranslation();
    const alerts = useAlertsController_allAlerts();
    const activeBioscopes = useBioscopeController_ActiveBioscopes();
    // Calculate the time for two hours ago
    const twoHoursAgo = new Date().getTime() - 2 * 60 * 60 * 1000;
    const currentTime = new Date().getTime();
    const onlineBioscopes = activeBioscopes.data?.data?.filter((item) => {
        const lastAliveTime = new Date(item.lastAlive).getTime();
        // Return true if the lastAlive time is between two hours ago and the current time
        return lastAliveTime >= twoHoursAgo && lastAliveTime <= currentTime;
    });

    const acknowledgements = useAcknowledgedAlertsController_getAcknowledgements();

    const clientList = useClientController_GetClients();
    const createBioscope = useBioscopeController_CreateBioscope({
        onSettled: () => activeBioscopes.refetch(),
    });
    const loading =
        alerts.isLoading ||
        activeBioscopes.isLoading ||
        clientList.isLoading ||
        createBioscope.isLoading;
    const error = alerts.error || activeBioscopes.error || clientList.error || createBioscope.error;
    const [addBioscopeModalIsOpen, setAddBioscopeModalIsOpen] = React.useState(false);
    const [filter, setFilter] = React.useState('');

    const onCreateBioscope = (clientId, bioscopeId) =>
        createBioscope
            .mutateAsync({
                requestBody: {
                    clientId: clientId === '' ? undefined : Number.parseInt(clientId),
                    bioscopeId: bioscopeId === '' ? undefined : Number.parseInt(bioscopeId),
                },
            })
            .then((result) => {
                console.log({ fn: 'onCreateBioscope', result }); // we are on internal page
            })
            .catch((error) => {
                const newError =
                    error.graphQLErrors && error.graphQLErrors.length > 0
                        ? error.graphQLErrors[0]
                        : {
                              message: 'Noe skjedde',
                          };

                throw newError;
            });

    return (
        <Page title="Bioscopes">
            <GenericSidebar
                sideBarOptions={internalSidebarOptions}
                sidebarType={SidebarType.internalLevel}
                layerUrlPrefix={'/internal'}
            />
            <PageContent>
                <Loader error={error} loading={loading}>
                    <div>
                        <Row>
                            <Col sm={6} className="text-center">
                                <PageTitle showH1={true} title={t('Bioscopes')} />
                            </Col>
                            <Col sm={6}>
                                <p className={styles.bioscopeNumber}>
                                    <SimpleToolTip
                                        helpText={
                                            'This indicates the total number of bioscopes that have been active in the past two hours.'
                                        }
                                        placement="bottom">
                                        {'Active'}:<strong>{onlineBioscopes?.length}</strong>
                                    </SimpleToolTip>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Box>
                                <div className="d-flex align-items-center mb-2">
                                    <div className="box p-2">
                                        <Button onClick={() => setAddBioscopeModalIsOpen(true)}>
                                            {t('Add Bioscope')}
                                        </Button>
                                    </div>

                                    <div className="box ml-auto p-2">
                                        <Filter filter={filter} setFilter={setFilter} />
                                    </div>
                                </div>

                                <BioscopeTable
                                    acknowledgements={acknowledgements.data?.data ?? []}
                                    alerts={alerts.data?.data ?? []}
                                    bioscopes={activeBioscopes.data?.data ?? []}
                                    filter={filter}
                                />
                            </Box>
                        </Row>
                    </div>
                </Loader>
                <AddBioscope
                    isOpen={addBioscopeModalIsOpen}
                    clients={clientList.data?.data ?? []}
                    createBioscope={onCreateBioscope}
                    onClose={() => {
                        setAddBioscopeModalIsOpen(false);
                    }}
                />
            </PageContent>
        </Page>
    );
};

export default BioscopeListContainer;
export { BioscopeTable, Filter };
