import { useEffect, useRef, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import {
    ArcElement,
    Chart as ChartJS,
    ChartData,
    ChartOptions,
    Legend,
    LinearScale,
    LineElement,
    PieController,
    PluginChartOptions,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';
import cn from 'classnames';
import { saveAs } from 'file-saver';
import { t } from 'i18next';

import { IconButton } from '@/components/atoms/Buttons';
import GenericIcon from '@/components/atoms/icons/GenericIcon';
import * as iconUrls from '@/components/atoms/icons/GenericIcon/GenericIcons';
import SkeletonLoader from '@/components/atoms/SkeletonLoader';
import PanelToolBar from '@/components/molecules/PanelToolBar';

import 'chartjs-adapter-moment';

import { htmlLegendPlugin } from '../plugins/htmlLegendPlugin';

import styles from './OptoPieChart.module.scss';
// https://github.com/kurkle/chartjs-plugin-autocolors

ChartJS.register(
    LinearScale,
    PointElement,
    LineElement,
    PieController,
    ArcElement,
    Legend,
    htmlLegendPlugin,
    annotationPlugin,
    zoomPlugin,
    Tooltip,
    Title
);

export interface ChartActionButtons {
    copyAsPng?: boolean;
    resetZoom?: boolean;
    downloadAsPng?: boolean;
    fullScreen?: boolean;
    timeScaleSwitch?: boolean;
}

export interface OptoPieChartProps {
    chartName?: string;
    graphType?: 'pie';
    chartData: ChartData<'pie'>;
    chartOptions?: (data: ChartData<'pie'>) => ChartOptions<'pie'>;
    extras?: {
        count: number;
        relativeCount: number;
    }[];
    pluginOptions?: PluginChartOptions<'pie'>;
    loading?: boolean;
    actionButtons?: ChartActionButtons;
}

const OptoPieChart = ({
    chartName = '',
    chartData,
    chartOptions,
    loading = false,

    extras = [],
}: // Not a part of the chart.js data structure
OptoPieChartProps) => {
    const options = chartOptions(chartData);

    const chartRef = useRef<ChartJS<'pie'>>(null);
    const [chart, setChart] = useState(null);

    const chartBoundaries = useRef<HTMLDivElement>(null);

    const [isFullScreen, setisFullscreen] = useState(false);
    const [topheading, setTopheading] = useState(false);

    const showAsFullScreen = () => {
        if (chartBoundaries.current) {
            isFullScreen ? document.exitFullscreen() : chartBoundaries.current.requestFullscreen();
            chartBoundaries.current.onfullscreenchange = () => {
                chart?.resize();
                setisFullscreen(!isFullScreen);
            };
            return;
        }
    };

    const switchButtonandler = (e) => {
        const { ticks, type } = options.scales.x;
        options.scales.x = {
            type,
            time: {
                unit: e.currentTarget.name,
            },
            ticks,
        };
        //setOptions(options);
        chart?.update();
    };

    const downloadAsPngHandler = () => {
        const dateString = new Date().toISOString().split('T')[0];
        chart.canvas.toBlob((blob) =>
            saveAs(blob, chartName.length > 0 ? `${chartName}-${dateString}.png` : 'chart.png')
        );
    };

    const copyAsPngHandler = async (ev) => {
        if (!chart) {
            console.warn('No chart to copy');
            return;
        }
        const response = await chart.canvas.toBlob((blob) =>
            navigator.clipboard.write([new window.ClipboardItem({ 'image/png': blob })])
        );
        // TODO - show some kind of notification
        console.log('copied to clipboard', response);
    };

    const resetZoomHandler = () => {
        chart?.resetZoom();
    };

    useEffect(() => {
        if (chartRef.current) {
            setChart(chartRef.current as ChartJS<'pie'>);
            chartRef.current.update();
        }
    }, []);

    return (
        <SkeletonLoader loading={loading} name={`${chartName} loading..`}>
            <div className={styles.optopiechart} ref={chartBoundaries}>
                <div className={styles.chartHeaderSection}>
                    <div className={styles.chartTitle}>
                        {topheading && <h2>{options.plugins.title.text}</h2>}
                    </div>
                    <div
                        data-title={chartName}
                        className={cn(styles.htmlLegendBar, 'legend-container')}
                    />
                    <PanelToolBar hide={true}>
                        <IconButton
                            tooltip={t('Copy chart as PNG') as string}
                            onlyIcon={true}
                            variant="tertiary"
                            onClick={copyAsPngHandler}>
                            <GenericIcon icon={iconUrls.CopyIconSVG} />
                        </IconButton>
                        <IconButton
                            tooltip={t('Reset Zoom') as string}
                            onlyIcon={true}
                            variant="tertiary"
                            onClick={resetZoomHandler}>
                            <GenericIcon icon={iconUrls.ResetZoomIconSVG} />
                        </IconButton>
                        <IconButton
                            tooltip={t('Download chart as .png') as string}
                            onlyIcon={true}
                            variant="tertiary"
                            onClick={downloadAsPngHandler}>
                            <GenericIcon icon={iconUrls.DownloadFileIconSVG} />
                        </IconButton>
                        <IconButton
                            tooltip={t('Show chart in fullscreen') as string}
                            onlyIcon={true}
                            variant="tertiary"
                            onClick={showAsFullScreen}>
                            {isFullScreen ? (
                                <GenericIcon icon={iconUrls.FullScreenExitconSVG} />
                            ) : (
                                <GenericIcon icon={iconUrls.FullScreenIconSVG} />
                            )}
                        </IconButton>
                    </PanelToolBar>
                </div>

                <div className={cn(styles.tooltipContainer, 'tooltip-container')} />
                <Pie ref={chartRef} data={chartData} options={options} />
            </div>
        </SkeletonLoader>
    );
};

export default OptoPieChart;
export { OptoPieChart };
