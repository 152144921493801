import { Page } from '..';

import {
    Cataract,
    Emaciation,
    EyeDamage,
    FinnCondition,
    FirstImpression,
    GillLidd,
    JawDeformity,
    Maturation,
    ScaleLoss,
    SnoutWound,
    SpineDeformities,
    Wounds,
} from './LaksvelSubPages';

const LaksvelRoutes: Page[] = [
    {
        key: 1,
        hidden: false,
        label: 'Overall appearance',
        path: '',
        page: FirstImpression,
        params: {
            i18n: 'first_impression',
        },
    },
    {
        key: 2,
        hidden: false,
        label: 'Maturation',
        path: '/maturity',
        page: Maturation,
        params: {
            i18n: 'maturity',
        },
    },
    {
        key: 3,
        hidden: false,
        label: 'Body wounds',
        path: '/wounds',
        page: Wounds,
        params: {
            i18n: 'wounds',
        },
    },
    {
        key: 4,
        hidden: false,
        label: 'Emaciation',
        path: '/emaciation',
        page: Emaciation,
        params: {
            i18n: 'emaciation',
        },
    },
    {
        key: 5,
        hidden: false,
        label: 'Snout wounds',
        path: '/snout-wounds',
        page: SnoutWound,
        params: {
            i18n: 'snout_wounds',
        },
    },
    {
        key: 6,
        hidden: false,
        label: 'Gill lid',
        path: '/gill-lid',
        page: GillLidd,
        params: {
            i18n: 'gill_lid',
        },
    },
    {
        key: 7,
        hidden: false,
        label: 'Jaw deformities',
        path: '/deformed-jaw',
        page: JawDeformity,
        params: {
            i18n: 'deformed_jaw',
        },
    },
    {
        key: 8,
        hidden: false,
        label: 'Cataract',
        path: '/cataract',
        page: Cataract,
        params: {
            i18n: 'cataract',
        },
    },
    {
        key: 9,
        hidden: false,
        label: 'Eye damage',
        path: '/eye-damage',
        page: EyeDamage,
        params: {
            i18n: 'eye_damage',
        },
    },
    {
        key: 10,
        hidden: false,
        label: 'Fin status',
        path: '/fin-damage',
        page: FinnCondition,
        params: {
            i18n: 'fin_damage',
        },
    },
    {
        key: 11,
        hidden: false,
        label: 'Spine deformities',
        path: '/deformity',
        page: SpineDeformities,
        params: {
            i18n: 'deformity',
        },
    },
    {
        key: 12,
        hidden: false,
        label: 'Scale loss',
        path: '/scale-loss',
        page: ScaleLoss,
        params: {
            i18n: 'scale_loss',
        },
    },
];

export default LaksvelRoutes;
export { LaksvelRoutes, LaksvelRoutes as LaksvelMenuItems };
