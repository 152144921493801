import { useParams } from 'react-router-dom';
import { useCage } from 'contexts/cage-context';
import useInterval from 'hooks/useInterval';
import moment from 'moment';
import { useReportsController_Report } from 'services/hooks';

import { SuspendedLazy } from '@/utils/suspended-lazy';
import { Loader } from 'components/atoms/Loader';
const ReportDetail = SuspendedLazy(() =>
    import('./ReportDetail').then((m) => ({ default: m.ReportDetail }))
);
const REPORT_REFRESH_INTERVAL = 2000;

export const ReportDetailContainer = () => {
    const cage = useCage();
    const { cageId } = cage;
    const { reportId } = useParams<{ reportId?: string | undefined }>();

    if (!reportId || !cageId) {
        return null;
    }

    const { data, isLoading, error, refetch } = useReportsController_Report(
        cageId,
        parseInt(reportId)
    );

    const needsRefresh =
        !isLoading && (data?.data?.status === 'created' || data?.data?.status === 'started');
    const loading = isLoading || needsRefresh;
    useInterval(refetch, needsRefresh ? REPORT_REFRESH_INTERVAL : null);
    if (loading || error) {
        return <Loader loading={loading} error={error} />;
    }

    const report = (({ updatedAt, ...report }) => ({
        ...report.data,
        ...report,
        data: {
            ...report.data,
            weightUnitTag: report.weightUnitTag,
            lastFeedDay: report.lastFeedDay,
            start: moment(report.data?.start)?.toDate(),
            stop: moment(report.data?.stop)?.toDate(),
        },
        cage: cage.cage,
        location: cage.location,
        updatedAt: moment(updatedAt).toDate(),
        start: moment(report.data?.start).toDate(),
        stop: moment(report.data?.stop).toDate(),
        weightUnitTag: report.weightUnitTag,
        hasWarningAboutDataQuality: report?.data?.warning,
    }))(data?.data);

    return <ReportDetail {...report} />;
};
