/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { IconType } from 'react-icons';
import { CgBorderStyleDashed } from 'react-icons/cg';
import { MdTrendingDown, MdTrendingFlat, MdTrendingUp } from 'react-icons/md';
import { RiAlertLine, RiCheckboxCircleLine } from 'react-icons/ri';
import { useInView } from 'react-intersection-observer';
import { useHistory } from 'react-router-dom';
import { Table } from 'reactstrap';
import cn from 'classnames';
import moment from 'moment-timezone';
import { DISPLAY_DAY_FORMAT } from 'utils/formattedDay';
import { v4 as uuidv4 } from 'uuid';

import { AnchorButton } from '@/components/atoms/Buttons/AnchorButton';
import { FootNote } from '@/components/atoms/FootNote';
import { NavigateToIcon } from '@/components/atoms/icons/NavigateToIcon';
import { OptoTooltip } from '@/components/atoms/OptoTooltip';
import { ToolTipContent } from '@/components/atoms/ToolTipContent';
import { settings } from '@/config/settings';
import { graphSettings as ColorSettings } from '@/config/settings';
import { supressDepthAlarm } from '@/helpers/alarmCheckers';
import { createCageUrl } from '@/helpers/urlGenerator';
import type { OverviewCage, OverviewLocation } from '@/services/types';
import { handleDirectionForTooltip } from '@/utils/handleDirectionForTooltip';
import { Button } from 'components/atoms/Buttons';

import { default as overviewStyles } from '../overview.module.scss';
import { default as locationStyles } from './Location.module.scss';
const cx = cn.bind(overviewStyles);

interface TooltipProps {
    value?: number;
    growth: number;
    growthLimit: number;
    showPercentage: boolean;
    rowIndex: number;
    totalRows: number;
    dataType?: string;
}

const ValueWithToolTip = ({
    value,
    growth,
    growthLimit,
    showPercentage,
    rowIndex,
    totalRows,
    dataType,
}: TooltipProps) => {
    const { t } = useTranslation();

    let helpText: string;

    let showGrowth: string;

    let showValue: string;

    if (showPercentage) {
        showValue = isNaN(value) ? '' : `${(100 * value)?.toFixed(1).replace('-', '')}%`;
        showGrowth = isNaN(value) ? '---' : `${(100 * growth)?.toFixed(0).replace('-', '')}%`;
    } else {
        showValue = isNaN(value) ? '' : value?.toFixed(2).replace('-', '');
        showGrowth = growth?.toFixed(2).replace('-', '');
    }

    if (value === undefined) {
        helpText = t(`No data found`);
    } else if (growth > -growthLimit && growth < growthLimit) {
        helpText = `${t('stableText')}`;
    } else if (growth >= growthLimit) {
        helpText = `${t('Growing by')} ${showGrowth} ${t('each week')}`;
    } else if (growth <= -growthLimit) {
        helpText = `${t('Decreasing by')} ${showGrowth} ${t('each week')}`;
    } else {
        helpText = '';
    }

    function getIcon(op): [string, IconType] {
        const opNumber = Number(op);
        if (Number.isNaN(opNumber) || value === undefined) {
            return [ColorSettings.alertColors.neutral, CgBorderStyleDashed];
        }
        if (opNumber > -growthLimit && opNumber < growthLimit) {
            return [ColorSettings.alertColors.neutral, MdTrendingFlat];
        }
        if (opNumber > growthLimit) {
            return [ColorSettings.alertColors.danger, MdTrendingUp];
        }
        return [ColorSettings.alertColors.success, MdTrendingDown];
    }

    const [color, Icon] = getIcon(growth);

    return (
        <OptoTooltip
            nub={handleDirectionForTooltip(rowIndex, totalRows)}
            maxWidth={150}
            content={helpText}>
            <span data-type={dataType}>{showValue}</span>
            <Icon color={color} />
        </OptoTooltip>
    );
};

interface AlarmTooltipProps {
    value?: number;
    alarm: string;
    hasActiveBioscope: boolean;
    rowIndex: number;
    totalRows: number;
}
const AlarmWithToolTip = ({
    value,
    alarm = '',
    hasActiveBioscope,
    rowIndex,
    totalRows,
}: AlarmTooltipProps) => {
    const { t } = useTranslation();
    let helpText: string;
    if (alarm !== '') {
        helpText = t(`measurementStatusTexts.${alarm}`);
    } else {
        helpText = t('All good');
    }

    function getIcon(op): [string, IconType] {
        if (!hasActiveBioscope) {
            return [ColorSettings.alertColors.neutral, CgBorderStyleDashed];
        }
        if (op !== '' && value === 0) {
            return [ColorSettings.alertColors.success, RiAlertLine];
        }
        if (op !== '' && value === 1) {
            return [ColorSettings.alertColors.warning, RiAlertLine];
        }
        if (op !== '' && value === 2) {
            return [ColorSettings.alertColors.danger, RiAlertLine];
        }
        return [ColorSettings.alertColors.success, RiCheckboxCircleLine];
    }

    const [color, Icon] = getIcon(alarm);

    return (
        <OptoTooltip
            nub={handleDirectionForTooltip(rowIndex, totalRows)}
            maxWidth={150}
            content={helpText}>
            <Icon size="1.7em" color={color} />
        </OptoTooltip>
    );
};

interface LaksvelTooltipProps {
    cage?: OverviewCage;
    tooltipValue: number;
    rowindex?: number;
}
const LaksvelTooltip = ({ cage, tooltipValue, rowindex = 0 }: LaksvelTooltipProps) => {
    const { t } = useTranslation();

    function addLaksvelTooltipContent(
        tooltipContent: Array<{ key: string; value: string }>,
        laksvel: any
    ) {
        const parameters = cage?.laksvel?.laksvel?.parameters;

        if (parameters) {
            for (const key in parameters) {
                if (Object.prototype.hasOwnProperty.call(parameters, key)) {
                    const value = parameters[key]?.sum_score2_score3?.toFixed(1);
                    tooltipContent.push({
                        key: t(key),
                        value: `${value ?? 0}%`,
                    });
                }
            }
        }
    }

    const tooltipContent: {
        key: string;
        value: string;
    }[] = [];

    if (tooltipValue && cage && cage.laksvel) {
        addLaksvelTooltipContent(tooltipContent, cage.laksvel);
    } else {
        tooltipContent.push({
            key: '',
            value: '',
        });
    }

    const content = <ToolTipContent content={tooltipContent} layout={'two-column'} />;

    return (
        <OptoTooltip
            heading={
                cage?.laksvel?.laksvel?.avg_laksvel
                    ? `${tooltipValue.toFixed(1)}% ${t('Laksvel siteoverview tooltip heading')}`
                    : t('No data found')
            }
            subHeading={
                cage?.laksvel?.laksvel?.avg_laksvel
                    ? t('Laksvel siteoverview tooltip subheading')
                    : t('No data found')
            }
            nub={rowindex < 2 ? 'left-center' : 'up-center'}
            content={content}>
            {cage?.laksvel?.laksvel?.avg_laksvel ? (
                <span>{cage.laksvel.laksvel.avg_laksvel.toFixed(1)}%</span>
            ) : (
                <CgBorderStyleDashed color={ColorSettings.alertColors.neutral} />
            )}
        </OptoTooltip>
    );
};

interface FishSpeedAlarmProps {
    cage?: OverviewCage;
    alarm: string;
    hasActiveBioscope: boolean;
    rowindex?: number;
}

const FishSpeedAlarm = ({
    cage,
    alarm = '',
    hasActiveBioscope,
    rowindex = 0,
}: FishSpeedAlarmProps) => {
    const { t } = useTranslation();
    const tooltipContent: {
        key: string;
        value: string;
    }[] = [];

    if (alarm !== '' && cage && cage.speed !== undefined) {
        tooltipContent.push(
            {
                key: t('Status'),
                value: alarm,
            },
            {
                key: t('Speed'),
                value: cage.speed
                    ? `${cage.speed?.toFixed(2)} ${t('swimspeed')}`
                    : t('No data found'),
            },
            {
                key: t('Date'),
                value: moment(cage.lastSpeedMeasurement).format(DISPLAY_DAY_FORMAT),
            },
            {
                key: t('Daypart'),
                value: t(`daypartTag.${cage?.daypart?.tag}`) ?? '---',
            }
        );
    } else {
        tooltipContent.push({
            key: t('Speed'),
            value: t('No data found'),
        });
    }

    function getIcon(op: number): [string, IconType] {
        if (op === null || op === undefined) {
            return [ColorSettings.alertColors.neutral, CgBorderStyleDashed];
        }
        if (op > settings.swimspeed.high_speed_threshold) {
            return [ColorSettings.alertColors.danger, RiAlertLine];
        }
        if (
            op < settings.swimspeed.low_speed_threshold ||
            op > settings.swimspeed.medium_speed_threshold
        ) {
            return [ColorSettings.alertColors.warning, RiAlertLine];
        }
        return [ColorSettings.alertColors.success, RiCheckboxCircleLine];
    }

    const [color, Icon] = getIcon(cage?.speed);

    const content = <ToolTipContent content={tooltipContent} />;

    return (
        <OptoTooltip
            nub={rowindex < 2 ? 'left-center' : 'up-center'}
            maxWidth={150}
            content={content}>
            <Icon size="1.7em" color={color} />
        </OptoTooltip>
    );
};

interface avgLaksvelScores {
    avg_score2_score3: number;
    score_0: number;
    score_1: number;
    score_2: number;
    score_3: number;
}

interface LocationContext {
    someSelected: boolean;
    allSelected: boolean;
    showMarkHistorical: boolean;
    hasAccessToFishHealth: boolean;
    hasAccessToLice: boolean;
    hasAccessToLaksvel: boolean;
    hasAccessToFishSpeed: boolean;
    toggleSelectedCages: (boolean) => unknown;
    setSelectedCages: (cageId: number) => unknown;
    markHistorical: (cageId: number, historical: boolean) => Promise<unknown>;
    location: OverviewLocation;
    t: (string) => string;
    hasHistoricalPens: boolean;
    hideCompare: boolean;
    clientId: number;
}

interface CageContext {
    cage: OverviewCage;
    selected: boolean;
    t: (string) => string;
    locationContext: LocationContext;
}

const CheckAll = ({ someSelected, allSelected, toggleSelectedCages }: LocationContext) => {
    const checkAllRef = useRef<HTMLInputElement>();

    useEffect(() => {
        if (checkAllRef.current) {
            checkAllRef.current.indeterminate = someSelected;
        }
    }, [someSelected]);
    return (
        <input
            ref={checkAllRef}
            type="checkbox"
            checked={allSelected}
            onChange={() => toggleSelectedCages(!someSelected && !allSelected)}
        />
    );
};

const thousandSeparator = (e) => {
    const num = e.toFixed(0);
    return Number.parseFloat(num).toLocaleString('fr');
};

const HistoricalCheckbox = ({ cage, locationContext }: CageContext) => {
    const [isSetting, setIsSetting] = useState(false);
    const updateHistorical = async () => {
        try {
            setIsSetting(true);
            await locationContext.markHistorical(cage.id, !cage.historical);
        } finally {
            setIsSetting(false);
        }
    };

    return (
        <input
            type="checkbox"
            disabled={isSetting}
            checked={!!cage.historical}
            onChange={updateHistorical}
        />
    );
};

const columns: {
    header: (params: LocationContext) => React.ReactNode;
    dataName: string;
    show: boolean | ((params: LocationContext) => boolean);
    classNameHeader?: string;
    cell: (
        params: CageContext,
        rowIndex: number,
        totalRows: number,
        goToUrl: (url: string) => void
    ) => React.ReactNode;
}[] = [
    {
        header: ({ t }) => t('Historical'),
        dataName: 'historical',
        show: (ctx) => ctx.showMarkHistorical,
        cell: (cageContext) => {
            const id = uuidv4();
            return (
                <td key={id} data-name="historical" data-label={cageContext.t('Historical')}>
                    <HistoricalCheckbox {...cageContext} />
                </td>
            );
        },
    },
    {
        header: ({ t }) => '',
        dataName: 'cage',
        show: true,
        cell: (
            { cage, locationContext: { t, location, clientId } },
            rowIndex,
            totalRows,
            goToUrl
        ) => {
            const id = uuidv4();
            return (
                <td
                    key={id}
                    title={`${t('Go to')} ${t('Cage')} ${cage.name}`}
                    data-label={t('Cage')}
                    data-name="cage">
                    <AnchorButton
                        tooltip={`${t('Go to')} ${t('Cage')} ${cage.name}`}
                        variant="table"
                        size="small"
                        to={createCageUrl(clientId, location.id, cage.id)}>
                        {t('Open')}
                    </AnchorButton>
                </td>
            );
        },
    },
    {
        header: ({ t }) => t('Name'),
        dataName: 'name',
        show: true,

        cell: ({ cage, locationContext: { t } }) => {
            const id = uuidv4();
            return (
                <td
                    key={id}
                    title={`${t('Cage')} ${cage.name}`}
                    data-label={t('name')}
                    data-name="name">
                    {`${cage?.name}`}
                </td>
            );
        },
    },
    {
        header: ({ t }) => t('Weight'),
        dataName: 'weight',
        show: true,
        cell: ({ cage, locationContext: { t, clientId, location } }) => {
            const id = uuidv4();
            return (
                <td key={id} data-label={t('Weight')} data-name="weight">
                    <a
                        href={`${createCageUrl(
                            clientId,
                            location.id,
                            cage.id
                        )}/weight-distribution`}>
                        {`${
                            cage?.weight?.livingWeight
                                ? `${thousandSeparator(cage?.weight?.livingWeight)} g`
                                : '---'
                        }`}
                    </a>
                </td>
            );
        },
    },
    {
        header: ({ t }) => `${t('Wound')}`,
        dataName: 'wound',
        show: ({ hasAccessToFishHealth, location }) => {
            /**
             * The information we show for wounds on the
             * site overveiw is not valid for trout, it is wrong!
             */
            return hasAccessToFishHealth && location.fishTypes.shortName === 'salmon';
        },
        cell: ({ cage, locationContext: { t, clientId, location } }, rowIndex, totalRows) => {
            const id = uuidv4();
            return (
                <td key={id} data-label={t('Wound')} data-name="wound">
                    <a href={`${createCageUrl(clientId, location.id, cage.id)}/health/wounds`}>
                        <ValueWithToolTip
                            value={cage?.welfare?.woundRate}
                            growthLimit={0.025} // change the limit according to the DEFAULT_EQ_THRESHOLD in utils.js
                            growth={cage?.welfare?.woundSlope as number}
                            showPercentage={true}
                            rowIndex={rowIndex}
                            totalRows={totalRows}
                            dataType={'text-link'}
                        />
                    </a>
                </td>
            );
        },
    },
    {
        header: ({ t }) => t('Laksvel'),
        dataName: 'laksvel',
        show: ({ hasAccessToLaksvel }) => hasAccessToLaksvel,
        cell: ({ cage, locationContext: { t, clientId, location } }, rowIndex) => {
            const id = uuidv4();
            return (
                <td key={id} data-label={t('Laksvel')} data-name="laksvel">
                    <a href={`${createCageUrl(clientId, location.id, cage.id)}/laksvel`}>
                        <LaksvelTooltip
                            rowindex={rowIndex}
                            cage={cage}
                            tooltipValue={cage?.laksvel?.laksvel?.avg_laksvel}
                        />
                    </a>
                </td>
            );
        },
    },
    {
        header: ({ t }) => t('Lice'),
        dataName: 'lice',
        show: ({ hasAccessToLice }) => hasAccessToLice,
        cell: ({ cage, locationContext: { t, clientId, location } }, rowIndex, totalRows) => {
            const id = uuidv4();
            return (
                <td key={id} data-label={t('Mature female lice')} data-name="lice">
                    <a href={`${createCageUrl(clientId, location.id, cage.id)}/lice-count`}>
                        <ValueWithToolTip
                            value={cage?.lice?.femaleLegacyAvg}
                            showPercentage={false}
                            growthLimit={0.05}
                            growth={cage?.lice?.femaleLegacyAvgDiff as number}
                            rowIndex={rowIndex}
                            totalRows={totalRows}
                            dataType={'text-link'}
                        />
                    </a>
                </td>
            );
        },
    },
    {
        header: ({ t }) => t('Swim Speed'),
        dataName: 'swim speed',
        show: ({ hasAccessToFishSpeed }) => hasAccessToFishSpeed,
        cell: ({ cage, t, locationContext: { clientId, location } }, rowIndex) => {
            const getSpeedStatus = (speed: number | undefined, t: (key: string) => string) => {
                if (speed === undefined) {
                    return t('No data found');
                }
                if (speed < settings.swimspeed.low_speed_threshold) {
                    return `${t('Slower than')} ${settings.swimspeed.low_speed_threshold} ${t(
                        'swimspeed'
                    )}`;
                }
                if (speed > settings.swimspeed.high_speed_threshold) {
                    return `${t('Faster than')} ${settings.swimspeed.high_speed_threshold} ${t(
                        'swimspeed'
                    )}`;
                }
                if (speed > settings.swimspeed.medium_speed_threshold) {
                    return `${t('Faster than')} ${settings.swimspeed.medium_speed_threshold} ${t(
                        'swimspeed'
                    )}`;
                }
                return t('Normal');
            };

            const id = uuidv4();

            return (
                <td key={id} data-name="speed" data-rows="" data-row-index={rowIndex}>
                    <a href={`${createCageUrl(clientId, location.id, cage.id)}/graphs/speed`}>
                        <FishSpeedAlarm
                            rowindex={rowIndex}
                            cage={cage}
                            alarm={getSpeedStatus(cage.speed, t)}
                            hasActiveBioscope={cage?.activeBioscopes?.length > 0}
                        />
                    </a>
                </td>
            );
        },
    },
    {
        header: ({ t }) => t('Growth'),
        dataName: 'growth',
        show: true,
        cell: ({ cage, t }) => {
            const id = uuidv4();
            return (
                <td key={id} data-label={t('Growth')} data-name="growth">
                    {
                        <span>{`${
                            cage?.weight?.growth || cage?.weight?.growth === 0
                                ? `${cage?.weight?.growth.toFixed(0)} g/day`
                                : '---'
                        }`}</span>
                    }
                </td>
            );
        },
    },

    {
        header: ({ t }) => t('Bioscope status'),
        dataName: 'bioscope status',
        show: true,
        cell: ({ cage }, rowIndex, totalRows) => {
            let alarms = cage?.weight?.measStatusList || [];

            alarms = alarms.filter((alarm) => {
                return (
                    cage?.cageType !== undefined &&
                    supressDepthAlarm({
                        cageType: cage.cageType,
                        alarmType: alarm,
                    }) === false
                );
            });

            const unackedAlarms = alarms.filter(
                (alarm) => !cage?.weight?.ackedStatusList?.includes(alarm)
            );
            let alarmLevel: number;
            if (alarms.length === unackedAlarms.length) {
                alarmLevel = cage?.weight?.measStatusLevel;
            } else {
                alarmLevel = unackedAlarms.length > 0 ? 1 : 0;
            }

            const id = uuidv4();

            return (
                <td key={id} data-name="status">
                    <AlarmWithToolTip
                        value={alarmLevel}
                        alarm={unackedAlarms.length > 0 ? unackedAlarms[0] : alarms[0]}
                        hasActiveBioscope={cage?.activeBioscopes?.length > 0}
                        rowIndex={rowIndex}
                        totalRows={totalRows}
                    />
                </td>
            );
        },
    },
    /*
    {
        header: ({ t }) => t('Type'),
        show: true,
        cell: ({ cage, t }) => {
            const id = uuidv4();
            return (
                <td key={id} data-name="cagetype">
                    {t(cage?.cageType?.name) ?? '---'}
                </td>
            );
        },
    },
    */
    {
        header: ({ t }) => t('Last measurement'),
        dataName: 'last measurement',
        show: true,
        cell: ({ cage, t, locationContext }) => {
            const id = uuidv4();
            return (
                <td key={id} data-label={t('Last measurement')} data-name="measurement">
                    <span>
                        {cage?.weight?.day
                            ? moment(cage?.weight?.day).format(DISPLAY_DAY_FORMAT)
                            : '---'}
                    </span>
                </td>
            );
        },
    },
    {
        header: (locationContext) => (
            <>
                {locationContext.t('Compare')}
                <CheckAll {...locationContext} />
            </>
        ),
        dataName: 'compare',
        classNameHeader: locationStyles.compareColumn,
        show: ({ hideCompare }) => {
            return !hideCompare;
        },
        cell: ({ cage, t, selected, locationContext: { setSelectedCages } }) => {
            const isSelected = selected;
            const id = uuidv4();
            return (
                <td key={id} data-label={t('Compare')} data-cell="compare" data-name="compare">
                    {
                        <input
                            type="checkbox"
                            checked={isSelected}
                            disabled={!cage.weight}
                            onChange={() => setSelectedCages(cage.id)}
                        />
                    }
                </td>
            );
        },
    },
];

interface LocationProps {
    location: OverviewLocation;
    hasAccessToMarkHistorical: boolean;
    createCompareUrl: (cageIds: number[]) => string;
    markHistorical: (cageId: number, historical: boolean) => Promise<unknown>;
    isOptoscaleAdmin?: boolean;
    hideCompare?: boolean;
    hideMarkHistorical?: boolean;
    hideNoMeasurements?: boolean;
    hideInactive?: boolean;
    showLocationNavigationButton?: boolean;
    clientId: number;
}

const LocationComponent = ({
    location,
    hasAccessToMarkHistorical,
    createCompareUrl,
    markHistorical,
    isOptoscaleAdmin = false,
    hideCompare = false,
    hideMarkHistorical = false,
    hideNoMeasurements = false,
    hideInactive = false,
    showLocationNavigationButton = false,
    clientId,
}: LocationProps) => {
    const hasAccessToFishHealth = location.cages.some((cage) => cage.welfare);
    const hasAccessToLice = location.cages.some((cage) => cage.lice);
    const hasAccessToLaksvel = location.cages.some((cage) => cage.laksvel);
    const hasAccessToFishSpeed = location.cages.some((cage) => cage.speed);
    const history = useHistory();
    const { t } = useTranslation();
    const cages = [...location.cages];

    const nameSort = (a: OverviewCage, b: OverviewCage) =>
        Number.parseFloat(a.name) - Number.parseFloat(b.name) || a.name.localeCompare(b.name);
    const historicalSort = (a: OverviewCage, b: OverviewCage) => {
        if (a.historical !== b.historical) {
            return a.historical ? 1 : -1;
        }
        if (a.activeBioscopes.length !== b.activeBioscopes.length) {
            return a.activeBioscopes.length > b.activeBioscopes.length ? -1 : 1;
        }
        return 0;
    };

    cages.sort((a, b) => historicalSort(a, b) || nameSort(a, b));
    const [showHistorical, setShowHistorical] = useState(false);
    const [showMarkHistorical, setShowMarkHistorical] = useState(false);

    const { ref, inView } = useInView({
        /* Optional options */
        initialInView: false,
        triggerOnce: true,
        rootMargin: '100px',
    });
    const [selectedCages, setSelectedCages] = useState(
        cages.map((cage) => ({ id: cage.id, name: cage.name, selected: false }))
    );
    const selectedCagesWithTrueValue = selectedCages
        .filter((cage) => cage.selected === true)
        .map((cage) => cage.id);

    const allSelected = selectedCages.every((cage) => cage.selected === true);
    const someSelected = allSelected ? false : selectedCages.some((cage) => cage.selected === true);

    const toggleSelectedCages = (on: boolean) => {
        const isHistoricalCage = ({ id: number }) =>
            location.cages.find((cage) => cage.id === number)?.historical ?? false;
        setSelectedCages((cages) =>
            cages.map((cage) => ({
                ...cage,
                selected: isHistoricalCage(cage) ? cage.selected : on,
            }))
        );
    };

    const locationContext: LocationContext = {
        someSelected,
        allSelected,
        hasAccessToFishHealth,
        hasAccessToLice,
        hasAccessToLaksvel,
        hasAccessToFishSpeed,
        toggleSelectedCages,

        setSelectedCages: (cageId) => {
            setSelectedCages((cages) =>
                cages.map((cage) =>
                    cage.id === cageId ? { ...cage, selected: !cage.selected } : cage
                )
            );
        },
        location,
        t,
        markHistorical,
        showMarkHistorical,
        hasHistoricalPens: cages.some((cage) => cage.historical),
        hideCompare: hideCompare,
        clientId: clientId,
    };

    const shownColumns = columns.filter(
        (column) =>
            column.show === true ||
            (typeof column.show === 'function' && column.show(locationContext))
    );

    const cageContexts = cages.map((cage) => ({
        cage,
        selected: selectedCages.find((c) => c.id === cage.id)?.selected,

        t,
        locationContext,
        historical: cage.historical,
        inactive: cage.activeBioscopes.length === 0,
        className: '',
    }));

    const goToUrl = (url) => {
        if (url) {
            history.push(url);
        }
    };

    return (
        <>
            <div
                ref={ref}
                className={cx({
                    // overflowHidden: true,
                    height0: !open,
                    heightAuto: open,
                })}>
                <Table className={cn(overviewStyles.locationTable, locationStyles.optoTable)} hover>
                    <thead>
                        <tr>
                            {shownColumns.map(({ header: title, dataName }, index) => (
                                <th scope={'col'} key={index} data-name={dataName}>
                                    <span>{title(locationContext)}</span>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody
                        data-show-all={showHistorical}
                        data-hide-no-measurements={hideNoMeasurements}
                        data-hide-inactive={hideInactive}>
                        {cageContexts.map((cage, rowIndex) => (
                            <tr
                                key={cage.cage.id}
                                className={cage.className}
                                data-cage-historical={cage?.historical?.length > 0}
                                data-cage-no-measurements={!cage.cage.weight}
                                data-cage-inactive={cage?.inactive}
                                onMouseEnter={(event) => {
                                    event.currentTarget.setAttribute('data-cage-hover', 'true');
                                }}
                                onMouseLeave={(event) => {
                                    event.currentTarget.removeAttribute('data-cage-hover');
                                }}>
                                {shownColumns.map(
                                    ({ cell }, columnIndex) =>
                                        (inView || columnIndex === 0) &&
                                        cell(cage, rowIndex, cageContexts.length, goToUrl)
                                )}
                            </tr>
                        ))}
                    </tbody>
                </Table>
                {location.fishTypes.shortName === 'trout' && isOptoscaleAdmin && (
                    <FootNote>
                        <p>{t('nowoundsfortrout')}</p>
                    </FootNote>
                )}
                {hideNoMeasurements &&
                    cageContexts.filter((x) => x.inactive || !x.cage.weight).length > 0 && (
                        <div className={locationStyles.tableFooterWithButton}>
                            <div
                                className={locationStyles.tableFooterContainer}
                                onClick={() =>
                                    history.push(`/c/${clientId}/location/${location.id}`)
                                }>
                                <p className={locationStyles.tableFooterNote}>
                                    {t('Pens without bioscopes are shown here')}{' '}
                                </p>
                                <NavigateToIcon color={'black'} />
                            </div>
                            {showLocationNavigationButton && (
                                <Button
                                    size={'small'}
                                    color={'secondary'}
                                    className={locationStyles.navigateToButton}
                                    onClick={() =>
                                        history.push(`/c/${clientId}/location/${location.id}`)
                                    }>
                                    <div>{t('Go to site')}</div>
                                    <NavigateToIcon color={'secondary'} />
                                </Button>
                            )}
                        </div>
                    )}

                {(!hideCompare || !hideMarkHistorical || showLocationNavigationButton) && (
                    <div className={locationStyles.locationActionButtons}>
                        {showLocationNavigationButton && !hideNoMeasurements && (
                            <Button
                                color={'secondary'}
                                className={locationStyles.navigateToButton}
                                onClick={() =>
                                    history.push(`/c/${clientId}/location/${location.id}`)
                                }>
                                <div>{t('Go to site')}</div>
                                <NavigateToIcon color={'secondary'} />
                            </Button>
                        )}

                        {!hideMarkHistorical && (
                            <Button
                                disabled={!locationContext.hasHistoricalPens}
                                color={'secondary'}
                                onClick={() => setShowHistorical(!showHistorical)}>
                                {showHistorical ? t('Hide historical') : t('Show historical')}
                            </Button>
                        )}

                        {hasAccessToMarkHistorical && !hideMarkHistorical && (
                            <Button
                                color={'secondary'}
                                onClick={() => setShowMarkHistorical(!showMarkHistorical)}>
                                {t('Mark Historical')}
                            </Button>
                        )}

                        {!hideCompare && (
                            <Button
                                color={'primary'}
                                disabled={selectedCagesWithTrueValue.length === 0}
                                to={createCompareUrl(selectedCagesWithTrueValue)}>
                                {t('Compare')}
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default LocationComponent;
export { LocationComponent as Location };
