import React, { useCallback, useState } from 'react';
import useCage from 'contexts/cage-context';
import { useQueryParams } from 'hooks/useQueryParams';
import { t } from 'i18next';
import {
    useFeedbackController_ImageFeedback,
    useLiceController_DailyLice,
    useLiceController_GetTemperature,
    useLiceController_Last200Lice,
    useLiceController_WeeklyLice,
    useWelfareController_allImages,
} from 'services/hooks';
import { DailyLiceCount, ImageFeedbackDto } from 'services/types';
import dayToString from 'utils/dayToString';

import { PageTitleRow } from '@/components/molecules/PageTitleRow';
import { getLabelByValue } from '@/components/molecules/ShareFalseDetectionDropdown/ShareFalseDetectionDropDownObjects';
import SwitchButtons from '@/components/molecules/SwitchButtons';
import { ToastTemplateBackendFeedback } from '@/components/molecules/ToastTemplate';
import { TroutNotice } from '@/components/organisms/TroutNotice/TroutNotice';
import useCageFilter from '@/contexts/cage-filter-context';
import { useMe } from '@/contexts/meContext';
import { fullSizeImageMapper, useImageState } from '@/hooks/image-state';
import { Loader } from 'components/atoms/Loader';
import { DownloadCsvXlsx } from 'components/DownloadCsvXlsx';
import SomethingHappened from 'components/SomethingHappened';

import { LICENSES } from '../../../../../config/licenses';

import LiceCount from './LiceCount';

import styles from './LiceCountContainer.module.scss';

const LiceCountContainer = () => {
    const { client, cageId, hasCageLicense, isTrout, timezone, cage } = useCage();
    const { isOptoscaleAdmin, state } = useMe();

    const { from, to, daypartTag } = useCageFilter();

    const defaultMeasurmentSource = client.featureFlags.includes('LICE_LEGACY_VIEW')
        ? 'raw'
        : 'legacy';

    const showToggle = true;

    const [measurementSource, setMeasurementSource] = useState<'raw' | 'legacy'>(
        defaultMeasurmentSource
    );

    const dayFrom = from ? dayToString(from) : undefined;
    const dayTo = to ? dayToString(to) : undefined;

    const queryParams = useQueryParams();

    const dailyLice = useLiceController_DailyLice(
        cageId,
        { dayFrom, dayTo, dayPart: daypartTag },
        { staleTime: 5 * 60000 }
    );
    const weeklyLice = useLiceController_WeeklyLice(
        cageId,
        { dayFrom, dayTo, dayPart: daypartTag },
        { staleTime: 5 * 60000 }
    );
    const last200 = useLiceController_Last200Lice(cageId, {
        staleTime: 5 * 60000,
    });

    const { data: temperature } = useLiceController_GetTemperature(cageId, {
        daypartTag: daypartTag,
        weightUnitTag: 'g',
    });

    const loading = last200.isLoading || dailyLice.isLoading || weeklyLice.isLoading;
    const error = last200.error || dailyLice.error || weeklyLice.error;

    /********** Loce detection images *********/

    const { data: imagesdata, isLoading: imagesIsLoading } = useWelfareController_allImages(
        cageId,
        { type: 'louse-image' },
        { enabled: !!cageId, keepPreviousData: true }
    );

    const imageState = useImageState({
        data: imagesdata?.data,
        mapper: useCallback(fullSizeImageMapper(cageId), [cageId]),
        loading: imagesIsLoading,
        timezone: timezone,
        queryParam: 'imageId',
    });

    const { mutateAsync: reportFeedbackAsync } = useFeedbackController_ImageFeedback();

    if (loading) {
        return <Loader />;
    }
    if (error) {
        return <SomethingHappened />;
    }

    /**********************************************/

    const onCommitImageFeedbackDto = async (feedbackDto: ImageFeedbackDto) => {
        if (state?.user?.id) {
            feedbackDto = { ...feedbackDto, reportingUserId: state.user.id };
        }
        if (cageId) {
            feedbackDto = { ...feedbackDto, cageId: cageId };
        }

        const { status, data } = await reportFeedbackAsync({ requestBody: feedbackDto });
        if (status) {
            ToastTemplateBackendFeedback({
                content: `Thank you ${
                    state?.user?.firstName ?? ''
                } for contributing to train our AI model on: ${t(getLabelByValue(data.tag) || '')}`,
                toastId: 'detection-feedback',
                httpStatusCode: status,
            });
        }
    };

    function mapData<
        T extends
            | typeof last200.data.data
            | (typeof weeklyLice.data.data)[0]
            | (typeof dailyLice.data.data)[0],
    >(data: T): T {
        return {
            ...data,
            caligusElongatusAvg:
                measurementSource === 'legacy' // Now reporting / simulated
                    ? data.caligusElongatusLegacyAvg
                    : data.caligusElongatusAvg,
            movingStageAvg:
                measurementSource === 'legacy' ? data.movingStageLegacyAvg : data.movingStageAvg,
            femaleAvg: measurementSource === 'legacy' ? data.femaleLegacyAvg : data.femaleAvg,
        };
    }

    const mapDataLast200 = (data: typeof last200.data.data): typeof last200.data.data => {
        const initalMap = mapData(data);

        return {
            ...initalMap,
            caligusElongatusAvgDiff:
                measurementSource === 'legacy'
                    ? data.caligusElongatusLegacyAvgDiff
                    : data.caligusElongatusAvgDiff,
            femaleAvgDiff:
                measurementSource === 'legacy' ? data.femaleLegacyAvgDiff : data.femaleAvgDiff,
            movingStageAvgDiff:
                measurementSource === 'legacy'
                    ? data.movingStageLegacyAvgDiff
                    : data.movingStageAvgDiff,
        };
    };

    const getLiceCountDataWithTemperature = () => {
        return dailyLice?.data?.data?.map((el) => ({
            ...el,
            temperature: temperature?.data?.find((e) => e.day === el.day)?.temperature ?? null,
        }));
    };

    const { dailyLiceCountForDownload, allColumnNames } = downloadableData(
        dailyLice.data?.data,
        queryParams
    );

    return (
        <>
            {isTrout && <TroutNotice />}

            <PageTitleRow title={cage?.name} subTitle={t('Lice')}>
                <div className={styles.pageTitleRowButtonContainer}>
                    <DownloadCsvXlsx
                        label="CSV"
                        data={dailyLiceCountForDownload}
                        COLUMNS={allColumnNames}
                    />
                    <DownloadCsvXlsx
                        label="XLSX"
                        data={dailyLiceCountForDownload}
                        COLUMNS={allColumnNames}
                    />
                    <div>
                        {showToggle && (
                            <SwitchButtons
                                onClick={(ev) =>
                                    setMeasurementSource(ev.currentTarget.value as 'raw' | 'legacy')
                                }
                                size="md"
                                switches={[
                                    {
                                        label: t('Raw data'),
                                        value: 'raw',
                                        selected: measurementSource === 'raw',
                                        tooltipKey: 'rawdataexplain',
                                    },
                                    {
                                        label: t('Simulated manual count'),
                                        value: 'legacy',
                                        selected: measurementSource === 'legacy',
                                        tooltipKey: 'reportdataexplain',
                                    },
                                ]}
                            />
                        )}
                    </div>
                </div>
            </PageTitleRow>

            <LiceCount
                imageState={imageState}
                isOptoscaleAdmin={isOptoscaleAdmin}
                last200={last200?.data?.data ? mapDataLast200(last200?.data?.data) : undefined}
                liceCount={getLiceCountDataWithTemperature()?.map(mapData)}
                liceCountOverviewWeekly={weeklyLice?.data?.data?.map(mapData)}
                showMaximumLiceInformation={hasCageLicense(LICENSES.LICE_COUNT.MAX)}
                onCommitImageFeedbackDto={onCommitImageFeedbackDto}
            />
        </>
    );
};

export default LiceCountContainer;
// biome-ignore lint/suspicious/noExplicitAny: <explanation>
function downloadableData(dailyLiceCount: DailyLiceCount[], queryParams?: any) {
    const DEFAULT_DAYPART_TAG_FOR_DOWNLOAD = 'day';
    const daypartTagForDownload = queryParams[0]?.d
        ? queryParams[0].d
        : DEFAULT_DAYPART_TAG_FOR_DOWNLOAD;
    const dailyLiceCountForDownload =
        dailyLiceCount?.map((el) => ({
            date: el.day,
            daypartTag: daypartTagForDownload,
            count: el.count,
            countNoLice: el.countNoLice,
            caligusElongatusAvg: el.caligusElongatusAvg.toFixed(2),
            femaleAvg: el.femaleAvg.toFixed(2),
            movingStageAvg: el.movingStageAvg.toFixed(2),
        })) ?? [];
    // need to send columns names in this format
    const locationInfoColumns = [
        'location.name',
        'location.timezone',
        'location.sWeightPct',
        'cage.name',
    ];
    const dataColumnNames =
        dailyLiceCountForDownload.length > 0 ? Object.keys(dailyLiceCountForDownload[0]) : [];
    const formattedDataColumnNames = dataColumnNames.map((e) => `day.${e}`);
    const allColumnNames = [...locationInfoColumns, ...formattedDataColumnNames];
    return { dailyLiceCountForDownload, allColumnNames };
}
