import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Alert, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { useQueryClient } from '@tanstack/react-query';
import { useUserController_Login } from 'services/hooks';

import { Button } from 'components/atoms/Buttons';
import Loader from 'components/atoms/Loader';
import ChangeLanguage from 'components/molecules/ChangeLanguage/ChangeLanguage';

import '../public.scss';
import styles from './Login.module.scss';

export const Login: React.Factory<{}> = () => {
    const [values, setValues] = React.useState({
        email: '',
        password: '',
    });
    const { search } = useLocation();
    const query = new URLSearchParams(search);

    const {
        mutate: login,
        isLoading: loading,
        error,
    } = useUserController_Login({
        onSuccess: () => {
            queryClient.invalidateQueries();
            window.location.replace(query.get('redirectTo') || '/');
        },
    });

    const { t } = useTranslation();

    const queryClient = useQueryClient();

    const errorMessage = (error?.message as any)?.message; //the code generator is broken - it does not handle replies that are JSON objects

    return (
        <div className={`${styles.login} ${'pt-5'}`}>
            <Container>
                <Row className="justify-content-md-center flex-column">
                    <div className="languageBox">
                        <ChangeLanguage />
                    </div>
                    <Col md={{ size: 6, offset: 3 }} className={styles.loginBox}>
                        <div className="d-flex justify-content-center mb-4">
                            <img src="/logo.png" alt="" className="logo" />
                        </div>
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                login({
                                    queryParams: {
                                        username: values.email,
                                        password: values.password,
                                    },
                                });
                            }}>
                            <FormGroup>
                                <Label className="text-white">{t('Email')}</Label>
                                <Input
                                    type="email"
                                    name="email"
                                    placeholder="email"
                                    value={values.email}
                                    className="formControl"
                                    onChange={(e) =>
                                        setValues({
                                            ...values,
                                            email: e.target.value,
                                        })
                                    }
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label className="text-white">{t('Password')}</Label>
                                <Input
                                    type="password"
                                    name="password"
                                    placeholder="password"
                                    value={values.password}
                                    className="formControl"
                                    onChange={(e) =>
                                        setValues({
                                            ...values,
                                            password: e.target.value,
                                        })
                                    }
                                />
                            </FormGroup>
                            <div className="mt-2 d-flex justify-content-center">
                                {loading ? (
                                    <div className="w-100 d-flex justify-content-center flex-column ml-3">
                                        <Loader size={'250px'} />
                                    </div>
                                ) : (
                                    <Button
                                        fullWidth
                                        size={'large'}
                                        type="submit"
                                        className="btn-block">
                                        {t('Login')}
                                    </Button>
                                )}
                            </div>
                            <div className="mt-4 text-center clearfix">
                                <Link to="/reset-password-request">{t('Reset password')}</Link>
                            </div>
                            {errorMessage ? (
                                <Alert color="danger" className="mt-3">
                                    {errorMessage}
                                </Alert>
                            ) : (
                                ''
                            )}
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
