import { useClientController_GetClient } from '@/services/hooks';
import { ClientInfo } from '@/services/types';

import LocationLayer from './LocationLayer';

interface LocationLayerContainerProps {
    clientId: number;
    locationId: number;
}

const LocationLayerContainer = ({ clientId, locationId }: LocationLayerContainerProps) => {
    const client = useClientController_GetClient(clientId, {
        enabled: clientId !== null,
    });

    const clientInfo: ClientInfo = client.data?.data ? client.data.data : null;

    return LocationLayer(clientId, locationId, clientInfo?.name);
};

export default LocationLayerContainer;
export { LocationLayerContainer };
