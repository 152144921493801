import { FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BlockTitle from '@/components/atoms/BlockTitle';
import { UmerBox } from '@/components/atoms/UmerBox';
import FishHealthFilter from '@/components/molecules/FishHealthFilter';
import { ImageViewContainer } from '@/components/organisms/ImageViewer';
import { LAKSVEL_FILTER_TAGS } from '@/components/organisms/ImageViewer/ImageMapper';
import { OptoBarChart } from '@/components/organisms/OptoGraphs';
import Options, {
    autoTimeScale,
    categoryScale,
    chartTitle,
    formatAxsis,
    minMax,
    noDots,
    startYAxisAtZero,
    TypeNames,
    weightAndLengthUnitConverter,
} from '@/components/organisms/OptoGraphs/optionsModifiers';
import {
    defaultBarChartOptions,
    last500LaksvelToBarChartMapper,
} from '@/components/organisms/OptoGraphs/OptoBarChart';
import OptoMixedChart, {
    defaultLineChartOptions,
} from '@/components/organisms/OptoGraphs/OptoMixedChart';
import { DailyLaksvelMapper } from '@/components/organisms/OptoGraphs/OptoMixedChart/OptoMixedChartMappers';
import { useLaksvel } from '@/contexts/laksvel-context';
import { fullSizeLaksvelImageMapper, useImageState } from '@/hooks/image-state';

import { LaksvelRouterPageProps } from '../LaksvelContainerRouter';

import styles from '../Laksvel.module.scss';

const LaksvelMaturation: FunctionComponent<LaksvelRouterPageProps> = () => {
    const { t } = useTranslation();
    const INDICATOR = 'maturity_score';

    const {
        loading,
        laksvelData,
        averageLaksvelData,
        laksvelImages,
        timezone,
        cageId,
        isOptoscaleAdmin,
    } = useLaksvel();

    const [selectedImageFilterTag, setSelectedImagedFilterTag] = useState<string>((): string => {
        if (LAKSVEL_FILTER_TAGS.length <= 0) {
            return LAKSVEL_FILTER_TAGS[0].tag;
        }

        return LAKSVEL_FILTER_TAGS[0].tag;
    });

    const laksvelFilter = (image) => {
        if (selectedImageFilterTag === 'all') {
            return image;
        }
        return image.score === selectedImageFilterTag;
    };

    const {
        dayswithimages,
        images,
        initialImageIndex,
        onAfterTimelineChangeHandler,
        selectedImageDate,
    } = useImageState({
        data: laksvelImages[INDICATOR],
        mapper: useCallback(fullSizeLaksvelImageMapper(cageId, INDICATOR), [cageId]),
        loading: loading,
        timezone: timezone,
        queryParam: 'imageId',
    });

    const onFilterGroupChangeHandler = (tag: string) => {
        setSelectedImagedFilterTag(tag);
    };

    const last500Mapped = last500LaksvelToBarChartMapper({
        data: averageLaksvelData,
        inactiveSetLegend: [],
        property: 'maturity',
    });

    return (
        <>
            <div className={styles.laksvelPageContentGrid_50_100}>
                <UmerBox>
                    <OptoBarChart
                        chartData={last500Mapped}
                        chartName={t('Status')}
                        loading={loading}
                        chartOptions={Options(defaultBarChartOptions).modify(
                            chartTitle({ title: t('Status') }),
                            categoryScale({
                                labels: last500Mapped.labels as string[] | undefined,
                            }),
                            weightAndLengthUnitConverter({
                                type: 'percent',
                                toUnit: '%',
                                maximumFractionDigits: 1,
                                minimumFractionDigits: 1,
                            }),
                            minMax({ min: 0, max: 100 }),
                            formatAxsis({
                                axsisID: 'y',
                                tickType: 'percent',
                                descimals: 0,
                            })
                        )}
                    />
                </UmerBox>
                <UmerBox>
                    <OptoMixedChart
                        chartName={t('Development')}
                        chartData={DailyLaksvelMapper({
                            data: loading ? [] : laksvelData,
                            laksvelAttribute: 'maturity',
                        })}
                        actionButtons={{
                            copyAsPng: true,
                            resetZoom: true,
                            downloadAsPng: true,
                            fullScreen: true,
                            timeScaleSwitch: false,
                        }}
                        chartOptions={Options(defaultLineChartOptions).modify(
                            chartTitle({ title: t('Development') }),
                            autoTimeScale({}),
                            weightAndLengthUnitConverter({
                                type: TypeNames.percent,
                                fromUnit: '%',
                                toUnit: '%',
                            }),
                            startYAxisAtZero({}),
                            noDots(),
                            minMax({ min: 0, max: 100 }),
                            formatAxsis({
                                axsisID: 'y',
                                tickType: TypeNames.percent,
                                descimals: 0,
                            })
                        )}
                        loading={loading}
                    />
                </UmerBox>
                <UmerBox>
                    <BlockTitle heading={t('Detections')} />
                    {!loading && isOptoscaleAdmin && (
                        <>
                            <div>
                                <ImageViewContainer
                                    initialImageIndex={initialImageIndex}
                                    images={images.filter(laksvelFilter) ?? []}
                                    dayswithimages={dayswithimages}
                                    viewerConfig={{
                                        showPois: false,
                                        showLaksvelLabel: true,
                                        showWoundLabel: false,
                                        showHelpText: false,
                                    }}
                                    isColor={true}
                                    onCommitImageFeedbackDto={() => {}}
                                    key="laksvelDetections"
                                    instanceKey="laksvelDetections"
                                    isOptoScaleAdmin={true}
                                    onAfterTimelineChangeHandler={onAfterTimelineChangeHandler}
                                />
                            </div>
                            <div className={styles.maturationFilterOptions}>
                                <FishHealthFilter
                                    filterStateList={LAKSVEL_FILTER_TAGS}
                                    onFilterGroupChange={onFilterGroupChangeHandler}
                                />
                            </div>
                        </>
                    )}
                </UmerBox>
            </div>
        </>
    );
};

export default LaksvelMaturation;
export { LaksvelMaturation };
