import { useCallback } from 'react';

import { useCage } from '../contexts/cage-context';
import { Page } from '../routes/Client/Cage/pages';

import useCheckPageFeatureFlags from './check-page-feature-flags';
import useCheckPageRoles from './check-page-roles';

function useIsPageAvailable() {
    const { hasCageLicense } = useCage();
    const checkPageRoles = useCheckPageRoles();
    const checkPageFeatureFlags = useCheckPageFeatureFlags();

    const checkPageLicenses = (page: Page) =>
        page.licenses?.some((license) => hasCageLicense(license)) ?? true;

    return useCallback(
        (page) =>
            [checkPageRoles, checkPageLicenses, checkPageFeatureFlags].every((check) =>
                check(page)
            ),
        [checkPageFeatureFlags, checkPageLicenses, checkPageRoles]
    );
}

export default useIsPageAvailable;
export { useIsPageAvailable };
