import React from 'react';
import { Link, matchPath, RouteComponentProps, withRouter } from 'react-router-dom';
import cn from 'classnames';

import styles from './navigationItem.module.scss';

interface NavigationItemProps extends RouteComponentProps {
    to: string;
    children: React.ReactNode;
    active?: boolean;
}

const NavigationItem = ({ to, children, location, active }: NavigationItemProps) => {
    // use props instead of router state to check if item is active
    // allow components to define their own active/non-active logic.
    const currentTab =
        active ||
        matchPath(location.pathname, {
            path: to,
            exact: true,
        });

    return (
        <Link
            to={to}
            className={cn(styles.navigationItem, {
                [styles.active]: currentTab,
            })}>
            <div className={styles.textWrapper}>{children}</div>
        </Link>
    );
};

export default withRouter(NavigationItem);
