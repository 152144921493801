interface ApiConfig {
    url: string;
}

const apiConfig: ApiConfig = {
    url: '',
};

export default apiConfig;
export { apiConfig };
export type { ApiConfig };
