import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'reactstrap';
import {
    useLicenseController_createNewLicenseForUser,
    useLicenseController_deleteUserLicenses,
} from 'services/hooks';

import { Button } from '@/components/atoms/Buttons';

import MeContext from '../../../contexts/meContext';

import styles from './userRow.module.scss';

const LABEL_STYLE = {
    marginLeft: `${25}px`,
    display: 'flex',
    width: `${85}px`,
};

export interface UserRowProps {
    user: {
        id: number;
        email: string;
        emailConfirmed: boolean;
        firstName: string;
        lastName: string;
        systemRole: string;
        clientUsers: {
            clients: {
                id: number;
            };
            role: string;
        }[];
    };
    clientId: number;
    canDeleteUsers: boolean;
    canImpersonateUserUsers: boolean;
    deleteUser: (id: number) => Promise<unknown>;
    impersonateUser: (id: number) => Promise<unknown>;
}

export const UserRow = ({
    user,
    clientId,
    canDeleteUsers,
    deleteUser,
    canImpersonateUserUsers,
    impersonateUser,
}: UserRowProps) => {
    const { t } = useTranslation();
    const isUserOptoscaleAdmin = user.systemRole === 'ADMIN';

    let roleName = '';
    if (isUserOptoscaleAdmin) {
        roleName = t('OptoScale admin');
    } else if (clientId) {
        const clientRole = user.clientUsers.find((role) => role.clients.id === clientId);

        if (clientRole) {
            if (clientRole.role === 'ADMIN') {
                roleName = t('Client admin');
            } else if (clientRole.role === 'LOCATION_ADMIN') {
                roleName = t('Location admin');
            }
        }
    } else if (user?.clientUsers.length > 0) {
        if (user?.clientUsers[0].role === 'ADMIN') {
            roleName = t('Client admin');
        } else if (user?.clientUsers[0].role === 'LOCATION_ADMIN') {
            roleName = t('Location admin');
        }
    }

    return (
        <>
            <tr>
                <td>
                    {user.firstName ? user.firstName : ''} {user.lastName ? user.lastName : ''}
                </td>
                <td className={styles.emailColumn}>{user.email}</td>
                <td>{roleName}</td>
                <td>{user.emailConfirmed ? 'Yes' : null}</td>
                {canImpersonateUserUsers && (
                    <td className={styles.loginAsButton}>
                        <ImpersonateUserButton impersonateUser={impersonateUser} id={user.id} />
                    </td>
                )}
                {canDeleteUsers && (
                    <td className={styles.deleteButton}>
                        <DeleteUserButton deleteUser={deleteUser} id={user.id} />
                    </td>
                )}
            </tr>
        </>
    );
};

const UserLicenses = ({ user, isUserOptoscaleAdmin, clientId, queryClient }) => {
    const { t } = useTranslation();
    const { isClientAdmin } = React.useContext(MeContext);
    const hasAdminPrivileges = isClientAdmin(clientId);
    const newLicenseMutation = useLicenseController_createNewLicenseForUser();
    const deleteUserLicense = useLicenseController_deleteUserLicenses();

    const [loading, setLoading] = useState(false);

    const handleOnChange = (event, cl, cu) => {
        if (!hasAdminPrivileges) {
            event?.preventDefault();
            return false;
        }
        setLoading(true);
        if (event.target.checked) {
            newLicenseMutation
                .mutateAsync({
                    requestBody: {
                        clientId: cl.clientId,
                        clientLicenseId: cl.id,
                        clientUserId: cu.id,
                    },
                })
                .finally(() => {
                    queryClient.invalidateQueries();
                    setLoading(false);
                });
        } else {
            const ul = cu.userLicenses.filter((o) => o.clientLicenseId === cl.id);
            if (ul.length > 0) {
                deleteUserLicense.mutateAsync({ id: ul[0].id }).finally(() => {
                    queryClient.invalidateQueries();
                    setLoading(false);
                });
            }
        }
    };

    let getLicense = [];
    getLicense = user?.clientUsers?.map((cu) => {
        return cu.userLicenses.map((ul) => {
            return cu.clients.clientLicenses.find((cl) => cl.id === ul.clientLicenseId);
        });
    });

    return (
        <>
            {isUserOptoscaleAdmin
                ? 'All'
                : user.clientUsers.map((cu, index) => {
                      return (
                          /**
                           * check either show user based on client
                           * Or show all user which is only for Optoscale Admin
                           */
                          clientId ? (
                              cu.clients.clientLicenses
                                  .filter((f) => f.clientId === clientId)
                                  .map((cl, i) => {
                                      return (
                                          <div key={i}>
                                              <label style={LABEL_STYLE} key={i}>
                                                  <Input
                                                      className={styles.inputCheck}
                                                      type="checkbox"
                                                      id={i}
                                                      checked={cu.userLicenses.some(
                                                          (o) => o.clientLicenseId === cl.id
                                                      )}
                                                      disabled={!hasAdminPrivileges || loading}
                                                      onChange={(event) =>
                                                          handleOnChange(event, cl, cu)
                                                      }
                                                  />
                                                  {t(cl?.licenses?.name ?? ' ')}
                                              </label>
                                          </div>
                                      );
                                  })
                          ) : (
                              <div key={index}>
                                  {
                                      /**
                                       * Show Client name when the user have more than one clients
                                       */
                                      getLicense.length > 1 ? <h5>{cu?.clients?.name}</h5> : null
                                  }
                                  <div>
                                      {cu?.clients?.clientLicenses.map((cl, i) => {
                                          return (
                                              <label style={LABEL_STYLE} key={i}>
                                                  <Input
                                                      className={styles.inputCheck}
                                                      type="checkbox"
                                                      id={i}
                                                      checked={cu.userLicenses.some(
                                                          (o) => o.clientLicenseId === cl.id
                                                      )}
                                                      disabled={loading}
                                                      onChange={(event) =>
                                                          handleOnChange(event, cl, cu)
                                                      }
                                                  />
                                                  {t(cl?.licenses?.name ?? ' ')}
                                              </label>
                                          );
                                      })}
                                  </div>
                              </div>
                          )
                      );
                  })}
        </>
    );
};

interface DeleteUserButtonProps {
    deleteUser: (id: number) => Promise<unknown>;
    id: number;
}

const DeleteUserButton = ({ deleteUser, id }: DeleteUserButtonProps) => {
    const { t } = useTranslation();

    return (
        <Button
            variant="danger"
            onClick={async (e) => {
                e.preventDefault();
                const confirmDelete = window.confirm(
                    t('Are you sure you want to delete this user')
                );
                if (confirmDelete) {
                    await deleteUser(id);
                }
            }}>
            {t('Delete user')}
        </Button>
    );
};

interface ImpersonateUserButtonProps {
    impersonateUser: (id: number) => Promise<unknown>;
    id: number;
}

const ImpersonateUserButton = ({ impersonateUser, id }: ImpersonateUserButtonProps) => {
    const { t } = useTranslation();

    return (
        <Button
            color="warning"
            onClick={async (e) => {
                e.preventDefault();

                await impersonateUser(id);
            }}>
            {t('Login as user')}
        </Button>
    );
};
