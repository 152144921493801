import React from 'react';

import styles from './GenericIcon.module.scss';

export interface GenericIconProps {
    icon?: string;
    size?: number;
    color?: string;
}

// icon in this style: mgc_{name}_{style}

const GenericIcon = ({ icon = '', size = 25, color = 'black' }: GenericIconProps) => {
    const iconUrl = typeof icon === 'string' ? icon.replace('/public', '') : icon;
    return (
        <div
            className={styles.genericiconcontainer}
            style={{
                backgroundColor: color,
                maskImage: `url(${iconUrl})`,
                height: size,
                width: size,
            }}
        />
    );
};

export default GenericIcon;
export { GenericIcon };
