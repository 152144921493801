import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiAlarmWarningLine } from 'react-icons/ri';
import { Col, Dropdown, DropdownToggle, Row } from 'reactstrap';
import cn from 'classnames';
import formattedWeightUnit from 'utils/formattedWeightUnit';

import AnchorButton from '@/components/atoms/Buttons/AnchorButton';
import { CustomTable } from '@/components/organisms/CustomTable/CustomTable';
import { SimpleToolTip } from 'components/atoms/SimpleToolTip';
import { Box } from 'components/Layout';

import styles from './Location.module.scss';
const cx = cn.bind(styles);

const LESS_COUNT = 50;

interface LocationProps {
    cages: {
        cageId: number;
        caligusElongatusAvg: number;
        caligusElongatusTotal: number;
        count: number;
        depthAvg: number;
        depthMax: number;
        depthMin: number;
        femaleAvg: number;
        femaleTotal: number;
        livingWeight: number;
        movingStageAvg: number;
        movingStageTotal: number;
        name: string;
        isIncluded: boolean;
    }[];
    averages: {
        count: number;
        depthAvg: number;
        depthMax: number;
        depthMin: number;
        femaleAvg: number;
        caligusElongatusAvg: number;
        movingStageAvg: number;
        livingWeight: number;
        femaleTotal?: number;
        caligusElongatusTotal?: number;
        movingStageTotal?: number;
    };
    title: string;
    openDefault: boolean;
    locationPath: string;
    locationId: number;
    onHandleCageSelected: (locationId, cage) => void;
    onHandleAllCagesSelected: (locationId, isSelected) => void;
}

const Location = ({
    cages,
    averages,
    title,
    openDefault = true,
    locationPath,
    locationId,
    onHandleCageSelected,
}: LocationProps) => {
    const [open, setOpen] = React.useState(openDefault);
    const { t } = useTranslation();

    cages.sort((a, b) => parseFloat(a.name) - parseFloat(b.name) || a.name.localeCompare(b.name));

    const CountWithAlarm = ({ cage }) => {
        return cage.count < LESS_COUNT ? (
            <SimpleToolTip
                helpText={
                    cage.count === undefined
                        ? t('No data found')
                        : cage.count < LESS_COUNT
                          ? t('Less than 50 counts')
                          : ''
                }>
                <p>
                    {cage.count === undefined ? (
                        ''
                    ) : cage.count > LESS_COUNT ? (
                        cage.count
                    ) : (
                        <span
                            style={{ color: 'orange', lineHeight: '1em' }}
                            className="d-flex align-items-end">
                            {cage.count} <RiAlarmWarningLine size="1.4em" />
                        </span>
                    )}
                </p>
            </SimpleToolTip>
        ) : (
            <span>{cage.count}</span>
        );
    };

    return (
        <>
            {
                <Row>
                    <Col>
                        <Box>
                            <Dropdown
                                toggle={() => {
                                    setOpen(!open);
                                }}>
                                <DropdownToggle
                                    tag="h3"
                                    onClick={() => {
                                        setOpen(!open);
                                    }}
                                    className={
                                        open
                                            ? 'headingForCollapse openedCollapseBlue'
                                            : 'headingForCollapse'
                                    }>
                                    {title}{' '}
                                    <i
                                        className={cn({
                                            fas: true,
                                            'fa-caret-up': open,
                                            'fa-caret-down': !open,
                                        })}
                                    />
                                </DropdownToggle>

                                <div
                                    className={cx({
                                        overflowHidden: true,
                                        height0: !open,
                                        heightAuto: open,
                                    })}>
                                    <CustomTable className={styles.locationTable}>
                                        <thead>
                                            <tr>
                                                <th>{t('Cage')}</th>
                                                <th>
                                                    {t('Adult Female')}
                                                    <span className={styles.twoValues}>
                                                        <span className={styles.twoValuesColLeft}>
                                                            {t('Average')}
                                                        </span>
                                                        <b className={styles.separatorBar}>|</b>
                                                        <span className={styles.twoValuesColRight}>
                                                            {t('Total')}
                                                        </span>
                                                    </span>
                                                </th>
                                                <th>
                                                    {t('Mobile')}
                                                    <span className={styles.twoValues}>
                                                        <span className={styles.twoValuesColLeft}>
                                                            {t('Average')}
                                                        </span>
                                                        <b className={styles.separatorBar}>|</b>
                                                        <span className={styles.twoValuesColRight}>
                                                            {t('Total')}
                                                        </span>
                                                    </span>
                                                </th>
                                                <th>
                                                    {t('Caligus')}
                                                    <span className={styles.twoValues}>
                                                        <span className={styles.twoValuesColLeft}>
                                                            {t('Average')}
                                                        </span>
                                                        <b className={styles.separatorBar}>|</b>
                                                        <span className={styles.twoValuesColRight}>
                                                            {t('Total')}
                                                        </span>
                                                    </span>
                                                </th>
                                                <th>{t('Count')}</th>
                                                <th>{t('Weight (g)')}</th>
                                                <th>{t('Depth (m)')}</th>
                                                <th>{t('Include')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cages.map((cage) => {
                                                return (
                                                    <tr key={cage.cageId}>
                                                        <td
                                                            title={`${t('Go to')} ${t('Cage')} ${
                                                                cage.name
                                                            }`}
                                                            data-label={t('Cage')}>
                                                            <AnchorButton
                                                                to={`${locationPath}/pen/${cage.cageId}`}>
                                                                {cage.name}
                                                            </AnchorButton>
                                                        </td>
                                                        <td
                                                            data-label={t('Adult Female')}
                                                            data-sub-label={`(${t('Avg.')} | ${t(
                                                                'Total'
                                                            )})`}>
                                                            <span className={styles.twoValues}>
                                                                <span
                                                                    className={
                                                                        styles.twoValuesColLeft
                                                                    }>
                                                                    {cage.femaleAvg.toFixed(2) ??
                                                                        '---'}
                                                                </span>
                                                                <b className={styles.separatorBar}>
                                                                    |
                                                                </b>
                                                                <span
                                                                    className={
                                                                        styles.twoValuesColRight
                                                                    }>
                                                                    {cage.femaleTotal.toFixed(0) ??
                                                                        '---'}
                                                                </span>
                                                            </span>
                                                        </td>
                                                        <td
                                                            data-label={t('Mobile')}
                                                            data-sub-label={`(${t('Avg.')} | ${t(
                                                                'Total'
                                                            )})`}>
                                                            <span className={styles.twoValues}>
                                                                <span
                                                                    className={
                                                                        styles.twoValuesColLeft
                                                                    }>
                                                                    {cage.movingStageAvg.toFixed(
                                                                        2
                                                                    ) ?? '---'}
                                                                </span>
                                                                <b className={styles.separatorBar}>
                                                                    |
                                                                </b>
                                                                <span
                                                                    className={
                                                                        styles.twoValuesColRight
                                                                    }>
                                                                    {cage.movingStageTotal.toFixed(
                                                                        0
                                                                    ) ?? '---'}
                                                                </span>
                                                            </span>
                                                        </td>
                                                        <td
                                                            data-label={t('Caligus')}
                                                            data-sub-label={`(${t('Avg.')} | ${t(
                                                                'Total'
                                                            )})`}>
                                                            <span className={styles.twoValues}>
                                                                <span
                                                                    className={
                                                                        styles.twoValuesColLeft
                                                                    }>
                                                                    {cage.caligusElongatusAvg.toFixed(
                                                                        2
                                                                    ) ?? '---'}
                                                                </span>
                                                                <b className={styles.separatorBar}>
                                                                    |
                                                                </b>
                                                                <span
                                                                    className={
                                                                        styles.twoValuesColRight
                                                                    }>
                                                                    {cage.caligusElongatusTotal.toFixed(
                                                                        0
                                                                    ) ?? '---'}
                                                                </span>
                                                            </span>
                                                        </td>
                                                        <td
                                                            data-label={t('Count')}
                                                            className={styles.blackText}>
                                                            <CountWithAlarm cage={cage} />
                                                        </td>
                                                        <td
                                                            className={styles.greyText}
                                                            data-label={t('Weight')}>
                                                            <span>
                                                                {`${
                                                                    cage.livingWeight
                                                                        ? formattedWeightUnit(
                                                                              cage.livingWeight,
                                                                              'g',
                                                                              0,
                                                                              false
                                                                          ).value
                                                                        : '---'
                                                                }`}
                                                            </span>
                                                        </td>{' '}
                                                        <td
                                                            className={styles.greyText}
                                                            data-label={t('Depth')}>
                                                            <span>
                                                                {`${
                                                                    cage.depthAvg
                                                                        ? cage.depthAvg.toFixed(1)
                                                                        : '---'
                                                                }`}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                checked={cage.isIncluded}
                                                                onChange={() =>
                                                                    onHandleCageSelected(
                                                                        cage,
                                                                        locationId
                                                                    )
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td />
                                                <td data-label={t('Average Adult Female')}>
                                                    <div className={styles.highlightedText}>
                                                        <span className={styles.twoValues}>
                                                            <SimpleToolTip
                                                                helpText={t(
                                                                    'Average Adult Female'
                                                                )}>
                                                                <span
                                                                    className={
                                                                        styles.twoValuesColLeft
                                                                    }>
                                                                    <em>
                                                                        {averages.femaleAvg.toFixed(
                                                                            2
                                                                        ) ?? '---'}
                                                                    </em>
                                                                </span>
                                                            </SimpleToolTip>
                                                            <b className={styles.separatorBar}>|</b>
                                                            <SimpleToolTip
                                                                helpText={t('Total adult female')}>
                                                                <span
                                                                    className={
                                                                        styles.twoValuesColRight
                                                                    }>
                                                                    <em>
                                                                        {averages.femaleTotal.toFixed(
                                                                            0
                                                                        ) ?? '---'}
                                                                    </em>
                                                                </span>
                                                            </SimpleToolTip>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td data-label={t('Average Mobile')}>
                                                    <div className={styles.highlightedText}>
                                                        <span className={styles.twoValues}>
                                                            <SimpleToolTip
                                                                helpText={t('Average Mobile')}>
                                                                <span
                                                                    className={
                                                                        styles.twoValuesColLeft
                                                                    }>
                                                                    <em>
                                                                        {averages.movingStageAvg.toFixed(
                                                                            2
                                                                        ) ?? '---'}
                                                                    </em>
                                                                </span>
                                                            </SimpleToolTip>
                                                            <b className={styles.separatorBar}>|</b>
                                                            <SimpleToolTip
                                                                helpText={t('Total mobile')}>
                                                                <span
                                                                    className={
                                                                        styles.twoValuesColRight
                                                                    }>
                                                                    <em>
                                                                        {averages.movingStageTotal.toFixed(
                                                                            0
                                                                        ) ?? '---'}
                                                                    </em>
                                                                </span>
                                                            </SimpleToolTip>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td data-label={t('Average Caligus')}>
                                                    <div className={styles.highlightedText}>
                                                        <span className={styles.twoValues}>
                                                            <SimpleToolTip
                                                                helpText={t('Average Caligus')}>
                                                                <span
                                                                    className={
                                                                        styles.twoValuesColLeft
                                                                    }>
                                                                    <em>
                                                                        {averages.caligusElongatusAvg.toFixed(
                                                                            2
                                                                        ) ?? '---'}
                                                                    </em>
                                                                </span>
                                                            </SimpleToolTip>
                                                            <b className={styles.separatorBar}>|</b>
                                                            <SimpleToolTip
                                                                helpText={t('Total caligus')}>
                                                                <span
                                                                    className={
                                                                        styles.twoValuesColRight
                                                                    }>
                                                                    <em>
                                                                        {averages.caligusElongatusTotal.toFixed(
                                                                            0
                                                                        ) ?? '---'}
                                                                    </em>
                                                                </span>
                                                            </SimpleToolTip>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td
                                                    className={styles.blackText}
                                                    data-label={t('Count')}>
                                                    <SimpleToolTip helpText={t('Total count')}>
                                                        <em>{averages.count}</em>
                                                    </SimpleToolTip>
                                                </td>{' '}
                                                <td
                                                    className={styles.greyText}
                                                    data-label={t('Weight')}>
                                                    <SimpleToolTip helpText={t('Average weight')}>
                                                        <em>
                                                            {formattedWeightUnit(
                                                                averages.livingWeight,
                                                                'g',
                                                                0,
                                                                false
                                                            ).value ?? '---'}
                                                        </em>
                                                    </SimpleToolTip>
                                                </td>{' '}
                                                <td
                                                    className={styles.greyText}
                                                    data-label={t('Depth')}>
                                                    <SimpleToolTip helpText={t('Average Depth')}>
                                                        <em>
                                                            {averages.depthAvg.toFixed(1) ?? '---'}
                                                        </em>
                                                    </SimpleToolTip>
                                                </td>
                                                <td />
                                            </tr>
                                        </tfoot>
                                    </CustomTable>
                                </div>
                            </Dropdown>
                        </Box>
                    </Col>
                </Row>
            }
        </>
    );
};

export default Location;
