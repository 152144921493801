import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useContentController_Notices } from 'services/hooks';

import NoticeContainer from '@/components/atoms/Notice/NoticeContainer';
import { LocalizedString } from '@/services/types';

import styles from './pageContent.module.scss';

const PageContent = ({
    children,
    breadcrumb,
}: {
    children?: React.ReactNode;
    breadcrumb?: any;
}) => {
    const { i18n } = useTranslation();
    const { data, isLoading } = useContentController_Notices();

    const dynamicNotices = data?.data?.map(
        (notice: {
            _id: string;
            title: LocalizedString;
            content: LocalizedString;
            topic: string;
            role: string;
            startDate: string;
            endDate: string;
        }) => (
            <NoticeContainer
                key={notice._id}
                heading={notice.title[i18n.language as keyof LocalizedString]}
                message={notice.content[i18n.language as keyof LocalizedString]}
                variant="default"
                topic={notice.topic}
                role={notice.role}
                startDate={notice.startDate}
                endDate={notice.endDate}
                enableNotice={true}
                noticeId={notice._id}
                asToast={true}
            />
        )
    );

    return (
        <div
            data-pagetype="page-content"
            className={cn(styles.pageContent, {
                [styles.hasBreadcrumb]: !!breadcrumb,
            })}>
            {dynamicNotices}
            {breadcrumb}
            {children}
        </div>
    );
};

export default PageContent;
