import React, { ReactNode, useCallback } from 'react';
import classNames from 'classnames';
import moment from 'moment';

import TimeLineSlider from '@/components/molecules/TimeLineSlider';

import styles from './ScrollNavigator.module.scss';

export interface ScrollNavigatorProps {
    children?: ReactNode;
    listOfDates: { day: string; count: number }[];
    imageIndex: number;
    onAfterTimelineChangeHandler: (value: string) => void;
    pinnedNavHandler?: (pinned: boolean) => void;
    currentVisibleDay?: string;
    instanceKey: string;
}

const ScrollNavigator = ({
    children,
    listOfDates = [],
    currentVisibleDay = undefined,
    instanceKey = '',
    onAfterTimelineChangeHandler = () => {},
}: ScrollNavigatorProps) => {
    const onAfterChangeHandler = (value: string) => {
        onAfterTimelineChangeHandler(value);
    };

    const initialTimelineValue = listOfDates.findIndex((item) => {
        return (
            moment(currentVisibleDay).format('YYYY-MM-DD') === moment(item.day).format('YYYY-MM-DD')
        );
    });

    /*
        This callback function will load the image when the component
        that holds the img element is 300px outside the viewport
    */
    const lazyLoadCallback = (entries, observer) => {
        for (const entry of entries) {
            if (entry.isIntersecting) {
                const imageElement = entry.target.querySelector('img');
                if (
                    imageElement.hasAttribute('data-src') &&
                    imageElement.src !== imageElement.dataset.src
                ) {
                    imageElement.src = imageElement.dataset.src;
                    imageElement.removeAttribute('data-src');
                    observer.unobserve(entry.target);
                }
            }
        }
    };

    const scrollIntoViewCallback = (entries) => {
        for (const entry of entries) {
            if (
                !entry.isIntersecting &&
                entry.target.hasAttribute('data-iscurrent') &&
                entry.target.dataset.iscurrent === 'true'
            ) {
                const lastScroll = entry.target?.parentNode?.['lastScroll'];

                if (
                    entry.target?.parentNode &&
                    (!lastScroll || performance.now() > lastScroll + 50)
                ) {
                    entry.target.parentNode.scrollTo({
                        left: entry.target.offsetLeft - entry.target.parentNode.offsetWidth / 2,
                        behavior: 'smooth',
                    });
                }
            }
        }
    };

    const scrollNavigationRefHandler = useCallback(
        (node: HTMLImageElement) => {
            if (!node) {
                return;
            }

            // Setup IntersectionObserver for lazy loading images
            const lazyLoadObserver = new IntersectionObserver(lazyLoadCallback, {
                root: node,
                rootMargin: '600px',
                threshold: 0,
            });

            // (top, right, bottom, left).
            const scrollToViewObserver = new IntersectionObserver(scrollIntoViewCallback, {
                root: node,
                rootMargin: '0px',
                threshold: 0,
            });

            const navElement = node.querySelector('nav');
            for (const child of navElement.childNodes) {
                if (child instanceof HTMLElement) {
                    child.setAttribute('data-instancekey', instanceKey);
                    lazyLoadObserver.observe(child);
                    scrollToViewObserver.observe(child);
                }
            }
            navElement.addEventListener('scroll', (e) => {
                // biome-ignore lint/complexity/useLiteralKeys: Key/value are supported in this case
                navElement['lastScroll'] = e.timeStamp;
            });

            const slider = node;

            if (slider !== null) {
                let isDown = false;
                let startX: number;
                let scrollLeft: number;

                slider.addEventListener('mousedown', (e) => {
                    isDown = true;
                    slider.classList.add('active');
                    startX = e.pageX - slider.offsetLeft;
                    scrollLeft = slider.scrollLeft;
                });
                slider.addEventListener('mouseleave', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mouseup', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mousemove', (e) => {
                    if (!isDown) return;
                    e.preventDefault();
                    const x = e.pageX - slider.offsetLeft;
                    const walk = (x - startX) * 3;
                    slider.scrollLeft = scrollLeft - walk;
                });
            }
        },
        [lazyLoadCallback, scrollIntoViewCallback, instanceKey]
    );

    return (
        <div className={classNames(styles.scrollnavigator)} ref={scrollNavigationRefHandler}>
            <TimeLineSlider
                dateList={listOfDates}
                onAfterChangeHandler={onAfterChangeHandler}
                showCurrentTime={false}
                value={initialTimelineValue}
            />
            <nav className={styles.navigationScroller}>{children}</nav>
        </div>
    );
};

export default ScrollNavigator;
export { ScrollNavigator };
