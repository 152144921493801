import { t } from 'i18next';

import { GenericSidebar, SidebarType } from '@/components/molecules/GenericSidebar';
import LocationRoutes from '@/routes/Client/Location/LocationRoutes';
import { locationSidebarOptions, pages } from '@/routes/Client/Location/pages';
import { Page, PageContent } from 'components/Layout';

function LocationLayer(clientId: number, locationId: number, clientName?: string) {
    return (
        <Page title="Location">
            <GenericSidebar
                sideBarOptions={locationSidebarOptions}
                sidebarType={SidebarType.locationLevel}
                backOption={{
                    url: `/c/${clientId}/`,
                    title: clientName ? clientName : t('Back'),
                }}
            />

            <PageContent>
                <LocationRoutes pages={pages} clientId={clientId} locationId={locationId} />
            </PageContent>
        </Page>
    );
}

export default LocationLayer;
