import { JSX } from 'react';

import { IconButton, IconButtonProps } from '@/components/atoms/Buttons/IconButton/IconButton';
import { DownloadIcon } from '@/components/atoms/icons';

interface DownloadPdfProps extends IconButtonProps {
    buttonText: string;
    docGenerator: () => Promise<JSX.Element>;
    fileName: string;
}
export const DownloadPDF = ({
    buttonText,
    docGenerator,
    fileName,
    variant = 'secondary',
    size = 'medium',
}: DownloadPdfProps) => {
    const sanitizedFileName = fileName
        .replace(/\s+/g, '-') // Replace white spaces with '-'
        .replace(/[^a-zA-Z0-9-\u00C0-\u024F\u1E00-\u1EFF]/g, ''); // Remove special characters except English and non-English alphabets

    const downloadPDF = async () => {
        const { pdf } = await import('@react-pdf/renderer');
        const blob = await pdf(await docGenerator()).toBlob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${sanitizedFileName}.pdf`);
        link.click();
        URL.revokeObjectURL(url);
    };

    return (
        <IconButton variant={variant} size={size} onClick={downloadPDF} buttonText={buttonText}>
            <DownloadIcon size={20} />
        </IconButton>
    );
};
