import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import moment from 'moment';

import LiceChartHeader from '@/components/organisms/DeprecatedGraphs/Chart/headers/ChartHeader/LiceChartHeader';
import FullScreenCard from 'components/Layout/Cards/FullScreenCard/FullScreenCard';

import Graph from '../charts/LiceMeanPerFish';
import WeeklyGraph from '../charts/LiceMeanPerFish/WeeklyGraph';

import styles from './LiceMeanPerFishGraph.module.scss';

// Setting startOf week to monday
moment.updateLocale('en', {
    week: {
        dow: 1,
    },
});

const MIN_FISH_COUNT_FOR_CERTAIN_LICE = {
    DAILY: 100,
    WEEKLY: 500,
};

interface LiceMeanPerFishGraphProps {
    liceCount: {
        day: string;
        femaleAvg: number;
        total: number;
    }[];
    liceCountOverviewWeekly: {
        weekNumber: number;
        year: number;
        count: number;
        femaleAvg: number;
        caligusElongatusAvg: number;
        movingStageAvg: number;
    }[];
    showMaximumLiceInformation: boolean;
}

const LiceMeanPerFishGraph = ({
    liceCount,
    liceCountOverviewWeekly,
    showMaximumLiceInformation,
}: LiceMeanPerFishGraphProps) => {
    const { t } = useTranslation();
    const [timeUnit, setTimeUnit] = useState<'week' | 'day'>('week');

    const femaleLiceData = liceCount.map((datum) => ({
        x: datum.day,
        y: Number(datum.femaleAvg?.toFixed(2)),
        isUncertainLiceCount: datum.total < MIN_FISH_COUNT_FOR_CERTAIN_LICE.DAILY,
    }));

    const weeklyData = liceCountOverviewWeekly.map((item) => {
        const weekNumber = item.weekNumber;
        const year = item.year;
        const dateFromWeek = moment().weekday(1).isoWeek(weekNumber).year(year).toDate();

        const x = dateFromWeek.toISOString();

        const isUncertainLiceCount = item?.count > MIN_FISH_COUNT_FOR_CERTAIN_LICE.WEEKLY;

        return {
            weekNumber,
            year,
            femaleAvg: Number(item?.femaleAvg?.toFixed(2)),
            caligusElongatusAvg: Number(item?.caligusElongatusAvg?.toFixed(2)),
            movingStageAvg: Number(item?.movingStageAvg?.toFixed(2)),
            x,
            isUncertainLiceCount,
        };
    });

    return (
        <FullScreenCard>
            <div className={styles.switchingButtons}>
                <Button
                    className={timeUnit === 'week' ? `${styles.active}` : ''}
                    onClick={() => setTimeUnit('week')}>
                    {t('Weekly')}
                </Button>
                <Button
                    className={timeUnit === 'day' ? `${styles.active}` : ''}
                    onClick={() => setTimeUnit('day')}>
                    {t('Daily')}
                </Button>
            </div>
            {timeUnit === 'day' ? (
                <Graph
                    data={femaleLiceData}
                    header={(chart) => <LiceChartHeader chart={chart} />}
                />
            ) : (
                <WeeklyGraph
                    data={weeklyData}
                    header={(chart) => <LiceChartHeader chart={chart} />}
                    showMaximumLiceInformation={showMaximumLiceInformation}
                />
            )}
        </FullScreenCard>
    );
};
export default LiceMeanPerFishGraph;
