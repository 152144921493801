import {
    HelpIconSVG,
    HomeIconSVG,
    LiceIconSVG,
    MoveBioscopeIconSVG,
    UserIconSVG,
} from '@/components/atoms/icons/GenericIcon/GenericIcons';
import { Pages, SidebarType } from '@/components/molecules/GenericSidebar';
import { SidebarPageOptionObject } from '@/components/molecules/GenericSidebar/SidebarPageOptionObjects';

const pages: Pages = [
    {
        id: 1,
        exact: true,
        path: '',
        label: 'Region overview',
        sidebarToShowFor: SidebarType.regionLevel,
        image: HomeIconSVG,
    },

    {
        id: 2,
        path: '/lice-report',
        label: 'Lice report',
        sidebarToShowFor: SidebarType.regionLevel,
        exact: true,
        image: LiceIconSVG,
    },

    {
        id: 3,
        path: '/users',
        label: 'Users',
        sidebarToShowFor: SidebarType.regionLevel,
        exact: true,
        image: UserIconSVG,
    },

    {
        id: 4,
        path: '/move-bioscope',
        label: 'Move bioscope',
        sidebarToShowFor: SidebarType.regionLevel,
        exact: true,
        image: MoveBioscopeIconSVG,
    },
    {
        id: 5,
        path: '/help',
        label: 'Help',
        sidebarToShowFor: SidebarType.regionLevel,
        exact: true,
        image: HelpIconSVG,
    },
].map((page, index) => ({ key: index, ...page }));

const regionSidebarOptions: SidebarPageOptionObject[] = pages.map((item) => {
    return {
        page: item,
        url: '',
        active: false,
    };
});

export default regionSidebarOptions;
