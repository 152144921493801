import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import ChevronDirectionDownIcon from '@/components/atoms/icons/ChevronDirectionDownIcon';
import { GenericIcon } from '@/components/atoms/icons/GenericIcon';
import { SidebarPageOptionObject } from '@/components/molecules/GenericSidebar/SidebarPageOptionObjects';

import styles from './GenericSidebarItem.module.scss';

export interface GenericSidebarItemProps {
    children?: React.ReactNode;
    sidebarObject?: SidebarPageOptionObject;
    subOptions?: SidebarPageOptionObject[];
    allSidebarObjects?: SidebarPageOptionObject[];
    handleSetOpenSidebarOptions?: (sidebarObject: SidebarPageOptionObject) => void;
    openSidebarOptions?: SidebarPageOptionObject[];
}

const GenericSidebarItem = ({
    children,
    sidebarObject = new SidebarPageOptionObject(),
    subOptions = [],
    allSidebarObjects = [],
    handleSetOpenSidebarOptions = () => {},
    openSidebarOptions = [],
}: GenericSidebarItemProps) => {
    const history = useHistory();

    const size: number = 24;
    const imageColor = sidebarObject.active ? '#5bb784' : 'white';
    const isOpen = useRef<boolean>(false);

    const [animationToPlay, setAnimationToPlay] = useState<string>('');

    const handleChangePage = () => {
        handleOpenOption();

        history.push(sidebarObject.url);
    };

    const handleOpenOption = () => {
        const isAlreadyOpen = openSidebarOptions.some((item) => {
            return item.page === sidebarObject.page;
        });

        if (isAlreadyOpen && isOpen.current) {
            isOpen.current = false;
            setAnimationToPlay(styles.closeSubOptions);

            return;
        }

        isOpen.current = true;

        setAnimationToPlay(styles.openSubOptions);

        handleSetOpenSidebarOptions(sidebarObject);
    };

    useEffect(() => {
        const prevOpen = isOpen.current;

        isOpen.current = openSidebarOptions.some((item) => {
            return item.page === sidebarObject.page;
        });

        if (!isOpen.current && prevOpen) {
            setAnimationToPlay(styles.closeSubOptions);
        }
    }, [openSidebarOptions]);

    return (
        <>
            <div
                className={`${styles.optionContainer} ${
                    sidebarObject?.page?.parentId === undefined && sidebarObject?.active
                        ? styles.optionContainerHighlight
                        : ''
                }`}>
                <div
                    className={cn(styles.locationsidebaritem, {
                        [styles.active]: sidebarObject.active,
                    })}
                    onClick={() => handleChangePage()}>
                    <div className={styles.iconContainer}>
                        <GenericIcon
                            icon={sidebarObject?.page?.image ?? ''}
                            size={size}
                            color={imageColor}
                        />
                    </div>
                    <div className={styles.textWrapper}>{children}</div>
                </div>
                {subOptions.length > 0 && (
                    <div
                        className={`${styles.openIcon} ${animationToPlay}`}
                        onClick={() => handleOpenOption()}>
                        <ChevronDirectionDownIcon />
                    </div>
                )}
            </div>
            {subOptions.length > 0 && (
                <div
                    className={
                        isOpen.current && sidebarObject?.page?.parentId === undefined
                            ? styles.sidebarItemSubOptionsOpen
                            : styles.sidebarItemSubOptionsClose
                    }>
                    <div className={styles.lineContainer}>
                        <div className={styles.line} />
                        {subOptions.map((subOption) => (
                            <GenericSidebarItem
                                key={subOption.page.id}
                                sidebarObject={subOption}
                                subOptions={allSidebarObjects.filter(
                                    (item) => subOption?.page?.id === item?.page?.parentId
                                )}
                                allSidebarObjects={allSidebarObjects}
                                openSidebarOptions={openSidebarOptions}
                                handleSetOpenSidebarOptions={handleSetOpenSidebarOptions}>
                                {subOption.page.label}
                            </GenericSidebarItem>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default GenericSidebarItem;
export { GenericSidebarItem };
