import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './FishHealthFilter.module.scss';

interface FishHealthFilterProps {
    onFilterGroupChange?: (filterState: string) => void;
    filterStateList?: { title: string; tag: string }[];
    disableRadioList?: boolean[];
}

const FishHealthFilter = ({
    filterStateList = [],
    onFilterGroupChange,
    disableRadioList = [],
}: FishHealthFilterProps) => {
    const { t } = useTranslation();
    const [filterState, setFilterState] = useState(filterStateList[0]?.tag ?? '');

    const filterChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (event): void => {
        setFilterState(event.currentTarget.value);
        onFilterGroupChange(event.currentTarget.value);
        event.stopPropagation();

        return;
    };

    useEffect(() => {
        setFilterState(filterStateList[0]?.tag ?? '');
    }, [filterStateList]);

    return (
        <div className={styles.fishhealthfilter}>
            <fieldset>
                {filterStateList.map((filterStateItem, index) => (
                    <div key={filterStateItem.tag}>
                        <input
                            type="radio"
                            id={filterStateItem.tag}
                            name="fish_filter"
                            value={filterStateItem.tag}
                            checked={filterState === filterStateItem.tag}
                            onChange={filterChangeHandler}
                            disabled={disableRadioList[index] ?? false}
                        />
                        <label htmlFor={filterStateItem.tag}>{t(filterStateItem.title)}</label>
                    </div>
                ))}
            </fieldset>
        </div>
    );
};

export default FishHealthFilter;
export { FishHealthFilter };
