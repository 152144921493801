import React, { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { useTranslation } from 'react-i18next';
import {
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import { DISPLAY_DAY_FORMAT } from 'utils/formattedDay';

import IconButton from '@/components/atoms/Buttons/IconButton';
import { Button } from 'components/atoms/Buttons';

interface NewReportFormValues {
    lastFeedDay: string;
    gotoDetail: boolean;
    slaughterDay: string | null;
    temperature: number | null;
}

export interface NewReportProps {
    onSubmitted: (ev: NewReportFormValues) => void;
}

export function NewReport({ onSubmitted }: NewReportProps) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState<Partial<NewReportFormValues>>({
        gotoDetail: true,
        temperature: 0,
    });
    function updateForm<T extends keyof NewReportFormValues>(key: T) {
        return (value: NewReportFormValues[T]) =>
            setFormData((formData) => ({ ...formData, [key]: value }));
    }
    const [focused, setFocused] = useState<Partial<Record<keyof NewReportFormValues, boolean>>>({});
    function updateFocused<T extends keyof NewReportFormValues>(key: T) {
        return ({ focused }: { focused: boolean }) =>
            setFocused((formData) => ({ ...formData, [key]: focused }));
    }

    const toggle = () => setOpen(!open);
    const isValid = !!formData.lastFeedDay;
    const onFormDataonSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        if (isValid) {
            const submissionData = {
                ...formData,
                temperature: formData.temperature ?? 9, // Set default to 9 if user has not entered a value
            };
            onSubmitted(submissionData as NewReportFormValues);
            setOpen(false);
        }
    };

    return (
        <>
            <IconButton
                variant={'secondary'}
                size={'medium'}
                id="openNewReportModal"
                data-testid="openNewReportModal"
                onClick={() => setOpen(true)}
                buttonText={t('New Report')}></IconButton>
            <Modal isOpen={open} toggle={toggle} keyboard={true}>
                <ModalHeader toggle={toggle}>{t('New Report')}</ModalHeader>
                <Form onSubmit={onFormDataonSubmit}>
                    <ModalBody>
                        <FormGroup>
                            <Label for="lastFeedDay">{t('Reporting.Last feeding date')}</Label>
                            <SingleDatePicker
                                required={true}
                                date={formData.lastFeedDay}
                                placeholder={t('Reporting.Last feeding date')}
                                onDateChange={updateForm('lastFeedDay')}
                                focused={focused.lastFeedDay ?? false}
                                onFocusChange={updateFocused('lastFeedDay')}
                                block={true}
                                isOutsideRange={() => false}
                                numberOfMonths={1}
                                displayFormat={DISPLAY_DAY_FORMAT}
                                showClearDate={false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="slaugterDate">{t('Reporting.Harvest date')}</Label>
                            <SingleDatePicker
                                date={formData.slaughterDay}
                                placeholder={t('Reporting.Harvest date')}
                                onDateChange={updateForm('slaughterDay')}
                                focused={focused.slaughterDay ?? false}
                                onFocusChange={updateFocused('slaughterDay')}
                                block={true}
                                isOutsideRange={() => false}
                                numberOfMonths={1}
                                displayFormat={DISPLAY_DAY_FORMAT}
                                showClearDate={false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="temperature">{t('Reporting.Sea temperature')}</Label>
                            <Input
                                id="temperature"
                                name="temperature"
                                type="number"
                                min="-20"
                                max="30"
                                step="0.1"
                                value={formData.temperature}
                                onChange={(ev) => {
                                    const temperatureValue =
                                        ev.target.value === '' ? null : Number(ev.target.value);
                                    updateForm('temperature')(temperatureValue);
                                }}
                                placeholder={t('Reporting.Sea temperature')}
                                required={false}
                            />
                        </FormGroup>
                        <FormGroup check>
                            <Label for="gotoDetail" check>
                                <Input
                                    type="checkbox"
                                    id="gotoDetail"
                                    name="gotoDetail"
                                    onChange={(ev) => updateForm('gotoDetail')(ev.target.checked)}
                                    checked={formData.gotoDetail}
                                />
                                {t('Goto report detail')}
                            </Label>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit" disabled={!isValid}>
                            {t('Create New Report')}
                        </Button>
                        <Button color="secondary" onClick={() => setOpen(false)}>
                            {t('Cancel')}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
}
