import { Page, PageContent } from '@/components/Layout';
import { GenericSidebar, SidebarType } from '@/components/molecules/GenericSidebar';
import UserPage from '@/components/UserManagmentPage';
import { internalSidebarOptions } from '@/routes/Internal';

const InternalUserManagementContainer = ({ clientId }: { clientId: number }) => {
    const layerUrlPrefix = `/internal`;

    return (
        <Page title="Users">
            <GenericSidebar
                sideBarOptions={internalSidebarOptions}
                sidebarType={SidebarType.internalLevel}
                layerUrlPrefix={layerUrlPrefix}
            />
            <PageContent>
                <UserPage clientId={clientId} />
            </PageContent>
        </Page>
    );
};

export default InternalUserManagementContainer;
export { InternalUserManagementContainer };
