import React from 'react';

import { CageSelectorPillSwitch } from '@/components/atoms/CageSelectorPillSwitch';

import styles from './CageSelector.module.scss';

export interface CageSelectorProps {
    cageOptions?: CageOption[];
    cageAddRemove?: (cageIds: number[], isOn: boolean) => void;
    allCageOptions?: CageOption;
}

export interface CageOption {
    label: string;
    value: number[];
    title: string;
    disabled: boolean;
    checked: boolean;
}

const CageSelector = ({
    cageOptions = [],
    cageAddRemove = () => {},
    allCageOptions,
}: CageSelectorProps) => {
    return (
        <div className={styles.cageselector}>
            <CageSelectorPillSwitch
                text={allCageOptions?.label}
                value={allCageOptions?.value}
                active={allCageOptions?.checked}
                disabled={allCageOptions?.disabled}
                toggleHandler={cageAddRemove}
                key={allCageOptions?.checked.toString()}
            />
            {cageOptions.map((c: CageOption) => (
                <CageSelectorPillSwitch
                    text={c.label}
                    value={c.value}
                    key={`${c.value}: ${c.checked.toString()}`}
                    active={c.checked}
                    toggleHandler={cageAddRemove}
                    disabled={c.disabled}
                />
            ))}
        </div>
    );
};

export default CageSelector;
export { CageSelector };
