import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'reactstrap';

import { CageSelector } from '@/components/molecules/CageSelector';
import { CageOption } from '@/components/molecules/CageSelector/CageSelector';
import { PageTitleRow } from '@/components/molecules/PageTitleRow';
import { OverviewCage, OverviewLocation } from '@/services/types';

export interface CompareContentInterfaceProps {
    fullFilteredCagesList: OverviewCage[];
    currentCages: OverviewCage[];
    onCagesChanged: (cages: OverviewCage[]) => void;
    location: OverviewLocation;
}

const LocationComparePageContent = ({
    fullFilteredCagesList,
    currentCages,
    onCagesChanged = () => {},
    location,
}: CompareContentInterfaceProps) => {
    const { t, i18n } = useTranslation();

    const [allCageOptions, setAllCageOptions] = useState<CageOption[]>([]);
    const [allCagesButtonOptions, setAllCagesButtonOptions] = useState<CageOption>({
        label: t('All cages'),
        value: fullFilteredCagesList?.map((cage) => {
            return cage.id;
        }),
        title: t('All cages'),
        disabled: false,
        checked: false,
    });

    useEffect(() => {
        let tempAllCageOptions: CageOption[] = fullFilteredCagesList?.map((cage) => {
            return {
                label: cage.name,
                value: [cage.id],
                title: cage.name,
                disabled: !cage.weight,
                checked: false,
            };
        });

        tempAllCageOptions = tempAllCageOptions.map((item) => {
            return {
                ...item,
                checked:
                    currentCages.find((cage) => item.value.find((id) => id === cage.id)) !==
                    undefined,
            };
        });
        setAllCageOptions(tempAllCageOptions);

        handleCheckUncheckChips(
            currentCages.length === fullFilteredCagesList.length,
            tempAllCageOptions
        );
    }, []);

    useEffect(() => {
        if (allCageOptions.length <= 0) return;

        handleCheckUncheckChips(
            currentCages.length === fullFilteredCagesList.length,
            allCageOptions
        );
    }, [currentCages.length]);

    const addRemoveCage = (cageIds: number[], isOn: boolean) => {
        let tempCurrentCages: OverviewCage[] = [];

        currentCages.map((cage) => {
            tempCurrentCages.push(cage);
        });

        cageIds.forEach((cageId) => {
            const cage = fullFilteredCagesList.find((cage) => cage.id === cageId);
            if (cage) {
                if (isOn) {
                    if (!tempCurrentCages.includes(cage)) {
                        tempCurrentCages.push(cage);
                    }
                } else {
                    tempCurrentCages = tempCurrentCages.filter(
                        (tempCage) => tempCage.id !== cage.id
                    );
                }
            }
        });

        onCagesChanged(tempCurrentCages);
    };

    const handleCheckUncheckChips = (allActive: boolean, options: CageOption[]) => {
        const newAllCageOptions = options.map((cageOption) => {
            const hasCages =
                currentCages.find((cage) => cageOption.value.includes(cage.id)) !== undefined;

            return {
                ...cageOption,
                checked: allActive ? true : currentCages.length > 0 ? hasCages : false,
            };
        });

        setAllCageOptions(newAllCageOptions);

        const newAllCageOptionsButton = {
            ...allCagesButtonOptions,
            checked: allActive,
        };

        setAllCagesButtonOptions(newAllCageOptionsButton);
    };

    return (
        <>
            <Row>
                <PageTitleRow title={location?.name} subTitle={t('Compare pens')}>
                    <CageSelector
                        cageOptions={allCageOptions}
                        cageAddRemove={addRemoveCage}
                        allCageOptions={allCagesButtonOptions}
                    />
                </PageTitleRow>
            </Row>
        </>
    );
};

export default LocationComparePageContent;
