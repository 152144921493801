import React from 'react';

import styles from './HomeIcon.module.scss';

export interface HelpIconProps {
    children?: React.ReactNode;
    width?: number;
    color?: string;
}

const HomeIcon = ({ children, width = 24, color = '#09244B' }: HelpIconProps) => {
    const height = width;
    const viewBox = `0 0 24 24`;
    return (
        <div className={styles.homeicon}>
            <svg
                width={width ? width : '24'}
                height={height ? height : '24'}
                viewBox={viewBox}
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M24 0v24H0V0zM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01z" />
                <path
                    fill={color}
                    d="M13.228 2.688a2 2 0 0 0-2.456 0l-8.384 6.52C1.636 9.795 2.05 11 3.003 11H4v8a2 2 0 0 0 2 2h5v-7a1 1 0 0 1 2 0v7h5a2 2 0 0 0 2-2v-8h.997c.952 0 1.368-1.205.615-1.791z"
                />
            </svg>
        </div>
    );
};

export default HomeIcon;
export { HomeIcon };
