import React from 'react';

import styles from './BackIcon.module.scss';

export interface BackIconProps {
    color: string;
    height?: number;
}

const BackIcon = ({ color = 'currentColor', height = 24 }: BackIconProps) => {
    const width = height;

    return (
        <div className={styles.backicon}>
            <svg
                id="glyphicons-basic"
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                viewBox="0 0 32 32">
                <path
                    id="calendar"
                    fill={color}
                    d="M28,16a1,1,0,0,1-1,1H10v3.18933a.75.75,0,0,1-1.28033.5304L4.53033,16.53027a.74984.74984,0,0,1,0-1.06054l4.18934-4.18946A.75.75,0,0,1,10,11.81067V15H27A1,1,0,0,1,28,16Z"
                />
            </svg>
        </div>
    );
};

export default BackIcon;
export { BackIcon };
