import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useCage } from 'contexts/cage-context';

import { getLabelByValue } from '@/components/molecules/ShareFalseDetectionDropdown/ShareFalseDetectionDropDownObjects';
import { ToastTemplateBackendFeedback } from '@/components/molecules/ToastTemplate';
import SomethingHappened from '@/components/SomethingHappened';
import { useMe } from '@/contexts/meContext';
import { bioscopeImageMapper, fullSizeImageMapper, useImageState } from '@/hooks/image-state';
import {
    useCageImageController_GetImageList,
    useFeedbackController_ImageFeedback,
    useWelfareController_allImages,
} from '@/services/hooks';
import { ImageFeedbackDto } from '@/services/types';
import { Loader } from 'components/atoms/Loader';

import ImagePageContent from './ImagePageContent';

const ImagePageContainer = () => {
    const { cageId, timezone, hasColorWelfare } = useCage();
    const { isOptoscaleAdmin, state } = useMe();
    const { t } = useTranslation();

    const bioscopeImages = useCageImageController_GetImageList(cageId ?? 0);

    const bioscopeImageState = useImageState({
        loading: bioscopeImages.isLoading,
        data: bioscopeImages.data?.data
            .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
            .reverse(),
        mapper: useCallback(bioscopeImageMapper(cageId), [cageId]),
        queryParam: 'bioscopeImageId',
        timezone,
    });

    const type = hasColorWelfare ? 'full-size-image' : 'bw-welfare';
    const detections = useWelfareController_allImages(cageId ?? 0, { type });
    const detectionImageState = useImageState({
        loading: detections.isLoading,
        data: detections.data?.data
            .sort((a, b) => new Date(a.timeStamp).getTime() - new Date(b.timeStamp).getTime())
            .reverse(),
        mapper: useCallback(fullSizeImageMapper(cageId), [cageId]),
        queryParam: 'imageId',
        timezone,
    });

    const { mutateAsync: reportFeedbackAsync } = useFeedbackController_ImageFeedback();

    const onCommitImageFeedbackDto = async (feedbackDto: ImageFeedbackDto) => {
        if (state?.user?.id) {
            feedbackDto = { ...feedbackDto, reportingUserId: state.user.id };
        }
        if (cageId) {
            feedbackDto = { ...feedbackDto, cageId: cageId };
        }

        const { status, data } = await reportFeedbackAsync({ requestBody: feedbackDto });
        if (status) {
            ToastTemplateBackendFeedback({
                content: `Thank you ${
                    state?.user?.firstName ?? ''
                } for contributing to train our AI model on: ${t(getLabelByValue(data.tag) || '')}`,
                toastId: 'detection-feedback',
                httpStatusCode: status,
            });
        }
    };

    const source = hasColorWelfare ? 'color' : 'bw';

    if (bioscopeImages.isError || detections.isError) {
        return <SomethingHappened />;
    }

    if (bioscopeImages.isLoading || detections.isLoading) {
        return <Loader />;
    }

    return (
        <ImagePageContent
            bioscopeImageState={bioscopeImageState}
            detectionImageState={detectionImageState}
            source={source}
            cageId={cageId}
            isOptoscaleAdmin={isOptoscaleAdmin}
            onCommitFalseDetection={onCommitImageFeedbackDto}
        />
    );
};

export default ImagePageContainer;
export { ImagePageContainer };
