import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { IconButton } from '@/components/atoms/Buttons/IconButton';
import { DownloadIcon } from 'components/atoms/icons/DownloadIcon';
import { OptoTooltip } from 'components/atoms/OptoTooltip/OptoTooltip';

import { IconButtonProps } from '../IconButton/IconButton';

import styles from './DownloadButton.module.scss';

export interface DownloadButtonProps extends IconButtonProps {
    onClick?: (ev: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
    open?: boolean;
}

const DownloadButton = ({
    onClick = () => {},
    open = false,
    variant = 'secondary',
    size = 'small',
}: DownloadButtonProps) => {
    const { t } = useTranslation();
    return (
        <div className={styles.downloadbutton}>
            <OptoTooltip content={t('Download images')} nub="up-right">
                <IconButton onClick={onClick} onlyIcon={true} size={size} variant={variant}>
                    <span>
                        <DownloadIcon size={24} />

                        <i
                            className={classnames({
                                fas: true,
                                'fa-caret-up': open,
                                'fa-caret-down': !open,
                            })}
                        />
                    </span>
                </IconButton>
            </OptoTooltip>
        </div>
    );
};

export default DownloadButton;
export { DownloadButton };
