import React from 'react';

import styles from './NavigateToIcon.module.scss';

export interface NavigateToIconProps {
    children?: React.ReactNode;
    width?: number;
    height?: number;
    color?: string;
}

const NavigateToIcon = ({
    children,
    width = 15,
    height = 15,
    color = 'white',
}: NavigateToIconProps) => {
    return (
        <div className={styles.moveicon}>
            <svg
                width={width}
                height={height}
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M27.46967,16.53027l-4.18934,4.18946A.75.75,0,0,1,22,20.18933V17H5a1,1,0,0,1,0-2H22V11.81067a.75.75,0,0,1,1.28033-.5304l4.18934,4.18946A.74984.74984,0,0,1,27.46967,16.53027Z"
                    fill={color}
                />
            </svg>
        </div>
    );
};

export default NavigateToIcon;
export { NavigateToIcon };
