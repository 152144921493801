import React from 'react';
import { useClientController_Overview, useLocationController_MarkHistorical } from 'services/hooks';

import { Loader } from '@/components/atoms/Loader';
import { Page, PageContent } from '@/components/Layout';
import { GenericSidebar, SidebarType } from '@/components/molecules/GenericSidebar';
import SomethingHappened from '@/components/SomethingHappened';
import { useMe } from '@/contexts/meContext';
import regionSidebarOptions from '@/routes/Client/Overview';
import { OverviewLocation } from '@/services/types';

import Overview from './Overview';

const OvervewContainer = ({ clientId }: { clientId: number }) => {
    const { state, hasUserFeature, isClientAdmin, isOptoscaleAdmin } = useMe();

    const client = state.clients.find((client) => client.id === clientId);

    const layerUrlPrefix = `/c/${clientId}`;

    const { isLoading, data, error, refetch } = useClientController_Overview(clientId, {
        staleTime: 5 * 60 * 1000,
        cacheTime: 5 * 60 * 1000,
    });

    const { mutateAsync: markHistorical } = useLocationController_MarkHistorical({
        onSettled: () => refetch(),
    });

    const markHistoricalWrapper = async (cageId: number, historical: boolean) => {
        await markHistorical({ cageId: cageId, queryParams: { historical } });
        //We want to block the request until we have refetched the location data
        await refetch();
    };

    return (
        <Page title="Location">
            <GenericSidebar
                sideBarOptions={regionSidebarOptions}
                sidebarType={SidebarType.regionLevel}
                layerUrlPrefix={layerUrlPrefix}
            />
            <PageContent>
                {(isLoading || !data?.data?.locations) && <Loader />}
                {error && <SomethingHappened />}

                {!isLoading && !error && data?.data?.locations && (
                    <Overview
                        locations={data?.data?.locations as OverviewLocation[]}
                        clientId={clientId}
                        markHistorical={markHistoricalWrapper}
                        hasAccessToMarkHistorical={isOptoscaleAdmin}
                        isOptoscaleAdmin={isOptoscaleAdmin}
                        containerIsLoading={isLoading}
                        containerError={error}
                        clientName={client?.name}
                    />
                )}
            </PageContent>
        </Page>
    );
};

export default OvervewContainer;
export { OvervewContainer };
