const ADMIN_IS_SUPER = true;

const LICENSES = {
    FISH_HEALTH: {
        MIN: '^FISH_HEALTH\\.MIN$',
        INSPECTION: '^FISH_HEALTH\\.INSPECTION$',
    },
    LICE_COUNT: {
        MIN: '^LICE_COUNT\\.MIN$',
        MAX: '^LICE_COUNT\\.MAX$',
    },
    LAKSVEL: '^LAKSVEL$',
};

export default LICENSES;
export { ADMIN_IS_SUPER, LICENSES };
