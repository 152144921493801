import React from 'react';
import { Col, Row } from 'reactstrap';
import { useCage } from 'contexts/cage-context';
import { t } from 'i18next';

import { PageTitleRow } from '@/components/molecules/PageTitleRow';
import { Loader } from 'components/atoms/Loader';
import { DownloadCsvXlsx } from 'components/DownloadCsvXlsx';
import { Box } from 'components/Layout';
import NoData from 'components/NoData';
import SomethingHappened from 'components/SomethingHappened';

import WeightDistributionHistogramPlayer from './components/WeightDistributionHistogramPlayer';
import WeightDistributionStatus from './components/WeightDistributionStatus';
import useWeightDistribution from './weight-distribution-context';

function WeightDistribution() {
    const { loading, error, days } = useWeightDistribution();
    const { cage } = useCage();
    if (error) {
        return <SomethingHappened error={error} />;
    }

    if (loading) {
        return <Loader />;
    }

    if (!Array.isArray(days) || days.length < 1) {
        return <NoData />;
    }
    const allData =
        days?.flatMap((el) => {
            return el.histograms.flatMap((x) => ({
                date: el?.day,
                daily_Distribution_Type_Tag: x?.dailyDistributionTypeTag,
                bin_from: `${x?.bucket.from}g`,
                bin_to: `${x?.bucket.to}g`,
                cnt: x?.cnt,
                cntPct: x?.cntPct,
            }));
        }) ?? [];

    // need to send columns names in this format
    const locationInfoColumns = ['location.name', 'location.timezone', 'cage.name'];
    const dataColumnNames = allData.length > 0 ? Object.keys(allData[0]) : [];
    const formattedDataColumnNames = dataColumnNames.map((e) => `day.${e}`);
    const allColumnNames = [...locationInfoColumns, ...formattedDataColumnNames];

    return (
        <>
            <PageTitleRow title={cage?.name} subTitle={t('Weight distribution')}>
                <div>
                    <DownloadCsvXlsx label="CSV" data={allData} COLUMNS={allColumnNames} />
                    <DownloadCsvXlsx label="XLSX" data={allData} COLUMNS={allColumnNames} />
                </div>
            </PageTitleRow>

            <Row>
                <Col>
                    <Box>
                        <WeightDistributionHistogramPlayer />
                    </Box>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Box>
                        <WeightDistributionStatus />
                    </Box>
                </Col>
            </Row>
        </>
    );
}

export default WeightDistribution;
