import { useTranslation } from 'react-i18next';

import BlockTitle from '@/components/atoms/BlockTitle';
import NoComponentDataAvailable from '@/components/atoms/NoComponentDataAvailable';
import UmerBox from '@/components/atoms/UmerBox';
import { PageTitleRow } from '@/components/molecules/PageTitleRow';
import useCage from '@/contexts/cage-context';
import { ImageState } from '@/hooks/image-state';
import { ImageFeedbackDto } from '@/services/types';
import { ImageViewContainer } from 'components/organisms/ImageViewer';

export interface ImagesPageContentProps {
    source: string;
    detectionImageState: ImageState;
    bioscopeImageState: ImageState;
    healthType?: string;
    isOptoscaleAdmin?: boolean;
    cageId?: number;
    onCommitFalseDetection: (feedbackDto: ImageFeedbackDto) => void;
}

const ImagePageContent = ({
    detectionImageState,
    bioscopeImageState,
    source,
    isOptoscaleAdmin = false,
    onCommitFalseDetection = () => {},
}: ImagesPageContentProps) => {
    const { t } = useTranslation();
    const { cage } = useCage();
    const isColor = source === 'color';

    return (
        <>
            <PageTitleRow title={cage?.name} subTitle={t('Images')} />

            <UmerBox>
                <BlockTitle heading={t('Catch of the Day')} />
                {detectionImageState.dayswithimages.length === 0 ? (
                    <NoComponentDataAvailable />
                ) : (
                    <ImageViewContainer
                        key="catchoftheday"
                        instanceKey="catchoftheday"
                        isColor={isColor}
                        isOptoScaleAdmin={isOptoscaleAdmin}
                        onCommitImageFeedbackDto={onCommitFalseDetection}
                        viewerConfig={{
                            showMagnifyer: false,
                            scrollActivated: true,
                            showPois: false,
                            zoomDisabled: true,
                            showWoundLabel: false,
                            magnifyerDisabled: false,
                        }}
                        {...detectionImageState}
                    />
                )}
            </UmerBox>
            <UmerBox>
                <BlockTitle heading={t('Bioscope inspection')} />
                {bioscopeImageState.dayswithimages.length === 0 ? (
                    <NoComponentDataAvailable />
                ) : (
                    <ImageViewContainer
                        key="bioscope"
                        instanceKey="bioscope"
                        isOptoScaleAdmin={isOptoscaleAdmin}
                        isColor={isColor}
                        onCommitImageFeedbackDto={onCommitFalseDetection}
                        viewerConfig={{
                            showMagnifyer: false,
                            scrollActivated: true,
                            showPois: false,
                            zoomDisabled: true,
                            magnifyerDisabled: true,
                            showWoundLabel: false,
                        }}
                        {...bioscopeImageState}
                    />
                )}
            </UmerBox>
        </>
    );
};

export default ImagePageContent;
export { ImagePageContent };
