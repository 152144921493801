import React from 'react';
import { useTranslation } from 'react-i18next';

import { Chart } from '@/components/organisms/DeprecatedGraphs/Chart';

import options from './options';
import plugins from './plugins';

interface LiceCountBarChartProps {
    data: {
        x: string;
        y: number;
    }[];
    header: any;
}

export default ({ data, header }: LiceCountBarChartProps) => {
    const { t } = useTranslation();
    const allDates = data.map((item) => item.x);
    const lastCount = allDates[allDates.length - 1];
    const todayDate = new Date().toISOString().slice(0, 10);

    const isLastCountIsToday = lastCount === todayDate ? true : false;
    const backgroundColor = data.map((_item, index) =>
        index === data.length - 1 && isLastCountIsToday ? 'rgba(102, 152, 179, 0.4)' : '#6698b3'
    );

    const dataset = {
        label: t('Fish count'),
        backgroundColor,
        borderColor: '#6698b3',
        fill: false,
        hidden: false,
        data: data,
    };

    return (
        <Chart
            style={{}}
            type="bar"
            data={{
                datasets: [dataset],
            }}
            options={options()}
            plugins={plugins}
            header={header}
            sidebar={undefined}
        />
    );
};
