import React, { useState } from 'react';

import styles from './CageSelectorPillSwitch.module.scss';

export interface CageSelectorPillSwitchProps {
    text?: string;
    value?: number[];
    toggleHandler?: (cageIds: number[], isOn: boolean) => void;
    active?: boolean;
    children?: React.ReactNode;
    disabled?: boolean;
}

const CageSelectorPillSwitch = ({
    children,
    text = '',
    value = [],
    active = false,
    toggleHandler = () => {},
    disabled = false,
}: CageSelectorPillSwitchProps) => {
    const [isOn, setIsOn] = useState<boolean>(active.valueOf());

    const internalToggleHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        const cageList = [...value];

        const newIsOn = !isOn;
        setIsOn(newIsOn);
        toggleHandler(cageList, newIsOn);
    };

    return (
        <div className={styles.cageselectorpillswitch}>
            <button
                type="button"
                className={
                    !disabled
                        ? isOn
                            ? styles.active
                            : styles.inactiveButton
                        : styles.disabledButton
                }
                onClick={(event) => internalToggleHandler(event)}
                disabled={disabled}>
                <span>
                    <span>{children}</span>
                    <span>{text}</span>
                </span>
            </button>
        </div>
    );
};

export default CageSelectorPillSwitch;
export { CageSelectorPillSwitch };
