import { t } from 'i18next';

import { Page, PageContent } from '@/components/Layout';
import { GenericSidebar, SidebarType } from '@/components/molecules/GenericSidebar';
import { PageTitleRow } from '@/components/molecules/PageTitleRow';
import { useMe } from '@/contexts/meContext';
import { MoveBioscopeContainer } from '@/routes/Client/MoveBioScope/MoveBioscopeContainer';
import regionSidebarOptions from '@/routes/Client/Overview';

const RegionMoveBioscopeContainer = ({ clientId }: { clientId: number }) => {
    const layerUrlPrefix = `/c/${clientId}`;
    const { canMoveBioscopes } = useMe();

    return (
        <Page title="Bioscopes">
            <GenericSidebar
                sideBarOptions={regionSidebarOptions}
                sidebarType={SidebarType.regionLevel}
                layerUrlPrefix={layerUrlPrefix}
            />
            <PageContent>
                <PageTitleRow
                    title={canMoveBioscopes(clientId) ? t('Move bioscope') : t('Bioscopes')}
                />
                <MoveBioscopeContainer clientId={clientId} />
            </PageContent>
        </Page>
    );
};

export default RegionMoveBioscopeContainer;
export { RegionMoveBioscopeContainer };
