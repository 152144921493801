import { useCallback, useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { useTranslation } from 'react-i18next';
import { FormGroup, Input, Label } from 'reactstrap';
import { useCage } from 'contexts/cage-context';
import {
    DEFAULT_DAYPART_TAG,
    DEFAULT_FROM,
    DEFAULT_TO,
    DEFAULT_WEIGHT_UNIT_TAG,
    useCageFilter,
} from 'contexts/cage-filter-context';
import moment from 'moment';
import { DISPLAY_DAY_FORMAT } from 'utils/formattedDay';

import { Button } from 'components/atoms/Buttons';

import styles from './CageFilter.module.scss';

function CageFilter() {
    const { t } = useTranslation();
    const { dayparts, weightUnits, firstDateMeasurement } = useCage();
    const {
        setFilter,
        from: defaultFrom,
        to: defaultTo,
        daypartTag: defaultDaypartTag,
        weightUnitTag: defaultWeightUnitTag,
    } = useCageFilter();

    const [fromFocused, setFromFocused] = useState();
    const [toFocused, setToFocused] = useState();
    const [from, setFrom] = useState(defaultFrom);
    const [to, setTo] = useState(defaultTo);
    const [daypartTag, setDaypartTag] = useState(defaultDaypartTag);
    const [weightUnitTag, setWeightUnitTag] = useState(defaultWeightUnitTag);

    const handleFromFocusChange = useCallback((focused) => {
        setToFocused(false);
        setFromFocused(focused);
    }, []);

    const handleToFocusChange = useCallback((focused) => {
        setFromFocused(false);
        setToFocused(focused);
    }, []);

    const handleUpdateFilter = useCallback(() => {
        setFilter({
            from,
            to,
            daypartTag,
            weightUnitTag,
        });
    }, [setFilter, from, to, daypartTag, weightUnitTag]);

    const handleAllDataFilter = useCallback(() => {
        setFrom(moment(firstDateMeasurement));
        setTo(DEFAULT_TO);
        setFilter({
            from: firstDateMeasurement,
            to: undefined,
        });
    }, [setFilter, firstDateMeasurement]);

    const handleResetFilter = useCallback(() => {
        // set to defaults both context and UI filter states
        setFrom(DEFAULT_FROM);
        setTo(DEFAULT_TO);
        setDaypartTag(DEFAULT_DAYPART_TAG);
        setWeightUnitTag(DEFAULT_WEIGHT_UNIT_TAG);
        setFilter({
            from: DEFAULT_FROM,
            to: DEFAULT_TO,
            daypartTag: DEFAULT_DAYPART_TAG,
            weightUnitTag: DEFAULT_WEIGHT_UNIT_TAG,
        });
    }, [setFilter]);

    return (
        <div className="text-white">
            <FormGroup>
                <Label>{t('From')}</Label>
                <div>
                    <SingleDatePicker
                        date={from}
                        placeholder={t('fromFilterPlaceholder')}
                        isOutsideRange={() => false}
                        onDateChange={setFrom}
                        focused={fromFocused}
                        onFocusChange={({ focused }) => handleFromFocusChange(focused)}
                        block={true}
                        numberOfMonths={1}
                        displayFormat={DISPLAY_DAY_FORMAT}
                        showClearDate={false}
                    />
                </div>
            </FormGroup>
            <FormGroup>
                <Label>{t('To')}</Label>
                <div>
                    <SingleDatePicker
                        date={to}
                        placeholder={t('toFilterPlaceholder')}
                        isOutsideRange={() => false}
                        onDateChange={setTo}
                        focused={toFocused}
                        required
                        onFocusChange={({ focused }) => handleToFocusChange(focused)}
                        block={true}
                        numberOfMonths={1}
                        displayFormat={DISPLAY_DAY_FORMAT}
                        showClearDate={false}
                    />
                </div>
            </FormGroup>
            <FormGroup>
                <Label>{t('Day part')}</Label>
                <Input
                    type="select"
                    name="selectDaypartFilter"
                    id="selectDaypartFilter"
                    disabled={!Array.isArray(dayparts) || dayparts.length < 1}
                    value={daypartTag}
                    onChange={(e) => setDaypartTag(e.target.value)}>
                    {dayparts?.map((daypart) => (
                        <option key={daypart.id} value={daypart.tag}>
                            {t(`daypartTag.${daypart.tag}`)}
                        </option>
                    ))}
                </Input>
            </FormGroup>
            <FormGroup>
                <Label>{t('Weight unit')}</Label>
                <Input
                    type="select"
                    name="selectWeightUnitFilter"
                    id="selectWeightUnitFilter"
                    disabled={!Array.isArray(weightUnits) || weightUnits.length < 1}
                    value={weightUnitTag}
                    onChange={(e) => setWeightUnitTag(e.target.value)}>
                    {weightUnits?.map((weightUnit) => (
                        <option key={weightUnit.id} value={weightUnit.tag}>
                            {t(`weightUnitTag.${weightUnit.tag}`)}
                        </option>
                    ))}
                </Input>
            </FormGroup>
            <div className={styles.formButtonsContainer}>
                <Button fullWidth onClick={handleUpdateFilter}>
                    {t('Update')}
                </Button>
                <Button
                    buttonText={t('Show all')}
                    filledBackground
                    fullWidth
                    color="secondary"
                    className="mt-2"
                    onClick={handleAllDataFilter}
                />
                <Button
                    filledBackground
                    fullWidth
                    color="secondary"
                    className="mt-2"
                    onClick={handleResetFilter}
                    buttonText={t('Reset filters')}
                />
            </div>
        </div>
    );
}

export default CageFilter;
