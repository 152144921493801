import styles from './HelpPageUserManual.module.scss';
interface HelpPageUserManualProps {
    linkList: {
        title: string;
        link: string;
    }[];
    heading: any;
    description: string;
}

const HelpPageUserManual = ({ linkList, heading, description }: HelpPageUserManualProps) => {
    return (
        <div className="row">
            <div className="col-md-12">
                <h2 className={styles.helpHeading}>{heading}</h2>
                <p>{description}</p>
                <ul className={styles.helpLinks}>
                    {linkList.map((list, index) => {
                        return (
                            <li key={index}>
                                <a href={list.link} target="_blank" rel="noreferrer">
                                    {list.title}
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};
export default HelpPageUserManual;
