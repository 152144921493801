import { Link } from 'react-router-dom';
import { NavbarBrand } from 'reactstrap';
import { CageProvider } from 'contexts/cage-context';
import { CageFilterProvider } from 'contexts/cage-filter-context';

import { LeftColumn, Page, PageContent } from '@/components/Layout';
import styles from '@/routes/Client/Cage/Cage.module.scss';
import CageFilter from '@/routes/Client/Cage/components/CageFilter';
import CageSideBarNavigation from '@/routes/Client/Cage/components/CageSideBarNavigation';
import pages from '@/routes/Client/Cage/pages';

import Cage from './Cage';

interface CageContainerProps {
    clientId?: number; // nullable to support older cage urls
    locationId?: number; // nullable to support older cage urls
    cageId: number;
}

const CageContainer = ({ clientId = null, locationId = null, cageId }: CageContainerProps) => {
    return (
        <CageProvider cageId={cageId}>
            <CageFilterProvider>
                <Page title="Cage">
                    <LeftColumn>
                        <nav className={styles.leftNavigationPanel}>
                            <NavbarBrand tag={Link} to="/" className={styles.logo}>
                                <figure>
                                    <img src="/logo.png" alt="" />
                                </figure>
                            </NavbarBrand>

                            <CageSideBarNavigation
                                pages={pages}
                                clientId={clientId}
                                locationId={locationId}
                            />

                            <CageFilter />
                        </nav>
                    </LeftColumn>
                    <PageContent>
                        <Cage />
                    </PageContent>
                </Page>
            </CageFilterProvider>
        </CageProvider>
    );
};

export default CageContainer;
export { CageContainer };
