import { useMemo, useState } from 'react';
import apiConfig from 'config/api';
import moment from 'moment-timezone';
import { ParsedQuery } from 'query-string';

import {
    BioscopeImageController_Image,
    CageImageController_Image,
    template,
    WelfareController_GetFullSizeImage,
} from '@/services/services';
import { BioscopeImage } from '@/services/types';
import { Image } from 'components/organisms/ImageViewer';

import useQueryParams from './useQueryParams';
interface ImageInput<T> {
    data?: T[];
    filter?: (image: Image) => boolean;
    mapper: (data: T) => Omit<Image, 'day' | 'permalink'>;
    loading: boolean;
    queryParam?: string;
    timezone?: string;
}
function addMetadataToImage(
    image: Omit<Image, 'day'>,
    timezone: string | undefined,
    queryParam: string | undefined
): Image {
    return {
        ...image,
        day: moment(image.timeStamp)
            .tz(timezone || 'UTC')
            .format('YYYY-MM-DD'),
        permalink: queryParam
            ? `${window.location.origin}${window.location.pathname}?${queryParam}=${image.id}`
            : undefined,
    };
}

function mapImageToScpCopyCommand({
    bioscopeId,
    timeStamp,
    fishId,
}: {
    bioscopeId?: number;
    timeStamp: string;
    fishId?: number;
}) {
    const stamp = moment(timeStamp).tz('UTC');
    const folderStamp = stamp.format('YYYY_MM_DD');
    const fileStamp = `B${bioscopeId}_${stamp.format('YYYY_MM_DD_HH_mm_ss_SSS')}_${fishId}`;
    const baseFolder = `${bioscopeId}:/store/optoscale/bioscopeData`;
    return `scp ${baseFolder}/lice/${folderStamp}/pickles/lice_crop_${fileStamp}.gz ${baseFolder}/lice/${folderStamp}/pickles_wound/lice_crop_${fileStamp}.gz .`;
}

function queryParamToImageId(
    query: ParsedQuery<string>,
    queryParam: string | undefined
): string | undefined {
    if (queryParam === undefined) return undefined;
    const entry = query[queryParam];
    if (typeof entry === 'string') {
        return entry;
    }
    return undefined;
}

export interface ImageState {
    images: Image[];
    dayswithimages: { day: string; count: number }[];
    onAfterTimelineChangeHandler: (value: string) => void;
    initialImageIndex: number;
    loading: boolean;
    selectedImageDate: string;
}

export const fullSizeImageMapper =
    (cageId: number | undefined) =>
    (image: Omit<Image, 'day' | 'path'>): Omit<Image, 'day' | 'permalink'> => {
        return {
            ...image,
            path:
                apiConfig.url +
                template(WelfareController_GetFullSizeImage.key, {
                    cageId: cageId,
                    id: image.id,
                }),
            scpCommand: mapImageToScpCopyCommand(image),
        };
    };

export const fullSizeLaksvelImageMapper =
    (cageId: number | undefined, indicator: string) =>
    (image: Omit<Image, 'day' | 'path'>): Omit<Image, 'day' | 'permalink'> | null => {
        const score = Math.round(
            image.indicators?.[indicator as keyof typeof image.indicators] ?? 0
        );

        return {
            ...image,
            path:
                apiConfig.url +
                template(WelfareController_GetFullSizeImage.key, {
                    cageId: cageId,
                    id: image.id,
                }),
            scpCommand: mapImageToScpCopyCommand(image),
            score: `laksvel_${score}` as 'laksvel_1' | 'laksvel_2' | 'laksvel_3',
        };
    };

export const bioscopeImageMapper =
    (cageId: number | undefined) =>
    (bioscopeImage: BioscopeImage): Omit<Image, 'day' | 'permalink'> => {
        return {
            id: bioscopeImage.id,
            timeStamp: bioscopeImage.timestamp,
            path: template(CageImageController_Image.key, { cageId, id: bioscopeImage.id }),
            width: 968,
            height: 608,
            bboxes: [],
        };
    };

export const bioscopeImageBioscopeMapper =
    (bioscopeId: number | undefined) =>
    (bioscopeImage: BioscopeImage): Omit<Image, 'day' | 'permalink'> => {
        return {
            id: bioscopeImage.id,
            timeStamp: bioscopeImage.timestamp,
            path: template(BioscopeImageController_Image.key, { bioscopeId, id: bioscopeImage.id }),
            width: 968,
            height: 608,
            bboxes: [],
        };
    };

const defaultFilter = () => true;
const defaultData: unknown[] = [];
export function useImageState<T>({
    data = defaultData as T[],
    filter = defaultFilter,
    mapper,
    loading,
    queryParam,
    timezone = 'UTC',
}: ImageInput<T>): ImageState {
    const images = useMemo(
        () =>
            data
                .map(mapper)
                .map((image) => {
                    return addMetadataToImage(image, timezone, queryParam);
                })
                .filter(filter)
                .map((image) => {
                    const bboxes = image.bboxes;
                    return { ...image, bboxes };
                })
                .filter((image) => {
                    return image?.score !== 'laksvel_0';
                }),
        [data, mapper, timezone, queryParam, filter]
    );

    const dates = useMemo(
        () =>
            images?.reduce(
                (acc, image) => {
                    if (acc[image.day] === undefined) {
                        acc[image.day] = {
                            count: 0,
                            day: image.day,
                        };
                    }
                    acc[image.day].count++;
                    return acc;
                },
                {} as Record<string, { day: string; count: number }>
            ) ?? {},
        [images]
    );
    const dayswithimages = useMemo(
        () => Object.values(dates).sort((a, b) => (a.day < b.day ? -1 : a.day > b.day ? 1 : 0)),
        [dates]
    );

    const [queryParams] = useQueryParams();
    const imageId = queryParamToImageId(queryParams, queryParam);
    const initialImage = useMemo(() => {
        const image = images?.find((image) => image.id === imageId);
        return image === undefined ? images[0] : image;
    }, [images, imageId, images[0], queryParam]);

    const initialImageDate = initialImage?.day ?? '';

    const [selectedImageDate, setSelectedImageDate] = useState<string>(initialImageDate);

    //Standard React pattern to reset a state when a prop changes
    const [prevImages, setPrevImages] = useState<Image[]>(images);
    const [prevImageId, setPrevImageId] = useState<string | undefined>(imageId);
    if (prevImages !== images || prevImageId !== imageId) {
        setSelectedImageDate(initialImageDate);
        setPrevImages(images);
        setPrevImageId(imageId);
    }

    const imagesForSelectedDate = useMemo(
        () =>
            images.filter((image) => moment(image.day).format('YYYY-MM-DD') === selectedImageDate),
        [images, selectedImageDate]
    );

    const initialImageIndex = useMemo(() => {
        const index = imagesForSelectedDate.findIndex((image) => image.id === imageId);
        if (index === -1) return 0;
        return index;
    }, [imagesForSelectedDate, imageId]);

    return {
        images: imagesForSelectedDate,
        dayswithimages,
        onAfterTimelineChangeHandler: setSelectedImageDate,
        initialImageIndex,
        loading: loading,
        selectedImageDate,
    };
}
