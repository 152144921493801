import { useMe } from '@/contexts/meContext';
import LocationDashboardPageContent from '@/routes/Client/Location/pages/LocationDashboard/LocationDashboardPageContent';
import {
    useClientController_Overview,
    useLocationController_MarkHistorical,
} from '@/services/hooks';
import { OverviewLocation } from '@/services/types';

const LocationDashboardContainer = ({
    clientId,
    locationId,
}: {
    clientId: number;
    locationId: number;
}) => {
    const { isOptoscaleAdmin } = useMe();
    const { isLoading, data, error, refetch } = useClientController_Overview(clientId, {
        staleTime: 5 * 60 * 1000,
        cacheTime: 5 * 60 * 1000,
    });

    const { mutateAsync: markHistorical } = useLocationController_MarkHistorical({
        onSettled: () => refetch(),
    });

    const markHistoricalWrapper = async (cageId: number, historical: boolean) => {
        await markHistorical({ cageId: cageId, queryParams: { historical } });
        //We want to block the request until we have refetched the location data
        await refetch();
    };

    return (
        <LocationDashboardPageContent
            location={data?.data?.locations?.find((x) => x.id === locationId) as OverviewLocation}
            clientId={clientId}
            markHistorical={markHistoricalWrapper}
            hasAccessToMarkHistorical={isOptoscaleAdmin}
            isOptoscaleAdmin={isOptoscaleAdmin}
            renderLazy={false}
            containerLoading={isLoading}
            containerError={error}
        />
    );
};

export default LocationDashboardContainer;
export { LocationDashboardContainer };
