import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import moment from 'moment';
import { Locations } from 'services/types';

import styles from './BioscopePosition.module.scss';

interface BioscopePositionProps {
    title?: string;
    bioscopeLocations: Locations[];
}

const BioscopePosition = ({ bioscopeLocations, title = 'Position' }: BioscopePositionProps) => {
    const { t } = useTranslation();

    return (
        <div className={styles.bioscopePosition}>
            <h2>{t(title)}</h2>
            <Table responsive size="sm">
                <thead>
                    <tr>
                        <th>From</th>
                        <th>To</th>
                        <th>Pen</th>
                        <th>Location</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {bioscopeLocations.length === 0 ? (
                        <tr>
                            <td>No locations found</td>
                        </tr>
                    ) : (
                        bioscopeLocations.map((bioscopeLocation) => {
                            const onLand = !bioscopeLocation.cages;

                            return (
                                <tr key={bioscopeLocation.id}>
                                    <td>
                                        {moment(bioscopeLocation.from).format(
                                            'DD.MM.YYYY [kl.] HH.mm'
                                        )}
                                    </td>
                                    <td>
                                        {bioscopeLocation.to
                                            ? moment(bioscopeLocation.to).format(
                                                  'DD.MM.YYYY [kl.] HH.mm'
                                              )
                                            : 'Now'}
                                    </td>
                                    <td>{onLand ? 'Land' : bioscopeLocation.cages.name}</td>
                                    <td>
                                        {onLand ? 'Land' : bioscopeLocation.cages.locations.name}
                                    </td>
                                    <td>
                                        {!onLand && (
                                            <Link
                                                to={`/c/${
                                                    bioscopeLocation.cages.locations.clientId
                                                }/location/${
                                                    bioscopeLocation.cages.locations.id
                                                }/pen/${bioscopeLocation.cages.id}?from=${moment(
                                                    bioscopeLocation.from
                                                ).toISOString()}${
                                                    bioscopeLocation.to != null
                                                        ? `&to=${moment(
                                                              bioscopeLocation.to
                                                          ).toISOString()}`
                                                        : ''
                                                }`}>
                                                {t('Go to')}
                                            </Link>
                                        )}
                                    </td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default BioscopePosition;
export { BioscopePosition };
