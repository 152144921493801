import React from 'react';
import { useTranslation } from 'react-i18next';
import { merge } from 'lodash';

import { GenericSidebar, SidebarType } from '@/components/molecules/GenericSidebar';
import SomethingHappened from '@/components/SomethingHappened';
import { internalSidebarOptions } from '@/routes/Internal';
import {
    useCageController_FishTypes,
    useClientController_ApiUsers,
    useClientController_CreateApiUser,
    useClientController_GetClientWithLocations,
    useLocationController_CreateCage,
    useLocationController_MarkHistorical,
    useLocationController_UpdateLocation,
} from '@/services/hooks';
import {
    useLocationController_CageTypes,
    useLocationController_CreateLocation,
} from '@/services/hooks';
import { CageType, FishType } from '@/services/types';

import Loader from '../../../../components/atoms/Loader';
import { Page, PageContent } from '../../../../components/Layout';

import { Client } from './Client';

const mergeCall = <V, T extends Record<'requestBody', unknown>, R>(call: (input: T) => R, x: V) => {
    return (input: Omit<T, keyof V>) => {
        return call(merge(x, { requestBody: input }));
    };
};

const ClientContainer = (props) => {
    const { t } = useTranslation();
    const clientId = Number.parseInt(props.match.params.clientId);

    const clientWithLocationsRequest = useClientController_GetClientWithLocations(clientId);
    const { data: cageTypesList } = useLocationController_CageTypes();
    const { data: fishTypesList } = useCageController_FishTypes();

    const { mutateAsync: createLocation } = useLocationController_CreateLocation({
        onSettled: () => clientWithLocationsRequest.refetch(),
    });
    const clientCreateLocation = (input: {
        name: string;
        timezone: string;
        aquaCultureSiteId?: number;
        latitude?: number;
        longitude?: number;
        fishtypeid: number;
    }) =>
        createLocation({
            requestBody: {
                ...input,
                fishtypeid: parseInt(input.fishtypeid as unknown as string),
                clientId,
            },
        });

    const { mutateAsync: updateLocation } = useLocationController_UpdateLocation({
        onSettled: () => clientWithLocationsRequest.refetch(),
    });
    const locationUpdateLocation = ({
        id,
        ...input
    }: {
        name: string;
        timezone: string;
        aquaCultureSiteId?: number;
        latitude?: number;
        longitude?: number;
        id: number;
        fishtypeid: number;
    }) =>
        updateLocation({
            locationId: id,
            requestBody: {
                ...input,
                fishtypeid: parseInt(input.fishtypeid as unknown as string),
            },
        });

    const { mutateAsync: createApiUser } = useClientController_CreateApiUser({
        onSettled: () => clientWithLocationsRequest.refetch(),
    });
    const clientCreateApiUser = () => createApiUser({ clientId });

    const { mutateAsync: newCage } = useLocationController_CreateCage({
        onSettled: () => clientWithLocationsRequest.refetch(),
    });
    const createNewCage = (locationId: number, name: string, cagetypeid: number) => {
        return newCage({
            requestBody: { locationId, name, cagetypeid: cagetypeid },
        });
    };

    const apiUsersRequest = useClientController_ApiUsers(clientId);

    const { mutateAsync: markHistorical } = useLocationController_MarkHistorical({
        onSettled: () => clientWithLocationsRequest.refetch(),
    });

    const loading = clientWithLocationsRequest.isLoading || apiUsersRequest.isLoading;

    const error = clientWithLocationsRequest.error || apiUsersRequest.error;
    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <SomethingHappened />;
    }

    const client = {
        ...clientWithLocationsRequest.data?.data,
        locations: clientWithLocationsRequest.data?.data?.locations.map((location) => ({
            ...location,
            fishType: location.fishTypes,
            fishtypeid: location.fishTypes.id,
            cages: location.cages.map((cage) => ({
                ...cage,

                markHistorical: (historical: boolean) =>
                    markHistorical({ cageId: cage.id, queryParams: { historical } }),
            })),
        })),
    };

    const apiUsers =
        apiUsersRequest.data?.data?.map(({ createdAt, lastUsed, ...rest }) => ({
            ...rest,
            createdAt: new Date(createdAt),
            lastUsed: lastUsed ? new Date(lastUsed) : null,
        })) ?? [];

    const clientName = client?.name || clientId;

    return (
        <Page title={`Client ${clientName}`}>
            <GenericSidebar
                sideBarOptions={internalSidebarOptions}
                sidebarType={SidebarType.internalLevel}
            />
            <PageContent>
                <Client
                    cageTypesList={cageTypesList?.data as unknown as CageType[]}
                    fishTypesList={fishTypesList?.data as unknown as FishType[]}
                    refetch={clientWithLocationsRequest.refetch}
                    client={client}
                    apiUsers={apiUsers}
                    updateLocation={locationUpdateLocation}
                    createNewLocation={clientCreateLocation}
                    createNewCage={createNewCage}
                    createApiUser={clientCreateApiUser}
                />
            </PageContent>
        </Page>
    );
};

export default ClientContainer;
