import React from 'react';

import LiceChartHeader from '@/components/organisms/DeprecatedGraphs/Chart/headers/ChartHeader/LiceChartHeader';
import FullScreenCard from 'components/Layout/Cards/FullScreenCard/FullScreenCard';

import Graph from '../charts/LiceCount';

interface LiceCountBarChartProps {
    liceCount: {
        count: number;
        day: string;
    }[];
}

const LiceCountBarChart = ({ liceCount = [] }: LiceCountBarChartProps) => {
    const data = liceCount.map((datum) => ({ x: datum.day, y: datum.count }));

    return (
        <FullScreenCard>
            <Graph data={data} header={(chart) => <LiceChartHeader chart={chart} />} />
        </FullScreenCard>
    );
};

export default LiceCountBarChart;
