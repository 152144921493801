import * as ChartDataLabels from 'chartjs-plugin-datalabels';
import * as Zoom from 'chartjs-plugin-zoom';

import AutoGraphTimeUnit from '@/components/organisms/DeprecatedGraphs/Chart/plugins/AutoGraphTimeUnit';
import AutoIntegerUnit from '@/components/organisms/DeprecatedGraphs/Chart/plugins/AutoIntegerUnit';
import AutoZoomLimits from '@/components/organisms/DeprecatedGraphs/Chart/plugins/AutoZoomLimits';
import AutoZoomOutLimit from '@/components/organisms/DeprecatedGraphs/Chart/plugins/AutoZoomOutLimit';
import DoubleClickResetZoom from '@/components/organisms/DeprecatedGraphs/Chart/plugins/DoubleClickResetZoom';
import DrawBackground from '@/components/organisms/DeprecatedGraphs/Chart/plugins/DrawBackground';
import ZoomInLimit from '@/components/organisms/DeprecatedGraphs/Chart/plugins/ZoomInLimit';

export default [
    Zoom,
    DoubleClickResetZoom,
    DrawBackground,
    AutoGraphTimeUnit,
    AutoIntegerUnit,
    ZoomInLimit,
    AutoZoomOutLimit,
    AutoZoomLimits,
    ChartDataLabels,
];
