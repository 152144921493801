import {
    CompareIconSVG,
    DashboardIconSVG,
    HelpIconSVG,
} from '@/components/atoms/icons/GenericIcon/GenericIcons';
import { Pages, SidebarType } from '@/components/molecules/GenericSidebar';
import { SidebarPageOptionObject } from '@/components/molecules/GenericSidebar/SidebarPageOptionObjects';
import HelpPageContainer from '@/routes/Client/components/Help/HelpPageContainer';

import LocationCompare from './LocationCompare';
import LocationDashboard from './LocationDashboard';
export const pages: Pages = [
    {
        id: 1,
        exact: true,
        path: '',
        label: 'Dashboard',
        page: LocationDashboard,
        sidebarToShowFor: SidebarType.locationLevel,
        image: DashboardIconSVG,
    },

    {
        id: 2,
        path: '/compare',
        label: 'Compare',
        page: LocationCompare,
        sidebarToShowFor: SidebarType.locationLevel,
        exact: true,
        image: CompareIconSVG,
    },
    {
        id: 3,
        path: '/help',
        label: 'Help',
        page: HelpPageContainer,
        sidebarToShowFor: SidebarType.locationLevel,
        exact: true,
        image: HelpIconSVG,
    },
    /*
    {
        id: 4,
        parentId: 2,
        path: '/compare/health',
        label: 'Test1',
        page: LocationCompare,
        sidebarToShowFor: SidebarType.locationLevel,
        exact: true,
    },
    {
        id: 7,
        parentId: 1,
        path: '/compare/health',
        label: 'Test1',
        page: LocationCompare,
        sidebarToShowFor: SidebarType.locationLevel,
        exact: true,
    },
    */
].map((page, index) => ({ key: index, ...page }));

export const locationSidebarOptions: SidebarPageOptionObject[] = pages.map((item) => {
    return {
        page: item,
        url: '',
        active: false,
    };
});
